export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Built-in String */
  AcademicTitleId: { input: string; output: string };
  /** Built-in String */
  ActivityAttendeeId: { input: string; output: string };
  /** Built-in String */
  ActivityBatchId: { input: string; output: string };
  /** Built-in String */
  ActivityCallDetailId: { input: string; output: string };
  /** Built-in String */
  ActivityCoOrganizerId: { input: string; output: string };
  /** Built-in String */
  ActivityId: { input: string; output: string };
  /** Built-in String */
  ActivityShownMediaId: { input: string; output: string };
  /** Built-in String */
  ActivityTypeId: { input: string; output: string };
  /** Built-in String */
  AddressId: { input: string; output: string };
  /** Built-in String */
  AttributeId: { input: string; output: string };
  /** Built-in String */
  CallOutcomeId: { input: string; output: string };
  /** Built-in String */
  ClientPermissionId: { input: string; output: string };
  /** Built-in String */
  CommentId: { input: string; output: string };
  /** Built-in String */
  CommentTypeId: { input: string; output: string };
  /** Built-in String */
  CommunicationDataId: { input: string; output: string };
  /** Built-in String */
  CommunicationDataTypeId: { input: string; output: string };
  /** Built-in String */
  ConsentCommunicationDataTypeId: { input: string; output: string };
  /** Built-in String */
  ConsentItemId: { input: string; output: string };
  /** Built-in String */
  ConsentTypeId: { input: string; output: string };
  /** Built-in String */
  ConsentTypeManualProcessReasonId: { input: string; output: string };
  /** Built-in String */
  CountryId: { input: string; output: string };
  /** Built-in String */
  Date: { input: string; output: string };
  /** Built-in String */
  DateTime: { input: string; output: string };
  /** Built-in String */
  DeliveryTimeFrameId: { input: string; output: string };
  /** Built-in String */
  DocumentId: { input: string; output: string };
  /** Built-in String */
  EmployeeDepartmentId: { input: string; output: string };
  /** Built-in String */
  EmployeeFunctionId: { input: string; output: string };
  /** Built-in String */
  EmployeeId: { input: string; output: string };
  /** Built-in String */
  FeedbackId: { input: string; output: string };
  /** Built-in String */
  GivenPromotionalMaterialId: { input: string; output: string };
  /** Built-in String */
  InventoryId: { input: string; output: string };
  /** Built-in String */
  KeyMessageId: { input: string; output: string };
  /** Built-in String */
  MediaId: { input: string; output: string };
  /** Built-in String */
  MediaTrackingInfoId: { input: string; output: string };
  /** Built-in String */
  MediaVersionId: { input: string; output: string };
  /** Built-in String */
  OrganizationId: { input: string; output: string };
  /** Built-in String */
  OrganizationTypeId: { input: string; output: string };
  /** Built-in String */
  OrganizationalUnitId: { input: string; output: string };
  /** Built-in String */
  OrganizationalUnitTypeId: { input: string; output: string };
  /** Built-in String */
  PersonAssignmentId: { input: string; output: string };
  /** Built-in String */
  PersonAssignmentTypeId: { input: string; output: string };
  /** Built-in String */
  PersonConsentId: { input: string; output: string };
  /** Built-in String */
  PersonId: { input: string; output: string };
  /** Built-in String */
  PersonTypeId: { input: string; output: string };
  /** Built-in String */
  PostalCodeCityId: { input: string; output: string };
  /** Built-in String */
  PreferenceId: { input: string; output: string };
  /** Built-in String */
  ProductBundleId: { input: string; output: string };
  /** Built-in String */
  ProductFamilyId: { input: string; output: string };
  /** Built-in String */
  ProductId: { input: string; output: string };
  /** Built-in String */
  ProductTypeId: { input: string; output: string };
  /** Built-in String */
  PromotionalMaterialBatchId: { input: string; output: string };
  /** Built-in String */
  PromotionalMaterialId: { input: string; output: string };
  /** Built-in String */
  PromotionalMaterialRuleId: { input: string; output: string };
  /** Built-in String */
  SampleRequestFormId: { input: string; output: string };
  /** Built-in String */
  SpecialityGroupId: { input: string; output: string };
  /** Built-in String */
  SpecialityId: { input: string; output: string };
  /** Built-in String */
  StockId: { input: string; output: string };
  /** Built-in String */
  SystemConfigurationId: { input: string; output: string };
  /** Built-in String */
  TemplateId: { input: string; output: string };
  /** Built-in String */
  ToDoId: { input: string; output: string };
  /** Built-in String */
  TopicId: { input: string; output: string };
  /** Built-in String */
  TouchPointGroupId: { input: string; output: string };
  /** Built-in String */
  TouchpointId: { input: string; output: string };
  /** Built-in String */
  UUID: { input: string; output: string };
  /** Built-in String */
  UserId: { input: string; output: string };
};

export type AbstractActivityAttendeeInput = {
  oid?: InputMaybe<Scalars['ActivityAttendeeId']['input']>;
};

export type AcademicTitle = Node & {
  __typename?: 'AcademicTitle';
  /** Customer reference. reference, used by the customers to refer to the entity. */
  customerReference?: Maybe<Scalars['String']['output']>;
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Name. E.g. Dr. med. vet. */
  name?: Maybe<Scalars['String']['output']>;
  /** UUID. It uniquely identifies the entity across the academic titles. */
  oid: Scalars['AcademicTitleId']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Short version of name. E.g. Dr. */
  shortName?: Maybe<Scalars['String']['output']>;
  /** Sort code. Used to prioritise academic titles. */
  sortCode?: Maybe<Scalars['Int']['output']>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED */
  state: State;
  /** Type. It can be prefix or postfix title. */
  type?: Maybe<AcademicTitleType>;
};

export type AcademicTitleConnection = {
  __typename?: 'AcademicTitleConnection';
  /** List of edges. Default sorting is by sortCode ASC, name ASC */
  edges?: Maybe<Array<AcademicTitleEdge>>;
  /** Count of the entities with the current filter applied */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page */
  pageInfo: PageInfo;
  /** Count of all the entities */
  totalCount: Scalars['Int']['output'];
};

export type AcademicTitleEdge = {
  __typename?: 'AcademicTitleEdge';
  /** A cursor to this edge. This is the entity id */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The academic title entity */
  node?: Maybe<AcademicTitle>;
};

export type AcademicTitleFilterInput = {
  _and?: InputMaybe<Array<AcademicTitleFilterInput>>;
  _or?: InputMaybe<Array<AcademicTitleFilterInput>>;
  customerReference?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  oid?: InputMaybe<AcademicTitleIdFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  shortName?: InputMaybe<StringFilterInput>;
  sortCode?: InputMaybe<IntegerFilterInput>;
  state?: InputMaybe<StateFilterInput>;
  type?: InputMaybe<AcademicTitleTypeFilterInput>;
};

export type AcademicTitleIdFilterInput = {
  _and?: InputMaybe<Array<AcademicTitleIdFilterInput>>;
  _eq?: InputMaybe<Scalars['AcademicTitleId']['input']>;
  _in?: InputMaybe<Array<Scalars['AcademicTitleId']['input']>>;
  _ne?: InputMaybe<Scalars['AcademicTitleId']['input']>;
  _nin?: InputMaybe<Array<Scalars['AcademicTitleId']['input']>>;
  _or?: InputMaybe<Array<AcademicTitleIdFilterInput>>;
};

export type AcademicTitleOrderByInput = {
  customerReference?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  shortName?: InputMaybe<OrderBy>;
  sortCode?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

export enum AcademicTitleType {
  POSTFIX = 'POSTFIX',
  PREFIX = 'PREFIX',
}

export type AcademicTitleTypeFilterInput = {
  _eq?: InputMaybe<AcademicTitleType>;
  _in?: InputMaybe<Array<AcademicTitleType>>;
  _ne?: InputMaybe<AcademicTitleType>;
  _nin?: InputMaybe<Array<AcademicTitleType>>;
};

export type Activity = Node & {
  __typename?: 'Activity';
  /** The type of the activity */
  activityType: ActivityType;
  /** the approval status of the activity, e.g. OPEN, APPROVED, DISAPPROVED */
  approvalStatus?: Maybe<ApprovalStatus>;
  /** List of attendees of the activity. List of 0 or more attendees, can be existing persns in the system or guests */
  attendees: ActivityAttendeeConnection;
  /** Call details which are connected to the activity */
  callDetails: ActivityCallDetailConnection;
  /** List of coOrganizers of the activity. List of 0 or more coOrganizers */
  coOrganizers: ActivityCoOrganizerConnection;
  /** Comments which are connected to the activity */
  comments: CommentConnection;
  /** If the activity is confirmed */
  confirmed: Scalars['Boolean']['output'];
  /** Customer reference. Reference, used by the customers to refer to the entity. */
  customerReference?: Maybe<Scalars['String']['output']>;
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** End date of the activity */
  endDate?: Maybe<Scalars['Date']['output']>;
  /** End date and time of the activity */
  endDateTime?: Maybe<Scalars['DateTime']['output']>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Media which are connected to the activity */
  media: ActivityShownMediaConnection;
  /** Uuid. It uniquely identifies the entity across the activities. */
  oid: Scalars['ActivityId']['output'];
  /** The organization to which the activity is connected */
  organization: Organization;
  /** The organizational unit of the activity organizer */
  organizationalUnit?: Maybe<OrganizationalUnit>;
  /** The person, who is the organizer of the activity, usually the creator */
  organizer: Person;
  /** Promotional materials which are connected to the activity */
  promotionalMaterials: GivenPromotionalMaterialConnection;
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** If activity has promotional materials which require signature, this is the signed form */
  sampleRequestForm?: Maybe<SampleRequestForm>;
  /** If the activity is sealed, it means it cannot be modified */
  sealed: Scalars['Boolean']['output'];
  /** Start date of the activity */
  startDate?: Maybe<Scalars['Date']['output']>;
  /** Start date and time of the activity */
  startDateTime?: Maybe<Scalars['DateTime']['output']>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED */
  state: State;
  /** Status. The current status, e.g. PLANNED, CLOSED */
  status: ActivityStatus;
  /** Topics which are connected to the activity */
  topics: TopicConnection;
};

export type ActivityAttendeesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ActivityAttendeeFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActivityAttendeeOrderByInput>>;
};

export type ActivityCallDetailsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ActivityCallDetailFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActivityCallDetailOrderByInput>>;
};

export type ActivityCoOrganizersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ActivityCoOrganizerFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActivityCoOrganizerOrderByInput>>;
};

export type ActivityCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommentFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentOrderByInput>>;
};

export type ActivityMediaArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ActivityShownMediaFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActivityShownMediaOrderByInput>>;
};

export type ActivityPromotionalMaterialsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<GivenPromotionalMaterialFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GivenPromotionalMaterialOrderByInput>>;
};

export type ActivityTopicsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TopicFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TopicOrderByInput>>;
};

export type ActivityAttendee = {
  __typename?: 'ActivityAttendee';
  /** Name of the attendee if it's a guest */
  guestName?: Maybe<Scalars['String']['output']>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Shows if attendee is a known person to the system or it's a guest */
  isGuest: Scalars['Boolean']['output'];
  /** Uuid. It uniquely identifies the entity across the activity attendees. */
  oid: Scalars['ActivityAttendeeId']['output'];
  /** Person entity if it's known, not deleted person to the system */
  person?: Maybe<Person>;
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ActivityAttendeeConnection = {
  __typename?: 'ActivityAttendeeConnection';
  /** List of edges. Default sorting is by name ASC */
  edges?: Maybe<Array<ActivityAttendeeEdge>>;
  /** Count of the entities with the current filter applied */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page */
  pageInfo: PageInfo;
  /** Count of all the entities */
  totalCount: Scalars['Int']['output'];
};

export type ActivityAttendeeEdge = {
  __typename?: 'ActivityAttendeeEdge';
  /** A cursor to this edge. This is the entity id */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The activity attendee entity */
  node?: Maybe<ActivityAttendee>;
};

export type ActivityAttendeeFilterInput = {
  _and?: InputMaybe<Array<ActivityAttendeeFilterInput>>;
  _or?: InputMaybe<Array<ActivityAttendeeFilterInput>>;
  guestName?: InputMaybe<StringFilterInput>;
  isGuest?: InputMaybe<BooleanFilterInput>;
  oid?: InputMaybe<ActivityAttendeeIdFilterInput>;
  person?: InputMaybe<PersonFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ActivityAttendeeIdFilterInput = {
  _and?: InputMaybe<Array<ActivityAttendeeIdFilterInput>>;
  _eq?: InputMaybe<Scalars['ActivityAttendeeId']['input']>;
  _in?: InputMaybe<Array<Scalars['ActivityAttendeeId']['input']>>;
  _ne?: InputMaybe<Scalars['ActivityAttendeeId']['input']>;
  _nin?: InputMaybe<Array<Scalars['ActivityAttendeeId']['input']>>;
  _or?: InputMaybe<Array<ActivityAttendeeIdFilterInput>>;
};

export type ActivityAttendeeInput = {
  guestName?: InputMaybe<Scalars['String']['input']>;
  isGuest?: InputMaybe<Scalars['Boolean']['input']>;
  oid?: InputMaybe<Scalars['ActivityAttendeeId']['input']>;
  person?: InputMaybe<Scalars['PersonId']['input']>;
};

export type ActivityAttendeeOrderByInput = {
  guestName?: InputMaybe<OrderBy>;
  isGuest?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  person?: InputMaybe<PersonOrderByInput>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
};

export type ActivityCallDetail = {
  __typename?: 'ActivityCallDetail';
  /** The call outcome of the call. */
  callOutcome: CallOutcome;
  /** The type of the call, e.g. OUTBOUND, INBOUND */
  callType: CallType;
  /** Phone number of the person tho whom the call was initiated */
  calleePhoneNumber?: Maybe<Scalars['String']['output']>;
  /** Phone number of the person who initiated the call */
  callerPhoneNumber?: Maybe<Scalars['String']['output']>;
  /** Customer reference. Reference, used by the customers to refer to the entity. */
  customerReference?: Maybe<Scalars['String']['output']>;
  /** End date and time of the call detail */
  endDateTime: Scalars['DateTime']['output'];
  /** Uuid. It uniquely identifies the entity across the activity call details. */
  oid: Scalars['ActivityCallDetailId']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Start date and time of the call detail */
  startDateTime: Scalars['DateTime']['output'];
};

export type ActivityCallDetailConnection = {
  __typename?: 'ActivityCallDetailConnection';
  /** List of edges. Default sorting is by time ASC */
  edges?: Maybe<Array<ActivityCallDetailEdge>>;
  /** Count of the entities with the current filter applied */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page */
  pageInfo: PageInfo;
  /** Count of all the entities */
  totalCount: Scalars['Int']['output'];
};

export type ActivityCallDetailEdge = {
  __typename?: 'ActivityCallDetailEdge';
  /** A cursor to this edge. This is the entity id */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The activity call detail entity */
  node?: Maybe<ActivityCallDetail>;
};

export type ActivityCallDetailFilterInput = {
  _and?: InputMaybe<Array<ActivityCallDetailFilterInput>>;
  _or?: InputMaybe<Array<ActivityCallDetailFilterInput>>;
  callOutcome?: InputMaybe<CallOutcomeFilterInput>;
  callType?: InputMaybe<CallTypeFilterInput>;
  calleePhoneNumber?: InputMaybe<StringFilterInput>;
  callerPhoneNumber?: InputMaybe<StringFilterInput>;
  customerReference?: InputMaybe<StringFilterInput>;
  endDateTime?: InputMaybe<DateTimeFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  startDateTime?: InputMaybe<DateTimeFilterInput>;
};

export type ActivityCallDetailInput = {
  callOutcomeId: Scalars['CallOutcomeId']['input'];
  callType: CallType;
  calleePhoneNumber: Scalars['String']['input'];
  callerPhoneNumber: Scalars['String']['input'];
  customerReference: Scalars['String']['input'];
  endDate: Scalars['DateTime']['input'];
  oid: Scalars['ActivityCallDetailId']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type ActivityCallDetailOrderByInput = {
  callType?: InputMaybe<OrderBy>;
  calleePhoneNumber?: InputMaybe<OrderBy>;
  callerPhoneNumber?: InputMaybe<OrderBy>;
  customerReference?: InputMaybe<OrderBy>;
  endDateTime?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  startDateTime?: InputMaybe<OrderBy>;
};

export type ActivityCoOrganizer = {
  __typename?: 'ActivityCoOrganizer';
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Uuid. It uniquely identifies the entity across the activity coOrganizers. */
  oid: Scalars['ActivityCoOrganizerId']['output'];
  /** The organizational unit of the co-organizer */
  organizationalUnit?: Maybe<OrganizationalUnit>;
  /** Person entity if it's known, not deleted person to the system */
  person?: Maybe<Person>;
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ActivityCoOrganizerConnection = {
  __typename?: 'ActivityCoOrganizerConnection';
  /** List of edges. Default sorting is by name ASC */
  edges?: Maybe<Array<ActivityCoOrganizerEdge>>;
  /** Count of the entities with the current filter applied */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page */
  pageInfo: PageInfo;
  /** Count of all the entities */
  totalCount: Scalars['Int']['output'];
};

export type ActivityCoOrganizerEdge = {
  __typename?: 'ActivityCoOrganizerEdge';
  /** A cursor to this edge. This is the entity id */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The activity co-organizer entity */
  node?: Maybe<ActivityCoOrganizer>;
};

export type ActivityCoOrganizerFilterInput = {
  _and?: InputMaybe<Array<ActivityCoOrganizerFilterInput>>;
  _or?: InputMaybe<Array<ActivityCoOrganizerFilterInput>>;
  oid?: InputMaybe<ActivityCoOrganizerIdFilterInput>;
  organizationalUnit?: InputMaybe<OrganizationalUnitFilterInput>;
  person?: InputMaybe<PersonFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ActivityCoOrganizerIdFilterInput = {
  _and?: InputMaybe<Array<ActivityCoOrganizerIdFilterInput>>;
  _eq?: InputMaybe<Scalars['ActivityCoOrganizerId']['input']>;
  _in?: InputMaybe<Array<Scalars['ActivityCoOrganizerId']['input']>>;
  _ne?: InputMaybe<Scalars['ActivityCoOrganizerId']['input']>;
  _nin?: InputMaybe<Array<Scalars['ActivityCoOrganizerId']['input']>>;
  _or?: InputMaybe<Array<ActivityCoOrganizerIdFilterInput>>;
};

export type ActivityCoOrganizerInput = {
  oid?: InputMaybe<Scalars['ActivityCoOrganizerId']['input']>;
  organizationalUnit?: InputMaybe<Scalars['OrganizationalUnitId']['input']>;
  person?: InputMaybe<Scalars['PersonId']['input']>;
};

export type ActivityCoOrganizerOrderByInput = {
  oid?: InputMaybe<OrderBy>;
  person?: InputMaybe<PersonOrderByInput>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
};

export type ActivityConnection = {
  __typename?: 'ActivityConnection';
  /** List of edges. Default sorting is by startDate DESC */
  edges?: Maybe<Array<ActivityEdge>>;
  /** Count of the entities with the current filter applied */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page */
  pageInfo: PageInfo;
  /** Count of all the entities */
  totalCount: Scalars['Int']['output'];
};

export type ActivityEdge = {
  __typename?: 'ActivityEdge';
  /** A cursor to this edge. This is the entity id */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The activity entity */
  node?: Maybe<Activity>;
};

export type ActivityFilterInput = {
  _and?: InputMaybe<Array<ActivityFilterInput>>;
  _or?: InputMaybe<Array<ActivityFilterInput>>;
  activityType?: InputMaybe<ActivityTypeFilterInput>;
  approvalStatus?: InputMaybe<ApprovalStatusFilterInput>;
  attendees?: InputMaybe<ActivityAttendeeFilterInput>;
  callDetails?: InputMaybe<ActivityCallDetailFilterInput>;
  coOrganizers?: InputMaybe<ActivityCoOrganizerFilterInput>;
  comments?: InputMaybe<CommentFilterInput>;
  confirmed?: InputMaybe<BooleanFilterInput>;
  customerReference?: InputMaybe<StringFilterInput>;
  endDate?: InputMaybe<DateFilterInput>;
  endDateTime?: InputMaybe<DateTimeFilterInput>;
  media?: InputMaybe<ActivityShownMediaFilterInput>;
  oid?: InputMaybe<ActivityIdFilterInput>;
  organization?: InputMaybe<OrganizationFilterInput>;
  organizationalUnit?: InputMaybe<OrganizationalUnitFilterInput>;
  organizer?: InputMaybe<PersonFilterInput>;
  promotionalMaterials?: InputMaybe<GivenPromotionalMaterialFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  sampleRequestForm?: InputMaybe<SampleRequestFormFilterInput>;
  sealed?: InputMaybe<BooleanFilterInput>;
  startDate?: InputMaybe<DateFilterInput>;
  startDateTime?: InputMaybe<DateTimeFilterInput>;
  state?: InputMaybe<StateFilterInput>;
  status?: InputMaybe<ActivityStatusFilterInput>;
  topics?: InputMaybe<TopicFilterInput>;
};

export type ActivityGuestInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  oid?: InputMaybe<Scalars['ActivityAttendeeId']['input']>;
};

export type ActivityIdFilterInput = {
  _and?: InputMaybe<Array<ActivityIdFilterInput>>;
  _eq?: InputMaybe<Scalars['ActivityId']['input']>;
  _in?: InputMaybe<Array<Scalars['ActivityId']['input']>>;
  _ne?: InputMaybe<Scalars['ActivityId']['input']>;
  _nin?: InputMaybe<Array<Scalars['ActivityId']['input']>>;
  _or?: InputMaybe<Array<ActivityIdFilterInput>>;
};

export type ActivityOrderByInput = {
  confirmed?: InputMaybe<OrderBy>;
  customerReference?: InputMaybe<OrderBy>;
  endDate?: InputMaybe<OrderBy>;
  endDateTime?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  sealed?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  startDateTime?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

export type ActivityShownMedia = {
  __typename?: 'ActivityShownMedia';
  /** Key messages which are related to the shown media */
  keyMessages: Array<ActivityShownMediaKeyMessage>;
  /** The media entity of the media which was shown */
  media: Media;
  /** Uuid. It uniquely identifies the entity across the activity shown media. */
  oid: Scalars['ActivityShownMediaId']['output'];
  /** Reaction of the media if such is present */
  reaction: Reaction;
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The exact version of the media which was shown */
  shownVersion: MediaVersion;
  /** Tracking infos for the given media which are shown in the current activity */
  trackingInfos: Array<MediaTrackingInfo>;
};

export type ActivityShownMediaConnection = {
  __typename?: 'ActivityShownMediaConnection';
  /** List of edges. Default sorting is by name ASC */
  edges?: Maybe<Array<ActivityShownMediaEdge>>;
  /** Count of the entities with the current filter applied */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page */
  pageInfo: PageInfo;
  /** Count of all the entities */
  totalCount: Scalars['Int']['output'];
};

export type ActivityShownMediaEdge = {
  __typename?: 'ActivityShownMediaEdge';
  /** A cursor to this edge. This is the entity id */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The activity shown media entity */
  node?: Maybe<ActivityShownMedia>;
};

export type ActivityShownMediaFilterInput = {
  _and?: InputMaybe<Array<ActivityShownMediaFilterInput>>;
  _or?: InputMaybe<Array<ActivityShownMediaFilterInput>>;
  media?: InputMaybe<MediaFilterInput>;
  oid?: InputMaybe<ActivityShownMediaIdFilterInput>;
  reaction?: InputMaybe<ReactionFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ActivityShownMediaIdFilterInput = {
  _and?: InputMaybe<Array<ActivityShownMediaIdFilterInput>>;
  _eq?: InputMaybe<Scalars['ActivityShownMediaId']['input']>;
  _in?: InputMaybe<Array<Scalars['ActivityShownMediaId']['input']>>;
  _ne?: InputMaybe<Scalars['ActivityShownMediaId']['input']>;
  _nin?: InputMaybe<Array<Scalars['ActivityShownMediaId']['input']>>;
  _or?: InputMaybe<Array<ActivityShownMediaIdFilterInput>>;
};

export type ActivityShownMediaInput = {
  keyMessages: Array<ActivityShownMediaKeyMessageInput>;
  mediaId: Scalars['MediaId']['input'];
  mediaTrackingInfoIds?: InputMaybe<
    Array<InputMaybe<Scalars['MediaTrackingInfoId']['input']>>
  >;
  oid: Scalars['ActivityShownMediaId']['input'];
  reaction: Reaction;
  sequenceNumber: Scalars['Int']['input'];
  versionId: Scalars['MediaVersionId']['input'];
};

export type ActivityShownMediaKeyMessage = {
  __typename?: 'ActivityShownMediaKeyMessage';
  /** The key message entity */
  keyMessage: KeyMessage;
  /** Reaction of the key media if such is present */
  reaction: Reaction;
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ActivityShownMediaKeyMessageInput = {
  oid: Scalars['KeyMessageId']['input'];
  reaction: Reaction;
};

export type ActivityShownMediaOrderByInput = {
  media?: InputMaybe<MediaOrderByInput>;
  oid?: InputMaybe<OrderBy>;
  reaction?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
};

export enum ActivityStatus {
  CLOSED = 'CLOSED',
  PLANNED = 'PLANNED',
}

export type ActivityStatusFilterInput = {
  _eq?: InputMaybe<ActivityStatus>;
  _in?: InputMaybe<Array<ActivityStatus>>;
  _ne?: InputMaybe<ActivityStatus>;
  _nin?: InputMaybe<Array<ActivityStatus>>;
};

export type ActivityType = Node & {
  __typename?: 'ActivityType';
  /** Shows if it is allowed to add/edit co organizers for activity */
  allowCoOrganizers: Scalars['Boolean']['output'];
  /** Shows if attendee/organizer is allowed to share the screen */
  attendeeScreenSharingAllowed: Scalars['Boolean']['output'];
  /** Base Type of the activity type. e.g. DIRECT_CONTACT, EMAIL, etc. */
  baseType: ActivityTypeBaseType;
  /** List of comment types which can be used in an activity of this type */
  commentTypes: CommentTypeConnection;
  /** Customer reference. reference, used by the customers to refer to the entity. */
  customerReference?: Maybe<Scalars['String']['output']>;
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Shows how many days after activity date it can be modified */
  daysInPastForModification?: Maybe<Scalars['Int']['output']>;
  /** Shows the default duration of activity of this type. */
  defaultDuration: Scalars['Int']['output'];
  /** template which can be used for sending an invitation vie email to attendees */
  emailInvitationTemplate?: Maybe<Template>;
  /** # template which can be used for sending an invitation vie fax to attendees */
  faxInvitationTemplate?: Maybe<Template>;
  /** Shows if the time is hidden for closed activity */
  hideTimeForClosedActivity: Scalars['Boolean']['output'];
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Maximum allowed number of attendees in activity of this type */
  maxAttendeeCount: Scalars['Int']['output'];
  /** Medias which are connected to the this type */
  medias: MediaConnection;
  /** Minimum allowed number of attendees in activity of this type */
  minAttendeeCount: Scalars['Int']['output'];
  /** Name. String representation of the entity. */
  name: Scalars['String']['output'];
  /** Uuid. It uniquely identifies the entity across the activity types. */
  oid: Scalars['ActivityTypeId']['output'];
  /** Promotional materials which are connected to the this type */
  promotionalMaterials: PromotionalMaterialConnection;
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** List of the rules for this activity type. Those rules should be applied when creating an activity of this type */
  rules: Array<ActivityTypeRule>;
  /** Scheduling type of the activity, e.g. START_DATE, START_DATE_TIME, START_AND_END_DATE, START_AND_END_DATE_TIME */
  schedulingType: ActivityTypeScheduling;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED */
  state?: Maybe<State>;
  /** Shows if time is optional if schedulingType is START_DATE_TIME or START_AND_END_DATE_TIME */
  timeIsOptional: Scalars['Boolean']['output'];
  /** List of topics which can be used in an activity of this type */
  topics: TopicConnection;
  /** TouchPointGroup of the activity type */
  touchPointGroup?: Maybe<TouchPointGroup>;
};

export type ActivityTypeCommentTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommentTypeFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentTypeOrderByInput>>;
};

export type ActivityTypeMediasArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MediaFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MediaOrderByInput>>;
};

export type ActivityTypePromotionalMaterialsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PromotionalMaterialFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PromotionalMaterialOrderByInput>>;
};

export type ActivityTypeTopicsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TopicFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TopicOrderByInput>>;
};

export enum ActivityTypeBaseType {
  /** When none of the above is used */
  CUSTOM = 'CUSTOM',
  /** When a direct contact with the HCP is involved, e.g. Visit */
  DIRECT_CONTACT = 'DIRECT_CONTACT',
  /** When an email channel is used for the activity */
  EMAIL = 'EMAIL',
  /** When activity is via an event, e.g. conference */
  EVENT_CONTACT = 'EVENT_CONTACT',
  /** When an fax channel is used for the activity */
  FAX = 'FAX',
  /** When an mail/post channel is used for the activity */
  MAIL = 'MAIL',
  /** When a remote interaction is open from ysura CRM */
  REMOTE = 'REMOTE',
  /** When an telephone channel is used for the activity */
  TELEPHONE = 'TELEPHONE',
  /** When an email channel from ysura CRM is used for the activity, e.g. personal email */
  TEMPLATED_EMAIL = 'TEMPLATED_EMAIL',
  /** When an webinar is used for the activity */
  WEBINAR = 'WEBINAR',
}

export type ActivityTypeBaseTypeFilterInput = {
  _eq?: InputMaybe<ActivityTypeBaseType>;
  _in?: InputMaybe<Array<ActivityTypeBaseType>>;
  _ne?: InputMaybe<ActivityTypeBaseType>;
  _nin?: InputMaybe<Array<ActivityTypeBaseType>>;
};

export type ActivityTypeConnection = {
  __typename?: 'ActivityTypeConnection';
  /** List of edges. Default sorting is by name ASC */
  edges?: Maybe<Array<ActivityTypeEdge>>;
  /** Count of the entities with the current filter applied */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page */
  pageInfo: PageInfo;
  /** Count of all the entities */
  totalCount: Scalars['Int']['output'];
};

export type ActivityTypeEdge = {
  __typename?: 'ActivityTypeEdge';
  /** A cursor to this edge. This is the entity id */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The activity type entity */
  node?: Maybe<ActivityType>;
};

export type ActivityTypeFilterInput = {
  _and?: InputMaybe<Array<ActivityTypeFilterInput>>;
  _or?: InputMaybe<Array<ActivityTypeFilterInput>>;
  allowCoOrganizers?: InputMaybe<BooleanFilterInput>;
  attendeeScreenSharingAllowed?: InputMaybe<BooleanFilterInput>;
  baseType?: InputMaybe<ActivityTypeBaseTypeFilterInput>;
  commentTypes?: InputMaybe<CommentTypeFilterInput>;
  customerReference?: InputMaybe<StringFilterInput>;
  daysInPastForModification?: InputMaybe<IntegerFilterInput>;
  defaultDuration?: InputMaybe<IntegerFilterInput>;
  hideTimeForClosedActivity?: InputMaybe<BooleanFilterInput>;
  maxAttendeeCount?: InputMaybe<IntegerFilterInput>;
  minAttendeeCount?: InputMaybe<IntegerFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  oid?: InputMaybe<ActivityTypeIdFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  schedulingType?: InputMaybe<ActivityTypeSchedulingFilterInput>;
  state?: InputMaybe<StateFilterInput>;
  timeIsOptional?: InputMaybe<BooleanFilterInput>;
  topics?: InputMaybe<TopicFilterInput>;
  touchPointGroup?: InputMaybe<TouchPointGroupFilterInput>;
};

export type ActivityTypeIdFilterInput = {
  _and?: InputMaybe<Array<ActivityTypeIdFilterInput>>;
  _eq?: InputMaybe<Scalars['ActivityTypeId']['input']>;
  _in?: InputMaybe<Array<Scalars['ActivityTypeId']['input']>>;
  _ne?: InputMaybe<Scalars['ActivityTypeId']['input']>;
  _nin?: InputMaybe<Array<Scalars['ActivityTypeId']['input']>>;
  _or?: InputMaybe<Array<ActivityTypeIdFilterInput>>;
};

export type ActivityTypeOrderByInput = {
  allowCoOrganizers?: InputMaybe<OrderBy>;
  attendeeScreenSharingAllowed?: InputMaybe<OrderBy>;
  baseType?: InputMaybe<OrderBy>;
  customerReference?: InputMaybe<OrderBy>;
  daysInPastForModification?: InputMaybe<OrderBy>;
  defaultDuration?: InputMaybe<OrderBy>;
  hideTimeForClosedActivity?: InputMaybe<OrderBy>;
  maxAttendeeCount?: InputMaybe<OrderBy>;
  minAttendeeCount?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  schedulingType?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  timeIsOptional?: InputMaybe<OrderBy>;
};

export type ActivityTypeRule = {
  __typename?: 'ActivityTypeRule';
  /** Maximum allowed number of activities in case of MAX_ACTIVITY_PER_DAY */
  max?: Maybe<Scalars['Int']['output']>;
  /** Minimum allowed number of media in case of MIN_MEDIA_PER_ACTIVITY */
  min?: Maybe<Scalars['Int']['output']>;
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The type of the rule. Possible values are MAX_ACTIVITY_PER_DAY and MIN_MEDIA_PER_ACTIVITY */
  type: ActivityTypeRuleType;
  /** Start date after which the rule is valid. If null the rule has no start date */
  validFrom?: Maybe<Scalars['Date']['output']>;
  /** End date before which the rule is valid. If null the rule has no end date */
  validTo?: Maybe<Scalars['Date']['output']>;
};

export enum ActivityTypeRuleType {
  /** Maximum number of activities that can be created for a given person */
  MAX_ACTIVITY_PER_DAY = 'MAX_ACTIVITY_PER_DAY',
  /** Minimum number of media that should be added in a given activity */
  MIN_MEDIA_PER_ACTIVITY = 'MIN_MEDIA_PER_ACTIVITY',
}

export type ActivityTypeRuleTypeFilterInput = {
  _eq?: InputMaybe<ActivityTypeRuleType>;
  _in?: InputMaybe<Array<ActivityTypeRuleType>>;
  _ne?: InputMaybe<ActivityTypeRuleType>;
  _nin?: InputMaybe<Array<ActivityTypeRuleType>>;
};

export enum ActivityTypeScheduling {
  /** Start and end date can be set to teh activity */
  START_AND_END_DATE = 'START_AND_END_DATE',
  /** Start and end date with exact time can be set to teh activity */
  START_AND_END_DATE_TIME = 'START_AND_END_DATE_TIME',
  /** Only a start date can be set to activity, end date is same as the start date */
  START_DATE = 'START_DATE',
  /** Only a start date with time can be set to activity, end date (with time) is auto calculated based on default duration */
  START_DATE_TIME = 'START_DATE_TIME',
}

export type ActivityTypeSchedulingFilterInput = {
  _eq?: InputMaybe<ActivityTypeScheduling>;
  _in?: InputMaybe<Array<ActivityTypeScheduling>>;
  _ne?: InputMaybe<ActivityTypeScheduling>;
  _nin?: InputMaybe<Array<ActivityTypeScheduling>>;
};

export type AddAcademicTitleInput = {
  /** Customer reference. reference, used by the customers to refer to the entity. */
  customerReference?: InputMaybe<Scalars['String']['input']>;
  /** Name. E.g. Dr. med. vet. */
  name: Scalars['String']['input'];
  /** UUID. It uniquely identifies the entity across the academic titles. */
  oid: Scalars['AcademicTitleId']['input'];
  /** Short version of name. E.g. Dr. */
  shortName?: InputMaybe<Scalars['String']['input']>;
  /** Sort code. Used to prioritise academic titles. */
  sortCode?: InputMaybe<Scalars['Int']['input']>;
  /** State. The state, e.g. ACTIVE, INACTIVE, DELETED */
  state: State;
  /** Type. It can be prefix or postfix title. */
  type: AcademicTitleType;
};

export type AddActivityInput = {
  /** The type of the activity (DIRECT_CONTACT, EMAIL, etc.) */
  activityTypeId: Scalars['ActivityTypeId']['input'];
  /** The attendees (registered persons or external guests) of the activity. */
  attendees: Array<ActivityAttendeeInput>;
  /** The batch id of the activity. */
  batchId?: InputMaybe<Scalars['ActivityBatchId']['input']>;
  /** The call details (phone number, outcome of the call, etc.) of an activity of type PHONE */
  callDetails: Array<ActivityCallDetailInput>;
  /** The co-organizers of the activity. */
  coOrganizers: Array<ActivityCoOrganizerInput>;
  /** The comments of an activity. */
  comments: Array<CommentInput>;
  /** The flag to indicate if an activity is confirmed. */
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  /** Customer reference. Reference, used by the customers to refer to the entity. */
  customerReference?: InputMaybe<Scalars['String']['input']>;
  /** The ids of the topics discussed in the activity. */
  discussedTopics: Array<Scalars['TopicId']['input']>;
  /** The end date and time of an activity. */
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** The media shown during the activity. */
  media: Array<ActivityShownMediaInput>;
  /** UUID. It uniquely identifies the entity across the academic titles. */
  oid: Scalars['ActivityId']['input'];
  /** The id of the organization which is the target of the activity. */
  organizationId: Scalars['OrganizationId']['input'];
  /** The id of the organizational unit where the activity takes place. */
  organizationalUnitId?: InputMaybe<Scalars['OrganizationalUnitId']['input']>;
  /** The organizer id of the activity. */
  organizerId: Scalars['PersonId']['input'];
  /** The promotional materials requested during the activity. */
  requestedPromotionalMaterials: Array<PromotionalMaterialRequestInput>;
  /** The id of the sample drop form used during the activity. */
  sampleRequestFormId?: InputMaybe<Scalars['SampleRequestFormId']['input']>;
  /** The start date and time of an activity. */
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** The state of the activity. */
  state: State;
  /** The status of the activity. */
  status: ActivityStatus;
  /** The id of the task, created during an activity. */
  toDoId?: InputMaybe<Scalars['ToDoId']['input']>;
};

export type AddFeedbackInput = {
  /** Content of the feedback. */
  content?: InputMaybe<Scalars['String']['input']>;
  /** UUID. It uniquely identifies the entity across the feedback entities. */
  oid: Scalars['FeedbackId']['input'];
  /** Rating if the feedback. Number between 1-5. */
  rating: Scalars['Int']['input'];
  /** Id of the sub entity to which the feedback is attached. */
  subTargetId: Scalars['UUID']['input'];
  /** Type of the sub entity to which the feedback is attached. */
  subTargetType: FeedbackTargetType;
  /** Id of the entity to which the feedback is attached. */
  targetId: Scalars['UUID']['input'];
  /** Type of the entity to which the feedback is attached. */
  targetType: FeedbackTargetType;
};

export type AddMediaTrackingInfoInput = {
  /** The id of the organization to whom the media was presented. */
  audienceOrganizationId?: InputMaybe<Scalars['PersonId']['input']>;
  /** The id of the person to whom the media was presented. */
  audiencePersonId?: InputMaybe<Scalars['PersonId']['input']>;
  /** List of the tracking data for the presentation. */
  data: Array<MediaTrackingInfoDataInput>;
  /** The id of the presented media */
  mediaId: Scalars['MediaId']['input'];
  /** The id of the presented media version */
  mediaVersionId: Scalars['MediaVersionId']['input'];
  /** UUID. It uniquely identifies the entity across the media tracking info. */
  oid: Scalars['MediaTrackingInfoId']['input'];
  /** The id of the person who presented the media. */
  presenterPersonId: Scalars['PersonId']['input'];
  /** The time when the media is shown. */
  shownTime: Scalars['DateTime']['input'];
  /** State. The state, e.g. ACTIVE, INACTIVE, DELETED */
  state: State;
};

export type AddSampleRequestFormInput = {
  /** Activity type Id. Type of the activity associated to the sample request form. */
  activityTypeId: Scalars['ActivityTypeId']['input'];
  /** Address extension. Extension of the address. */
  addressExtension?: InputMaybe<Scalars['String']['input']>;
  /** Address. Address Id of the sample request form. */
  addressId: Scalars['AddressId']['input'];
  /** Document body for the sample request form. */
  body: Scalars['String']['input'];
  /** Created at date. Date when the sample request form is created. */
  createdAt: Scalars['Date']['input'];
  /** Delivery date. Date when the samples should be delivered. */
  deliveryDate?: InputMaybe<Scalars['Date']['input']>;
  /** Delivery time frame Id for the sample request. */
  deliveryTimeFrameId?: InputMaybe<Scalars['DeliveryTimeFrameId']['input']>;
  /** Document filename for the sample request form. */
  filename: Scalars['String']['input'];
  /** Issuer. Person Id that supplies the sample request form. */
  issuerId: Scalars['PersonId']['input'];
  /** Sample requests form items. */
  items: Array<SampleRequestFormItemInput>;
  /** UUID. It uniquely identifies the entity across the sample request forms. */
  oid: Scalars['SampleRequestFormId']['input'];
  /** Signer. Person Id who sings the sample request form. */
  signerId: Scalars['PersonId']['input'];
  /** Should we skipToDo for this sample request form. */
  skipToDo?: InputMaybe<Scalars['Boolean']['input']>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED */
  state: State;
};

export type Address = Node & {
  __typename?: 'Address';
  /** The main line of the address, e.g. Main Street 12 */
  addressLine1?: Maybe<Scalars['String']['output']>;
  /** The second line of the address, e.g. Building 2, Floor 3, etc. */
  addressLine2?: Maybe<Scalars['String']['output']>;
  /** The third line of the address, e.g. some more precise information about the address */
  addressLine3?: Maybe<Scalars['String']['output']>;
  /** The city of the entity */
  city: Scalars['String']['output'];
  /** Coordinates where the Address is located */
  coordinates: Coordinates;
  /** Country where the Address is located */
  country: Country;
  /** Customer reference. reference, used by the customers to refer to the entity. */
  customerReference?: Maybe<Scalars['String']['output']>;
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** The federal state where the address is located, e.g. Bavaria */
  federalState?: Maybe<Scalars['String']['output']>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Name. String representation of the entity. */
  name?: Maybe<Scalars['String']['output']>;
  /** Uuid. It uniquely identifies the entity across the addresses. */
  oid: Scalars['AddressId']['output'];
  /** The postal box of the address if such exists */
  postBox?: Maybe<Scalars['String']['output']>;
  /** The postal code of the entity */
  postalCode: Scalars['String']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED */
  state: State;
};

export type AddressConnection = {
  __typename?: 'AddressConnection';
  /** List of edges. Default sorting is by postalCode ASC, address1 ASC */
  edges?: Maybe<Array<AddressEdge>>;
  /** Count of the entities with the current filter applied */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page */
  pageInfo: PageInfo;
  /** Count of all the entities */
  totalCount: Scalars['Int']['output'];
};

export type AddressEdge = {
  __typename?: 'AddressEdge';
  /** A cursor to this edge. This is the entity id */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The address entity */
  node?: Maybe<Address>;
};

export type AddressFilterInput = {
  _and?: InputMaybe<Array<AddressFilterInput>>;
  _or?: InputMaybe<Array<AddressFilterInput>>;
  addressLine1?: InputMaybe<StringFilterInput>;
  addressLine2?: InputMaybe<StringFilterInput>;
  addressLine3?: InputMaybe<StringFilterInput>;
  city?: InputMaybe<StringFilterInput>;
  country?: InputMaybe<CountryFilterInput>;
  customerReference?: InputMaybe<StringFilterInput>;
  federalState?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  oid?: InputMaybe<AddressIdFilterInput>;
  postBox?: InputMaybe<StringFilterInput>;
  postalCode?: InputMaybe<StringFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  state?: InputMaybe<StateFilterInput>;
};

export type AddressIdFilterInput = {
  _and?: InputMaybe<Array<AddressIdFilterInput>>;
  _eq?: InputMaybe<Scalars['AddressId']['input']>;
  _in?: InputMaybe<Array<Scalars['AddressId']['input']>>;
  _ne?: InputMaybe<Scalars['AddressId']['input']>;
  _nin?: InputMaybe<Array<Scalars['AddressId']['input']>>;
  _or?: InputMaybe<Array<AddressIdFilterInput>>;
};

export type AddressOrderByInput = {
  addressLine1?: InputMaybe<OrderBy>;
  addressLine2?: InputMaybe<OrderBy>;
  addressLine3?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  customerReference?: InputMaybe<OrderBy>;
  federalState?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  postBox?: InputMaybe<OrderBy>;
  postalCode?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
};

export enum AddressType {
  DELIVERY = 'DELIVERY',
  LEGAL = 'LEGAL',
  STANDARD = 'STANDARD',
  VISIT = 'VISIT',
}

export type AddressTypeFilterInput = {
  _eq?: InputMaybe<AddressType>;
  _in?: InputMaybe<Array<AddressType>>;
  _ne?: InputMaybe<AddressType>;
  _nin?: InputMaybe<Array<AddressType>>;
};

export enum ApprovalStatus {
  APPROVED = 'APPROVED',
  DISAPPROVED = 'DISAPPROVED',
  OPEN = 'OPEN',
}

export type ApprovalStatusFilterInput = {
  _eq?: InputMaybe<ApprovalStatus>;
  _in?: InputMaybe<Array<ApprovalStatus>>;
  _ne?: InputMaybe<ApprovalStatus>;
  _nin?: InputMaybe<Array<ApprovalStatus>>;
};

export type Attribute = Node & {
  __typename?: 'Attribute';
  /** Customer reference. reference, used by the customers to refer to the entity. */
  customerReference?: Maybe<Scalars['String']['output']>;
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Name. String representation of the entity. */
  name: Scalars['String']['output'];
  /** Uuid. It uniquely identifies the entity across the attributes. */
  oid: Scalars['AttributeId']['output'];
  /** Options of the attribute if it is a selection attribute. */
  options: Array<AttributeOption>;
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated. */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED */
  state: State;
  /** Type of the attributes, e.g. BOOLEAN, TEXT, NUMERIC, SINGLE_SELECTION etc. */
  type: AttributeType;
  /** Type of the entity to which this attribute can be assigned, e.g. PERSON, ORGANIZATION, BUSINESS_EVENT etc. */
  usageType: AttributeUsageType;
};

export type AttributeConnection = {
  __typename?: 'AttributeConnection';
  /** List of edges. */
  edges?: Maybe<Array<AttributeEdge>>;
  /** Count of the entities with the current filter applied. */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page. */
  pageInfo: PageInfo;
  /** Count of all the entities. */
  totalCount: Scalars['Int']['output'];
};

export type AttributeEdge = {
  __typename?: 'AttributeEdge';
  /** A cursor to this edge. This is the entity id. */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one. */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one. */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The call outcome entity. */
  node?: Maybe<Attribute>;
};

export type AttributeFilterInput = {
  _and?: InputMaybe<Array<AttributeFilterInput>>;
  _or?: InputMaybe<Array<AttributeFilterInput>>;
  customerReference?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  oid?: InputMaybe<AttributeIdFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  state?: InputMaybe<StateFilterInput>;
  type?: InputMaybe<AttributeTypeFilterInput>;
  usageType?: InputMaybe<AttributeUsageTypeFilterInput>;
};

export type AttributeIdFilterInput = {
  _and?: InputMaybe<Array<AttributeIdFilterInput>>;
  _eq?: InputMaybe<Scalars['AttributeId']['input']>;
  _in?: InputMaybe<Array<Scalars['AttributeId']['input']>>;
  _ne?: InputMaybe<Scalars['AttributeId']['input']>;
  _nin?: InputMaybe<Array<Scalars['AttributeId']['input']>>;
  _or?: InputMaybe<Array<AttributeIdFilterInput>>;
};

export type AttributeOption = {
  __typename?: 'AttributeOption';
  /** Customer reference. reference, used by the customers to refer to the entity. */
  customerReference?: Maybe<Scalars['String']['output']>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Uuid. It uniquely identifies the entity across the attributes options. */
  oid: Scalars['AttributeId']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated. */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Sequence number of the option */
  sequence: Scalars['Int']['output'];
  /** Value. String representation of the entity. */
  value: Scalars['String']['output'];
};

export type AttributeOrderByInput = {
  customerReference?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

export enum AttributeType {
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  DOCUMENT = 'DOCUMENT',
  MULTI_SELECTION = 'MULTI_SELECTION',
  NUMERIC = 'NUMERIC',
  ORGANIZATION = 'ORGANIZATION',
  PERSON = 'PERSON',
  SINGLE_SELECTION = 'SINGLE_SELECTION',
  SURVEY_INSTANCE = 'SURVEY_INSTANCE',
  TEXT = 'TEXT',
  USER_PERSON = 'USER_PERSON',
}

export type AttributeTypeFilterInput = {
  _eq?: InputMaybe<AttributeType>;
  _in?: InputMaybe<Array<AttributeType>>;
  _ne?: InputMaybe<AttributeType>;
  _nin?: InputMaybe<Array<AttributeType>>;
};

export enum AttributeUsageType {
  BUSINESS_EVENT = 'BUSINESS_EVENT',
  BUSINESS_EVENT_COST_ITEM = 'BUSINESS_EVENT_COST_ITEM',
  BUSINESS_EVENT_ORGANIZATION = 'BUSINESS_EVENT_ORGANIZATION',
  BUSINESS_EVENT_PERSON = 'BUSINESS_EVENT_PERSON',
  BUSINESS_EVENT_PLANNED_COST_ITEM = 'BUSINESS_EVENT_PLANNED_COST_ITEM',
  BUSINESS_EVENT_STATUS_TRANSITION = 'BUSINESS_EVENT_STATUS_TRANSITION',
  ORGANIZATION = 'ORGANIZATION',
  ORGANIZATIONAL_UNIT = 'ORGANIZATIONAL_UNIT',
  PERSON = 'PERSON',
  PRODUCT = 'PRODUCT',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
}

export type AttributeUsageTypeFilterInput = {
  _eq?: InputMaybe<AttributeUsageType>;
  _in?: InputMaybe<Array<AttributeUsageType>>;
  _ne?: InputMaybe<AttributeUsageType>;
  _nin?: InputMaybe<Array<AttributeUsageType>>;
};

export type AttributeValueFilterInput = {
  _and?: InputMaybe<Array<AttributeValueFilterInput>>;
  _or?: InputMaybe<Array<AttributeValueFilterInput>>;
  attributeOid?: InputMaybe<AttributeIdFilterInput>;
  value?: InputMaybe<AttributeValueValueFilterInput>;
};

export type AttributeValueValueFilterInput = {
  _and?: InputMaybe<Array<AttributeValueValueFilterInput>>;
  _or?: InputMaybe<Array<AttributeValueValueFilterInput>>;
  value?: InputMaybe<StringFilterInput>;
};

export type BooleanFilterInput = {
  /** Matching exactly the value which is passed */
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  /** Not matching the value which is passed */
  _ne?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CallOutcome = Node & {
  __typename?: 'CallOutcome';
  /** Base type. It can be success or failure. */
  baseType: CallOutcomeBaseType;
  /** Customer reference. reference, used by the customers to refer to the entity. */
  customerReference?: Maybe<Scalars['String']['output']>;
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Name. String representation of the entity. */
  name: Scalars['String']['output'];
  /** Uuid. It uniquely identifies the entity across the call outcomes. */
  oid: Scalars['CallOutcomeId']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated. */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED */
  state: State;
};

export enum CallOutcomeBaseType {
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
}

export type CallOutcomeBaseTypeFilterInput = {
  _eq?: InputMaybe<CallOutcomeBaseType>;
  _in?: InputMaybe<Array<CallOutcomeBaseType>>;
  _ne?: InputMaybe<CallOutcomeBaseType>;
  _nin?: InputMaybe<Array<CallOutcomeBaseType>>;
};

export type CallOutcomeConnection = {
  __typename?: 'CallOutcomeConnection';
  /** List of edges. */
  edges?: Maybe<Array<CallOutcomeEdge>>;
  /** Count of the entities with the current filter applied. */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page. */
  pageInfo: PageInfo;
  /** Count of all the entities. */
  totalCount: Scalars['Int']['output'];
};

export type CallOutcomeEdge = {
  __typename?: 'CallOutcomeEdge';
  /** A cursor to this edge. This is the entity id. */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one. */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one. */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The call outcome entity. */
  node?: Maybe<CallOutcome>;
};

export type CallOutcomeFilterInput = {
  _and?: InputMaybe<Array<CallOutcomeFilterInput>>;
  _or?: InputMaybe<Array<CallOutcomeFilterInput>>;
  baseType?: InputMaybe<CallOutcomeBaseTypeFilterInput>;
  customerReference?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  oid?: InputMaybe<CallOutcomeIdFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  state?: InputMaybe<StateFilterInput>;
};

export type CallOutcomeIdFilterInput = {
  _and?: InputMaybe<Array<CallOutcomeIdFilterInput>>;
  _eq?: InputMaybe<Scalars['CallOutcomeId']['input']>;
  _in?: InputMaybe<Array<Scalars['CallOutcomeId']['input']>>;
  _ne?: InputMaybe<Scalars['CallOutcomeId']['input']>;
  _nin?: InputMaybe<Array<Scalars['CallOutcomeId']['input']>>;
  _or?: InputMaybe<Array<CallOutcomeIdFilterInput>>;
};

export type CallOutcomeOrderByInput = {
  baseType?: InputMaybe<OrderBy>;
  customerReference?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
};

export enum CallType {
  INBOUND = 'INBOUND',
  OUTBOUND = 'OUTBOUND',
}

export type CallTypeFilterInput = {
  _eq?: InputMaybe<CallType>;
  _in?: InputMaybe<Array<CallType>>;
  _ne?: InputMaybe<CallType>;
  _nin?: InputMaybe<Array<CallType>>;
};

export type ClientPermission = {
  __typename?: 'ClientPermission';
  /** description of client permission */
  description: Scalars['String']['output'];
  /** Flag indicating if the client permission is offline usable */
  offline: Scalars['Boolean']['output'];
  /** id of client permission */
  oid: Scalars['ClientPermissionId']['output'];
  /** name of client permission */
  permission: Scalars['String']['output'];
};

export type CollectDigitalPersonConsentInput = {
  actionAt: Scalars['DateTime']['input'];
  consentTypeId: Scalars['ConsentTypeId']['input'];
  content: Scalars['String']['input'];
  issuerPersonId: Scalars['PersonId']['input'];
  oid: Scalars['PersonConsentId']['input'];
  signerPersonId: Scalars['PersonId']['input'];
};

export type CollectManualPersonConsentInput = {
  actionAt: Scalars['DateTime']['input'];
  consentItemsValues: Array<PersonConsentItemValueInput>;
  consentTypeId: Scalars['ConsentTypeId']['input'];
  issuerPersonId: Scalars['PersonId']['input'];
  manualProcessReason: Scalars['ConsentTypeManualProcessReasonId']['input'];
  oid: Scalars['PersonConsentId']['input'];
  signerPersonId: Scalars['PersonId']['input'];
};

export type CollectPaperPersonConsentInput = {
  actionAt: Scalars['DateTime']['input'];
  consentItemsValues: Array<PersonConsentItemValueInput>;
  consentTypeId: Scalars['ConsentTypeId']['input'];
  formIdentifier: Scalars['String']['input'];
  issuerPersonId: Scalars['PersonId']['input'];
  oid: Scalars['PersonConsentId']['input'];
  signerPersonId: Scalars['PersonId']['input'];
};

export type Comment = Node & {
  __typename?: 'Comment';
  /** This contain the activity entity if the comment is attached to an activity or to an given promotional material of an activity */
  activity: Activity;
  /** Comment type. */
  commentType: CommentType;
  /** Content. String content of the entity. */
  content?: Maybe<Scalars['String']['output']>;
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** This contain the givenPromotionalMaterial entity if the comment is attached to an given promotional material of an activity */
  givenPromotionalMaterial: GivenPromotionalMaterial;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Uuid. It uniquely identifies the entity across the comments. */
  oid: Scalars['CommentId']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated. */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED. */
  state: State;
  /** This contains the type of the entity to which the comment is attached. */
  targetType: CommentTargetType;
};

export type CommentConnection = {
  __typename?: 'CommentConnection';
  /** List of edges. */
  edges?: Maybe<Array<CommentEdge>>;
  /** Count of the entities with the current filter applied. */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page. */
  pageInfo: PageInfo;
  /** Count of all the entities. */
  totalCount: Scalars['Int']['output'];
};

export type CommentEdge = {
  __typename?: 'CommentEdge';
  /** A cursor to this edge. This is the entity id. */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The comment entity. */
  node?: Maybe<Comment>;
};

export type CommentFilterInput = {
  _and?: InputMaybe<Array<CommentFilterInput>>;
  _or?: InputMaybe<Array<CommentFilterInput>>;
  activity?: InputMaybe<ActivityFilterInput>;
  commentType?: InputMaybe<CommentTypeFilterInput>;
  content?: InputMaybe<StringFilterInput>;
  givenPromotionalMaterial?: InputMaybe<GivenPromotionalMaterialFilterInput>;
  oid?: InputMaybe<CommentIdFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  state?: InputMaybe<StateFilterInput>;
};

export type CommentIdFilterInput = {
  _and?: InputMaybe<Array<CommentIdFilterInput>>;
  _eq?: InputMaybe<Scalars['CommentId']['input']>;
  _in?: InputMaybe<Array<Scalars['CommentId']['input']>>;
  _ne?: InputMaybe<Scalars['CommentId']['input']>;
  _nin?: InputMaybe<Array<Scalars['CommentId']['input']>>;
  _or?: InputMaybe<Array<CommentIdFilterInput>>;
};

export type CommentInput = {
  commentTypeId: Scalars['CommentTypeId']['input'];
  content?: InputMaybe<Scalars['String']['input']>;
  oid: Scalars['CommentId']['input'];
  subTargetId?: InputMaybe<Scalars['UUID']['input']>;
  targetId: Scalars['UUID']['input'];
  targetType: CommentTargetType;
};

export type CommentOrderByInput = {
  commentTargetType?: InputMaybe<OrderBy>;
  content?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
};

export enum CommentTargetType {
  ACTIVITY = 'ACTIVITY',
  ACTIVITY_GIVEN_PROMOTIONAL_MATERIAL = 'ACTIVITY_GIVEN_PROMOTIONAL_MATERIAL',
}

export type CommentTargetTypeFilterInput = {
  _eq?: InputMaybe<CommentTargetType>;
  _in?: InputMaybe<Array<CommentTargetType>>;
  _ne?: InputMaybe<CommentTargetType>;
  _nin?: InputMaybe<Array<CommentTargetType>>;
};

export type CommentType = Node & {
  __typename?: 'CommentType';
  /** Customer reference. reference, used by the customers to refer to the entity. */
  customerReference: Scalars['String']['output'];
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Description. String description of the entity. */
  description: Scalars['String']['output'];
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Name. String representation of the entity. */
  name: Scalars['String']['output'];
  /** Uuid. It uniquely identifies the entity across the comment types. */
  oid: Scalars['CommentTypeId']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED. */
  state: State;
  /** This contains the type of the entity to which the comment can be attached. */
  targetType: CommentTargetType;
};

export type CommentTypeConnection = {
  __typename?: 'CommentTypeConnection';
  /** List of edges. */
  edges?: Maybe<Array<CommentTypeEdge>>;
  /** Count of the entities with the current filter applied. */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page. */
  pageInfo: PageInfo;
  /** Count of all the entities. */
  totalCount: Scalars['Int']['output'];
};

export type CommentTypeEdge = {
  __typename?: 'CommentTypeEdge';
  /** A cursor to this edge. This is the entity id. */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The comment type entity. */
  node?: Maybe<CommentType>;
};

export type CommentTypeFilterInput = {
  _and?: InputMaybe<Array<CommentTypeFilterInput>>;
  _or?: InputMaybe<Array<CommentTypeFilterInput>>;
  customerReference?: InputMaybe<StringFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  oid?: InputMaybe<CommentTypeIdFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  state?: InputMaybe<StateFilterInput>;
  targetType?: InputMaybe<CommentTargetTypeFilterInput>;
};

export type CommentTypeIdFilterInput = {
  _and?: InputMaybe<Array<CommentTypeIdFilterInput>>;
  _eq?: InputMaybe<Scalars['CommentTypeId']['input']>;
  _in?: InputMaybe<Array<Scalars['CommentTypeId']['input']>>;
  _ne?: InputMaybe<Scalars['CommentTypeId']['input']>;
  _nin?: InputMaybe<Array<Scalars['CommentTypeId']['input']>>;
  _or?: InputMaybe<Array<CommentTypeIdFilterInput>>;
};

export type CommentTypeOrderByInput = {
  customerReference?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
};

export type CommunicationData = {
  __typename?: 'CommunicationData';
  /** the type for the communication data. Return entity of CommunicationDataType */
  communicationDataType: CommunicationDataType;
  /** Uuid. It uniquely identifies the entity across the communication data. */
  oid?: Maybe<Scalars['CommunicationDataId']['output']>;
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** the value of this communication data. It can be a phone number, fax number, email, etc. */
  value?: Maybe<Scalars['String']['output']>;
};

export enum CommunicationDataBaseType {
  EMAIL = 'EMAIL',
  FAX = 'FAX',
  INTERNET = 'INTERNET',
  OTHER = 'OTHER',
  PHONE = 'PHONE',
  WEB_CONFERENCE_URL = 'WEB_CONFERENCE_URL',
}

export type CommunicationDataBaseTypeFilterInput = {
  _eq?: InputMaybe<CommunicationDataBaseType>;
  _in?: InputMaybe<Array<CommunicationDataBaseType>>;
  _ne?: InputMaybe<CommunicationDataBaseType>;
  _nin?: InputMaybe<Array<CommunicationDataBaseType>>;
};

export type CommunicationDataConnection = {
  __typename?: 'CommunicationDataConnection';
  /** List of edges. Default sorting is by communication data type ASC */
  edges?: Maybe<Array<CommunicationDataEdge>>;
  /** Count of the entities with the current filter applied */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page */
  pageInfo: PageInfo;
  /** Count of all the entities */
  totalCount: Scalars['Int']['output'];
};

export type CommunicationDataEdge = {
  __typename?: 'CommunicationDataEdge';
  /** A cursor to this edge. This is the entity id */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The communication data entity */
  node?: Maybe<CommunicationData>;
};

export type CommunicationDataFilterInput = {
  _and?: InputMaybe<Array<CommunicationDataFilterInput>>;
  _or?: InputMaybe<Array<CommunicationDataFilterInput>>;
  communicationDataType?: InputMaybe<CommunicationDataTypeFilterInput>;
  oid?: InputMaybe<CommunicationDataIdFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  value?: InputMaybe<StringFilterInput>;
};

export type CommunicationDataIdFilterInput = {
  _and?: InputMaybe<Array<AddressIdFilterInput>>;
  _eq?: InputMaybe<Scalars['AddressId']['input']>;
  _in?: InputMaybe<Array<Scalars['AddressId']['input']>>;
  _ne?: InputMaybe<Scalars['AddressId']['input']>;
  _nin?: InputMaybe<Array<Scalars['AddressId']['input']>>;
  _or?: InputMaybe<Array<AddressIdFilterInput>>;
};

export type CommunicationDataOrderByInput = {
  communicationDataType?: InputMaybe<CommunicationDataTypeOrderByInput>;
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

export type CommunicationDataType = Node & {
  __typename?: 'CommunicationDataType';
  /** Base type. It can be email, phone, fax, internet, other, web_conference_url */
  baseType?: Maybe<CommunicationDataBaseType>;
  /** Consent usable. Indicates if it is usable in a consent */
  consentUsable?: Maybe<Scalars['Boolean']['output']>;
  /** Customer reference. Reference, used by the customers to refer to the entity. */
  customerReference?: Maybe<Scalars['String']['output']>;
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Employee usable. Indicates if it is usable for an employee */
  employeeUsable?: Maybe<Scalars['Boolean']['output']>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Name. String representation of the entity. */
  name?: Maybe<Scalars['String']['output']>;
  /** Uuid. It uniquely identifies the entity across the communicationdata type. */
  oid: Scalars['CommunicationDataTypeId']['output'];
  /** Organization usable. Indicates if it is usable for an organization */
  organizationUsable?: Maybe<Scalars['Boolean']['output']>;
  /** Person usable. Indicates if it is usable for a person */
  personUsable?: Maybe<Scalars['Boolean']['output']>;
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED */
  state?: Maybe<State>;
  /** Verification required. Indicates if a verification is required */
  verificationRequired?: Maybe<Scalars['Boolean']['output']>;
};

export type CommunicationDataTypeConnection = {
  __typename?: 'CommunicationDataTypeConnection';
  /** List of edges. Default sorting is by sortCode ASC, name ASC */
  edges?: Maybe<Array<CommunicationDataTypeEdge>>;
  /** Count of the entities with the current filter applied */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page */
  pageInfo: PageInfo;
  /** Count of all the entities */
  totalCount: Scalars['Int']['output'];
};

export type CommunicationDataTypeEdge = {
  __typename?: 'CommunicationDataTypeEdge';
  /** A cursor to this edge. This is the entity id */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The communication data type entity */
  node?: Maybe<CommunicationDataType>;
};

export type CommunicationDataTypeFilterInput = {
  _and?: InputMaybe<Array<CommunicationDataTypeFilterInput>>;
  _or?: InputMaybe<Array<CommunicationDataTypeFilterInput>>;
  baseType?: InputMaybe<CommunicationDataBaseTypeFilterInput>;
  consentUsable?: InputMaybe<BooleanFilterInput>;
  customerReference?: InputMaybe<StringFilterInput>;
  employeeUsable?: InputMaybe<BooleanFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  oid?: InputMaybe<CommunicationDataTypeIdFilterInput>;
  organizationUsable?: InputMaybe<BooleanFilterInput>;
  personUsable?: InputMaybe<BooleanFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  state?: InputMaybe<StateFilterInput>;
  verificationRequired?: InputMaybe<BooleanFilterInput>;
};

export type CommunicationDataTypeIdFilterInput = {
  _and?: InputMaybe<Array<CommunicationDataTypeIdFilterInput>>;
  _eq?: InputMaybe<Scalars['CommunicationDataTypeId']['input']>;
  _in?: InputMaybe<Array<Scalars['CommunicationDataTypeId']['input']>>;
  _ne?: InputMaybe<Scalars['CommunicationDataTypeId']['input']>;
  _nin?: InputMaybe<Array<Scalars['CommunicationDataTypeId']['input']>>;
  _or?: InputMaybe<Array<CommunicationDataTypeIdFilterInput>>;
};

export type CommunicationDataTypeOrderByInput = {
  baseType?: InputMaybe<OrderBy>;
  consentUsable?: InputMaybe<OrderBy>;
  customerReference?: InputMaybe<OrderBy>;
  employeeUsable?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  organizationUsable?: InputMaybe<OrderBy>;
  personUsable?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  verificationRequired?: InputMaybe<OrderBy>;
};

export enum ConsentAction {
  COLLECT_DIGITAL_CONSENT = 'COLLECT_DIGITAL_CONSENT',
  COLLECT_MANUAL_CONSENT = 'COLLECT_MANUAL_CONSENT',
  COLLECT_PAPER_CONSENT = 'COLLECT_PAPER_CONSENT',
  EXPIRE_CONSENT = 'EXPIRE_CONSENT',
  EXPIRE_PENDING_CONSENT = 'EXPIRE_PENDING_CONSENT',
  MERGED_CONSENT = 'MERGED_CONSENT',
  RECEIVE_DOUBLE_OPT_IN_CONSENT = 'RECEIVE_DOUBLE_OPT_IN_CONSENT',
  RECEIVE_PAPER_CONSENT = 'RECEIVE_PAPER_CONSENT',
  REJECT_CONSENT = 'REJECT_CONSENT',
  RESEND_DOUBLE_OPT_IN = 'RESEND_DOUBLE_OPT_IN',
  RESET_CONSENT = 'RESET_CONSENT',
  REVOKE_CONSENT = 'REVOKE_CONSENT',
  SET_CONSENT_ITEMS = 'SET_CONSENT_ITEMS',
  TRANSFER_CONSENT = 'TRANSFER_CONSENT',
}

export type ConsentActionFilterInput = {
  _eq?: InputMaybe<ConsentAction>;
  _in?: InputMaybe<Array<ConsentAction>>;
  _ne?: InputMaybe<ConsentAction>;
  _nin?: InputMaybe<Array<ConsentAction>>;
};

export type ConsentCommunicationDataType = {
  __typename?: 'ConsentCommunicationDataType';
  /** Communication data type backing this consent communication data type. */
  communicationDataType: CommunicationDataType;
  /** Description of this consent communication data type. */
  description?: Maybe<Scalars['String']['output']>;
  /** Input ID of the input field used to populate this communication data item. */
  inputId?: Maybe<Scalars['String']['output']>;
  /** Internal description of this consent communication data type. */
  internalDescription?: Maybe<Scalars['String']['output']>;
  /** Flag indicating this consent communication data collection is mandatory. */
  mandatory?: Maybe<Scalars['Boolean']['output']>;
  /** Uuid. It uniquely identifies the entity across the communication data types of this consent item. */
  oid: Scalars['ConsentCommunicationDataTypeId']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated. */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ConsentItem = {
  __typename?: 'ConsentItem';
  /** default consent item state in lifecycle state ACCEPTED. */
  acceptedItemState?: Maybe<ConsentItemStateChange>;
  /** Show if this accepted consent item is switchable. */
  acceptedSwitchable: Scalars['Boolean']['output'];
  /** List of communication data types which can be used in consent item. */
  consentCommunicationDataTypes: Array<ConsentCommunicationDataType>;
  /** Customer reference. reference, used by the customers to refer to the entity. */
  customerReference?: Maybe<Scalars['String']['output']>;
  /** default consent item state in lifecycle state DEFAULT. */
  defaultItemState?: Maybe<ConsentItemStateChange>;
  /** Description. String representation of the entity description. */
  description?: Maybe<Scalars['String']['output']>;
  /** input id of consent item */
  inputId?: Maybe<Scalars['String']['output']>;
  /** Internal description. String representation of the entity internal description. */
  internalDescription?: Maybe<Scalars['String']['output']>;
  /** Name. String representation of the entity. */
  name: Scalars['String']['output'];
  /** Uuid. It uniquely identifies the entity across the consent items. */
  oid: Scalars['ConsentItemId']['output'];
  /** default consent item state in lifecycle state PENDING_DOUBLE_OPT_IN. */
  pendingDoubleOptInItemState?: Maybe<ConsentItemStateChange>;
  /** Show if this pending double opt in is switchable. */
  pendingDoubleOptInSwitchable: Scalars['Boolean']['output'];
  /** default consent item state in lifecycle state PENDING_PAPER. */
  pendingPaperItemState?: Maybe<ConsentItemStateChange>;
  /** Show if pending paper is switchable. */
  pendingPaperSwitchable: Scalars['Boolean']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** default consent item state in lifecycle state REJECTED. */
  rejectedItemState?: Maybe<ConsentItemStateChange>;
  /** Sort code. */
  sortCode: Scalars['Int']['output'];
};

export type ConsentItemConnection = {
  __typename?: 'ConsentItemConnection';
  edges?: Maybe<Array<ConsentItemEdge>>;
  filteredCount: Scalars['Int']['output'];
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ConsentItemEdge = {
  __typename?: 'ConsentItemEdge';
  cursor: Scalars['String']['output'];
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  node?: Maybe<ConsentItem>;
};

export type ConsentItemFilterInput = {
  _and?: InputMaybe<Array<ConsentItemFilterInput>>;
  _or?: InputMaybe<Array<ConsentItemFilterInput>>;
  acceptedItemState?: InputMaybe<ConsentItemStateChangeFilterInput>;
  acceptedSwitchable?: InputMaybe<BooleanFilterInput>;
  customerReference?: InputMaybe<StringFilterInput>;
  defaultItemState?: InputMaybe<ConsentItemStateChangeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  inputId?: InputMaybe<StringFilterInput>;
  internalDescription?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  oid?: InputMaybe<ConsentItemIdFilterInput>;
  pendingDoubleOptInSwitchable?: InputMaybe<BooleanFilterInput>;
  pendingPaperItemState?: InputMaybe<ConsentItemStateChangeFilterInput>;
  pendingPaperSwitchable?: InputMaybe<BooleanFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  rejectedItemState?: InputMaybe<ConsentItemStateChangeFilterInput>;
  sortCode?: InputMaybe<IntegerFilterInput>;
};

export type ConsentItemIdFilterInput = {
  _and?: InputMaybe<Array<ConsentItemIdFilterInput>>;
  _eq?: InputMaybe<Scalars['ConsentItemId']['input']>;
  _in?: InputMaybe<Array<Scalars['ConsentItemId']['input']>>;
  _ne?: InputMaybe<Scalars['ConsentItemId']['input']>;
  _nin?: InputMaybe<Array<Scalars['ConsentItemId']['input']>>;
  _or?: InputMaybe<Array<ConsentItemIdFilterInput>>;
};

export type ConsentItemOrderByInput = {
  acceptedItemState?: InputMaybe<OrderBy>;
  acceptedSwitchable?: InputMaybe<OrderBy>;
  customerReference?: InputMaybe<OrderBy>;
  defaultItemState?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  inputId?: InputMaybe<OrderBy>;
  internalDescription?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  pendingDoubleOptInSwitchable?: InputMaybe<OrderBy>;
  pendingPaperItemState?: InputMaybe<OrderBy>;
  pendingPaperSwitchable?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  rejectedItemState?: InputMaybe<OrderBy>;
  sortCode?: InputMaybe<OrderBy>;
};

export enum ConsentItemStateChange {
  TURN_OFF = 'TURN_OFF',
  TURN_ON = 'TURN_ON',
}

export type ConsentItemStateChangeFilterInput = {
  _eq?: InputMaybe<ConsentItemStateChange>;
  _in?: InputMaybe<Array<ConsentItemStateChange>>;
  _ne?: InputMaybe<ConsentItemStateChange>;
  _nin?: InputMaybe<Array<ConsentItemStateChange>>;
};

export type ConsentType = Node & {
  __typename?: 'ConsentType';
  /** Shows if re-collection of consent allowed. */
  allowRecollection: Scalars['Boolean']['output'];
  /** List of consent items for this consent type. */
  consentItems: ConsentItemConnection;
  /** Customer reference. reference, used by the customers to refer to the entity. */
  customerReference?: Maybe<Scalars['String']['output']>;
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Description. String representation of the entity description. */
  description?: Maybe<Scalars['String']['output']>;
  /** Shows if signature process enabled for this consent type. */
  digitalSignatureProcessEnabled: Scalars['Boolean']['output'];
  /** Digital signature template in case of signature process enabled for this consent type. */
  digitalSignatureTemplate?: Maybe<Template>;
  /** Double opt in collection page template. */
  doubleOptInCollectionPageTemplate?: Maybe<Template>;
  /** Shows if digital double opt in enabled for this consent type. */
  doubleOptInDigitalEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Double opt in email template. */
  doubleOptInEmailTemplate?: Maybe<Template>;
  /** Double opt in template in case of error. */
  doubleOptInErrorPageTemplate?: Maybe<Template>;
  /** Double opt in page url in case of error. */
  doubleOptInErrorPageUrl?: Maybe<Scalars['String']['output']>;
  /** Shows if manual double opt in enabled for this consent type. */
  doubleOptInManualEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Shows if paper double opt in enabled for this consent type. */
  doubleOptInPaperEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Amount of days allowed for double opt in to keep pending state. */
  doubleOptInPendingGracePeriodInDays?: Maybe<Scalars['Int']['output']>;
  /** Double opt in template in case of success. */
  doubleOptInSuccessPageTemplate?: Maybe<Template>;
  /** Double opt in page url in case of success. */
  doubleOptInSuccessPageUrl?: Maybe<Scalars['String']['output']>;
  /** Shows if attachment should be added to the email. */
  emailWithAttachment?: Maybe<Scalars['Boolean']['output']>;
  /** End date for the consent type entity. */
  endDate?: Maybe<Scalars['Date']['output']>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Internal description. String representation of the entity internal description. */
  internalDescription?: Maybe<Scalars['String']['output']>;
  /** Current lifecycle status, e.g. IN_PREPARATION, PREPARED, ACTIVE, FINISHED, INACTIVE. */
  lifecycleStatus?: Maybe<LifecycleStatus>;
  /** Shows if manual process enabled for this consent type. */
  manualProcessEnabled: Scalars['Boolean']['output'];
  /** List of allowed reasons for manual collection of the consent. */
  manualProcessReasons: Array<ConsentTypeManualProcessReason>;
  /** Name. String representation of the entity. */
  name: Scalars['String']['output'];
  /** Uuid. It uniquely identifies the entity across the consent types. */
  oid: Scalars['ConsentTypeId']['output'];
  /** Shows if paper process enabled for this consent type. */
  paperProcessEnabled: Scalars['Boolean']['output'];
  /** Paper template in case of paper process enabled for this consent type. */
  paperTemplate?: Maybe<Template>;
  /** Amount of days allowed for pending state. */
  pendingStateGracePeriodInDays?: Maybe<Scalars['Int']['output']>;
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated. */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Start date for the consent type entity. */
  startDate: Scalars['Date']['output'];
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED */
  state: State;
  /** Shows if email should be suppressed. */
  suppressEmail: Scalars['Boolean']['output'];
  /** Consent validity end date for END_DATE mode. */
  validityEndDate?: Maybe<Scalars['Date']['output']>;
  /** Consent validity mode type. e.g. INFINITE, PERIOD, END_DATE, etc. */
  validityMode: ConsentTypeValidityMode;
  /** Consent validity period in months for PERIOD mode. */
  validityPeriodInMonths?: Maybe<Scalars['Int']['output']>;
};

export type ConsentTypeConsentItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ConsentItemFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ConsentItemOrderByInput>>;
};

export type ConsentTypeConnection = {
  __typename?: 'ConsentTypeConnection';
  /** List of edges. Default sorting is by name ASC. */
  edges?: Maybe<Array<ConsentTypeEdge>>;
  /** Count of the entities with the current filter applied. */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page. */
  pageInfo: PageInfo;
  /** Count of all the entities. */
  totalCount: Scalars['Int']['output'];
};

export type ConsentTypeEdge = {
  __typename?: 'ConsentTypeEdge';
  /** A cursor to this edge. This is the entity id. */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one. */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one. */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The consent type entity. */
  node?: Maybe<ConsentType>;
};

export type ConsentTypeFilterInput = {
  _and?: InputMaybe<Array<ConsentTypeFilterInput>>;
  _or?: InputMaybe<Array<ConsentTypeFilterInput>>;
  allowRecollection?: InputMaybe<BooleanFilterInput>;
  customerReference?: InputMaybe<StringFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  digitalSignatureProcessEnabled?: InputMaybe<BooleanFilterInput>;
  digitalSignatureTemplate?: InputMaybe<TemplateFilterInput>;
  doubleOptInCollectionPageTemplate?: InputMaybe<TemplateFilterInput>;
  doubleOptInDigitalEnabled?: InputMaybe<BooleanFilterInput>;
  doubleOptInEmailTemplate?: InputMaybe<TemplateFilterInput>;
  doubleOptInErrorPageTemplate?: InputMaybe<TemplateFilterInput>;
  doubleOptInErrorPageUrl?: InputMaybe<StringFilterInput>;
  doubleOptInManualEnabled?: InputMaybe<BooleanFilterInput>;
  doubleOptInPaperEnabled?: InputMaybe<BooleanFilterInput>;
  doubleOptInPendingGracePeriodInDays?: InputMaybe<IntegerFilterInput>;
  doubleOptInSuccessPageTemplate?: InputMaybe<TemplateFilterInput>;
  doubleOptInSuccessPageUrl?: InputMaybe<StringFilterInput>;
  emailWithAttachment?: InputMaybe<BooleanFilterInput>;
  endDate?: InputMaybe<DateFilterInput>;
  internalDescription?: InputMaybe<StringFilterInput>;
  lifecycleStatus?: InputMaybe<LifecycleStatusFilterInput>;
  manualProcessEnabled?: InputMaybe<BooleanFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  oid?: InputMaybe<ConsentTypeIdFilterInput>;
  paperProcessEnabled?: InputMaybe<BooleanFilterInput>;
  paperTemplate?: InputMaybe<TemplateFilterInput>;
  pendingStateGracePeriodInDays?: InputMaybe<IntegerFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  startDate?: InputMaybe<DateFilterInput>;
  state?: InputMaybe<StateFilterInput>;
  suppressEmail?: InputMaybe<BooleanFilterInput>;
  validityEndDate?: InputMaybe<DateFilterInput>;
  validityMode?: InputMaybe<ConsentTypeValidityModeFilterInput>;
  validityPeriodInMonths?: InputMaybe<IntegerFilterInput>;
};

export type ConsentTypeIdFilterInput = {
  _and?: InputMaybe<Array<ConsentTypeIdFilterInput>>;
  _eq?: InputMaybe<Scalars['ConsentTypeId']['input']>;
  _in?: InputMaybe<Array<Scalars['ConsentTypeId']['input']>>;
  _ne?: InputMaybe<Scalars['ConsentTypeId']['input']>;
  _nin?: InputMaybe<Array<Scalars['ConsentTypeId']['input']>>;
  _or?: InputMaybe<Array<ConsentTypeIdFilterInput>>;
};

export type ConsentTypeManualProcessReason = {
  __typename?: 'ConsentTypeManualProcessReason';
  /** Shows if Double Opt In is enabled. */
  doiEnabled: Scalars['Boolean']['output'];
  /** Uuid. It uniquely identifies the entity across the consent types. */
  oid?: Maybe<Scalars['ConsentTypeManualProcessReasonId']['output']>;
  /** Reason. String representation of the entity. */
  reason: Scalars['String']['output'];
};

export type ConsentTypeOrderByInput = {
  allowRecollection?: InputMaybe<OrderBy>;
  customerReference?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  digitalSignatureProcessEnabled?: InputMaybe<OrderBy>;
  doubleOptInDigitalEnabled?: InputMaybe<OrderBy>;
  doubleOptInErrorPageUrl?: InputMaybe<OrderBy>;
  doubleOptInManualEnabled?: InputMaybe<OrderBy>;
  doubleOptInPaperEnabled?: InputMaybe<OrderBy>;
  doubleOptInPendingGracePeriodInDays?: InputMaybe<OrderBy>;
  doubleOptInSuccessPageUrl?: InputMaybe<OrderBy>;
  emailWithAttachment?: InputMaybe<OrderBy>;
  endDate?: InputMaybe<OrderBy>;
  internalDescription?: InputMaybe<OrderBy>;
  lifecycleStatus?: InputMaybe<OrderBy>;
  manualProcessEnabled?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  paperProcessEnabled?: InputMaybe<OrderBy>;
  pendingStateGracePeriodInDays?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  suppressEmail?: InputMaybe<OrderBy>;
  validityEndDate?: InputMaybe<OrderBy>;
  validityMode?: InputMaybe<OrderBy>;
  validityPeriodInMonths?: InputMaybe<OrderBy>;
};

export enum ConsentTypeValidityMode {
  END_DATE = 'END_DATE',
  INFINITE = 'INFINITE',
  PERIOD = 'PERIOD',
}

export type ConsentTypeValidityModeFilterInput = {
  _eq?: InputMaybe<ConsentTypeValidityMode>;
  _in?: InputMaybe<Array<ConsentTypeValidityMode>>;
  _ne?: InputMaybe<ConsentTypeValidityMode>;
  _nin?: InputMaybe<Array<ConsentTypeValidityMode>>;
};

export type Coordinates = {
  __typename?: 'Coordinates';
  /** Latitude of the address */
  latitude?: Maybe<Scalars['Float']['output']>;
  /** Longitude of the address */
  longitude?: Maybe<Scalars['Float']['output']>;
};

export type Country = Node & {
  __typename?: 'Country';
  /** 2 letter ISO code, e.g. GB, DE, ES */
  alpha2Code?: Maybe<Scalars['String']['output']>;
  /** 3 letter ISO code, e.g. GBR, DEU, ESP */
  alpha3Code: Scalars['String']['output'];
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Name. String representation of the entity. */
  name?: Maybe<Scalars['String']['output']>;
  /** Uuid. It uniquely identifies the entity across the countries. */
  oid: Scalars['CountryId']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Top level domain of the country */
  tld: Scalars['String']['output'];
};

export type CountryConnection = {
  __typename?: 'CountryConnection';
  /** List of edges. Default sorting is by alpha3Code ASC */
  edges?: Maybe<Array<CountryEdge>>;
  /** Count of the entities with the current filter applied */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page */
  pageInfo: PageInfo;
  /** Count of all the entities */
  totalCount: Scalars['Int']['output'];
};

export type CountryEdge = {
  __typename?: 'CountryEdge';
  /** A cursor to this edge. This is the entity id */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The country entity */
  node?: Maybe<Country>;
};

export type CountryFilterInput = {
  _and?: InputMaybe<Array<CountryFilterInput>>;
  _or?: InputMaybe<Array<CountryFilterInput>>;
  alpha2Code?: InputMaybe<StringFilterInput>;
  alpha3Code?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  oid?: InputMaybe<CountryIdFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  tld?: InputMaybe<StringFilterInput>;
};

export type CountryIdFilterInput = {
  _and?: InputMaybe<Array<CountryIdFilterInput>>;
  _eq?: InputMaybe<Scalars['CountryId']['input']>;
  _in?: InputMaybe<Array<Scalars['CountryId']['input']>>;
  _ne?: InputMaybe<Scalars['CountryId']['input']>;
  _nin?: InputMaybe<Array<Scalars['CountryId']['input']>>;
  _or?: InputMaybe<Array<CountryIdFilterInput>>;
};

export type CountryOrderByInput = {
  alpha2Code?: InputMaybe<OrderBy>;
  alpha3Code?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  tld?: InputMaybe<OrderBy>;
};

export type DataPermission = {
  __typename?: 'DataPermission';
  /** Flag indicating if the data permission is scoped (the user has only limited access to some objects) */
  scoped: Scalars['Boolean']['output'];
  /** Type of data permission */
  type: DataPermissionType;
};

export type DataPermissionFilterInput = {
  _and?: InputMaybe<Array<DataPermissionFilterInput>>;
  _or?: InputMaybe<Array<DataPermissionFilterInput>>;
  type?: InputMaybe<DataPermissionTypeFilterInput>;
};

export enum DataPermissionType {
  ADD_ACADEMIC_TITLE = 'ADD_ACADEMIC_TITLE',
  ADD_ACTIVITY_CONFLICT_GROUP = 'ADD_ACTIVITY_CONFLICT_GROUP',
  ADD_ACTIVITY_PRESET = 'ADD_ACTIVITY_PRESET',
  ADD_ACTIVITY_TYPE = 'ADD_ACTIVITY_TYPE',
  ADD_ADDRESS = 'ADD_ADDRESS',
  ADD_AGENDA_ITEM_TYPE = 'ADD_AGENDA_ITEM_TYPE',
  ADD_AGENDA_TYPE = 'ADD_AGENDA_TYPE',
  ADD_APPROVAL = 'ADD_APPROVAL',
  ADD_ATTACHMENT = 'ADD_ATTACHMENT',
  ADD_ATTRIBUTE = 'ADD_ATTRIBUTE',
  ADD_ATTRIBUTE_CATEGORY = 'ADD_ATTRIBUTE_CATEGORY',
  ADD_BUSINESS_EVENT = 'ADD_BUSINESS_EVENT',
  ADD_BUSINESS_EVENT_INVITATION_STATUS = 'ADD_BUSINESS_EVENT_INVITATION_STATUS',
  ADD_BUSINESS_EVENT_PARTICIPATION_STATUS = 'ADD_BUSINESS_EVENT_PARTICIPATION_STATUS',
  ADD_CALL_OUTCOME = 'ADD_CALL_OUTCOME',
  ADD_CAMPAIGN = 'ADD_CAMPAIGN',
  ADD_CAMPAIGN_TARGET_PERSON = 'ADD_CAMPAIGN_TARGET_PERSON',
  ADD_COACHING_INSTANCE = 'ADD_COACHING_INSTANCE',
  ADD_COMMENT = 'ADD_COMMENT',
  ADD_COMMENT_TYPE = 'ADD_COMMENT_TYPE',
  ADD_CONSENT_DOCUMENT = 'ADD_CONSENT_DOCUMENT',
  ADD_CONSENT_TYPE = 'ADD_CONSENT_TYPE',
  ADD_COST_TYPE = 'ADD_COST_TYPE',
  ADD_DAILY_ALLOWANCE_CONFIGURATION = 'ADD_DAILY_ALLOWANCE_CONFIGURATION',
  ADD_DAILY_ALLOWANCE_REDUCTION_TYPE = 'ADD_DAILY_ALLOWANCE_REDUCTION_TYPE',
  ADD_DATA_CONSISTENCY_RULE = 'ADD_DATA_CONSISTENCY_RULE',
  ADD_DATA_SCOPE = 'ADD_DATA_SCOPE',
  ADD_DELIVERY_STATUS = 'ADD_DELIVERY_STATUS',
  ADD_DELIVERY_TIME_FRAME = 'ADD_DELIVERY_TIME_FRAME',
  ADD_DOCUMENT = 'ADD_DOCUMENT',
  ADD_EMPLOYEE = 'ADD_EMPLOYEE',
  ADD_EVENT_ORGANIZATION_ROLE = 'ADD_EVENT_ORGANIZATION_ROLE',
  ADD_EVENT_PERSON_ROLE = 'ADD_EVENT_PERSON_ROLE',
  ADD_EVENT_STATUS = 'ADD_EVENT_STATUS',
  ADD_EVENT_STATUS_TRANSITION_REASON = 'ADD_EVENT_STATUS_TRANSITION_REASON',
  ADD_EVENT_TYPE = 'ADD_EVENT_TYPE',
  ADD_FEEDBACK = 'ADD_FEEDBACK',
  ADD_KEY_MESSAGE = 'ADD_KEY_MESSAGE',
  ADD_LIST = 'ADD_LIST',
  ADD_MEDIA = 'ADD_MEDIA',
  ADD_MEDIA_TRACKING_INFO = 'ADD_MEDIA_TRACKING_INFO',
  ADD_MEDICAL_REQUEST = 'ADD_MEDICAL_REQUEST',
  ADD_MEDICAL_REQUEST_TOPIC = 'ADD_MEDICAL_REQUEST_TOPIC',
  ADD_NOTIFICATION = 'ADD_NOTIFICATION',
  ADD_ORGANIZATION = 'ADD_ORGANIZATION',
  ADD_ORGANIZATION_ASSIGNMENT = 'ADD_ORGANIZATION_ASSIGNMENT',
  ADD_PERSON = 'ADD_PERSON',
  ADD_PERSON_ASSIGNMENT = 'ADD_PERSON_ASSIGNMENT',
  ADD_PERSON_ASSIGNMENT_TYPE = 'ADD_PERSON_ASSIGNMENT_TYPE',
  ADD_POSTAL_CODE_CITY = 'ADD_POSTAL_CODE_CITY',
  ADD_PRIVILEGE = 'ADD_PRIVILEGE',
  ADD_PROMOTIONAL_MATERIAL = 'ADD_PROMOTIONAL_MATERIAL',
  ADD_PURCHASE_ORDER = 'ADD_PURCHASE_ORDER',
  ADD_PURCHASE_ORDER_REQUEST = 'ADD_PURCHASE_ORDER_REQUEST',
  ADD_QUICK_ATTRIBUTE_GROUP = 'ADD_QUICK_ATTRIBUTE_GROUP',
  ADD_RELATIONSHIP = 'ADD_RELATIONSHIP',
  ADD_RELATIONSHIP_TYPE = 'ADD_RELATIONSHIP_TYPE',
  ADD_ROLE = 'ADD_ROLE',
  ADD_SAMPLE_REQUEST_FORM = 'ADD_SAMPLE_REQUEST_FORM',
  ADD_SPECIALITY = 'ADD_SPECIALITY',
  ADD_SPECIALITY_GROUP = 'ADD_SPECIALITY_GROUP',
  ADD_STOCK_TRANSACTION_REASON = 'ADD_STOCK_TRANSACTION_REASON',
  ADD_SURVEY = 'ADD_SURVEY',
  ADD_SURVEY_INSTANCE = 'ADD_SURVEY_INSTANCE',
  ADD_SURVEY_QUESTION = 'ADD_SURVEY_QUESTION',
  ADD_SYSTEM_CONFIGURATION = 'ADD_SYSTEM_CONFIGURATION',
  ADD_SYSTEM_NOTIFICATION = 'ADD_SYSTEM_NOTIFICATION',
  ADD_TEMPLATE_TOKEN = 'ADD_TEMPLATE_TOKEN',
  ADD_TERRITORY_LIST_TO_ORGANIZATIONAL_UNIT = 'ADD_TERRITORY_LIST_TO_ORGANIZATIONAL_UNIT',
  ADD_TIME_ON_TERRITORY = 'ADD_TIME_ON_TERRITORY',
  ADD_TIME_ON_TERRITORY_TYPE = 'ADD_TIME_ON_TERRITORY_TYPE',
  ADD_TOUCH_POINT_PLAN = 'ADD_TOUCH_POINT_PLAN',
  ADD_TO_DO = 'ADD_TO_DO',
  ADD_TO_DO_CATEGORY = 'ADD_TO_DO_CATEGORY',
  ADD_USER = 'ADD_USER',
  ADD_USER_GROUP = 'ADD_USER_GROUP',
  ADD_WIZARD_CONFIGURATION = 'ADD_WIZARD_CONFIGURATION',
  ADMINISTRATE_PERSON_CONSENT = 'ADMINISTRATE_PERSON_CONSENT',
  ADMINISTRATE_PURCHASE_ORDER = 'ADMINISTRATE_PURCHASE_ORDER',
  ADMIN_BUSINESS_EVENT = 'ADMIN_BUSINESS_EVENT',
  ADMIN_DATA_CHANGE_REQUEST = 'ADMIN_DATA_CHANGE_REQUEST',
  ADMIN_EXECUTE = 'ADMIN_EXECUTE',
  APPROVE_APPROVAL = 'APPROVE_APPROVAL',
  ASSIGN_ACTIVITY_TYPE = 'ASSIGN_ACTIVITY_TYPE',
  ASSIGN_ATTRIBUTE = 'ASSIGN_ATTRIBUTE',
  ASSIGN_COMMENT_TYPE = 'ASSIGN_COMMENT_TYPE',
  ASSIGN_CONSENT_TYPE = 'ASSIGN_CONSENT_TYPE',
  ASSIGN_COST_TYPE = 'ASSIGN_COST_TYPE',
  ASSIGN_EVENT_TYPE = 'ASSIGN_EVENT_TYPE',
  ASSIGN_MEDIA = 'ASSIGN_MEDIA',
  ASSIGN_RELATIONSHIP_TYPE = 'ASSIGN_RELATIONSHIP_TYPE',
  ASSIGN_SURVEY = 'ASSIGN_SURVEY',
  ASSIGN_TEMPLATE = 'ASSIGN_TEMPLATE',
  ASSIGN_TIME_ON_TERRITORY_TYPE = 'ASSIGN_TIME_ON_TERRITORY_TYPE',
  BULK_EDIT_PURCHASE_ORDER = 'BULK_EDIT_PURCHASE_ORDER',
  CANCEL_PURCHASE_ORDER = 'CANCEL_PURCHASE_ORDER',
  CHANGE_PASSWORD_FOR_OTHER_USERS = 'CHANGE_PASSWORD_FOR_OTHER_USERS',
  CLOSE_PURCHASE_ORDER = 'CLOSE_PURCHASE_ORDER',
  COLLECT_PERSON_CONSENT = 'COLLECT_PERSON_CONSENT',
  COMPLETE_TO_DO = 'COMPLETE_TO_DO',
  CORRECT_ACTIVITY_PROMOTIONAL_MATERIAL = 'CORRECT_ACTIVITY_PROMOTIONAL_MATERIAL',
  DELETE_ACTIVITY_CONFLICT_GROUP = 'DELETE_ACTIVITY_CONFLICT_GROUP',
  DELETE_ACTIVITY_PRESET = 'DELETE_ACTIVITY_PRESET',
  DELETE_ACTIVITY_TYPE = 'DELETE_ACTIVITY_TYPE',
  DELETE_ADDRESS = 'DELETE_ADDRESS',
  DELETE_AGENDA_ITEM_TYPE = 'DELETE_AGENDA_ITEM_TYPE',
  DELETE_AGENDA_TYPE = 'DELETE_AGENDA_TYPE',
  DELETE_APPROVAL = 'DELETE_APPROVAL',
  DELETE_ATTACHMENT = 'DELETE_ATTACHMENT',
  DELETE_ATTRIBUTE = 'DELETE_ATTRIBUTE',
  DELETE_ATTRIBUTE_CATEGORY = 'DELETE_ATTRIBUTE_CATEGORY',
  DELETE_BUSINESS_EVENT = 'DELETE_BUSINESS_EVENT',
  DELETE_CALL_OUTCOME = 'DELETE_CALL_OUTCOME',
  DELETE_CAMPAIGN = 'DELETE_CAMPAIGN',
  DELETE_CAMPAIGN_TARGET_PERSON = 'DELETE_CAMPAIGN_TARGET_PERSON',
  DELETE_CONSENT_TYPE = 'DELETE_CONSENT_TYPE',
  DELETE_CONTACT_CONSENT = 'DELETE_CONTACT_CONSENT',
  DELETE_DAILY_ALLOWANCE_CONFIGURATION = 'DELETE_DAILY_ALLOWANCE_CONFIGURATION',
  DELETE_DAILY_ALLOWANCE_REDUCTION_TYPE = 'DELETE_DAILY_ALLOWANCE_REDUCTION_TYPE',
  DELETE_DATA_SCOPE = 'DELETE_DATA_SCOPE',
  DELETE_DISCOUNT_TYPE = 'DELETE_DISCOUNT_TYPE',
  DELETE_DOCUMENT = 'DELETE_DOCUMENT',
  DELETE_EMPLOYEE = 'DELETE_EMPLOYEE',
  DELETE_EVENT_TYPE = 'DELETE_EVENT_TYPE',
  DELETE_KEY_MESSAGE = 'DELETE_KEY_MESSAGE',
  DELETE_LIST = 'DELETE_LIST',
  DELETE_MEDIA = 'DELETE_MEDIA',
  DELETE_MEDIA_TRACKING_INFO = 'DELETE_MEDIA_TRACKING_INFO',
  DELETE_MEDICAL_REQUEST = 'DELETE_MEDICAL_REQUEST',
  DELETE_MEDICAL_REQUEST_TOPIC = 'DELETE_MEDICAL_REQUEST_TOPIC',
  DELETE_ORGANIZATION = 'DELETE_ORGANIZATION',
  DELETE_ORGANIZATION_TYPE = 'DELETE_ORGANIZATION_TYPE',
  DELETE_PERSON = 'DELETE_PERSON',
  DELETE_PERSON_ASSIGNMENT_TYPE = 'DELETE_PERSON_ASSIGNMENT_TYPE',
  DELETE_PERSON_TYPE = 'DELETE_PERSON_TYPE',
  DELETE_POSTAL_CODE_CITY = 'DELETE_POSTAL_CODE_CITY',
  DELETE_PRICE_LIST = 'DELETE_PRICE_LIST',
  DELETE_PRODUCT = 'DELETE_PRODUCT',
  DELETE_PROMOTIONAL_MATERIAL = 'DELETE_PROMOTIONAL_MATERIAL',
  DELETE_PURCHASE_ORDER = 'DELETE_PURCHASE_ORDER',
  DELETE_PURCHASE_ORDER_REQUEST = 'DELETE_PURCHASE_ORDER_REQUEST',
  DELETE_PURCHASE_ORDER_TYPE = 'DELETE_PURCHASE_ORDER_TYPE',
  DELETE_RELATIONSHIP = 'DELETE_RELATIONSHIP',
  DELETE_RELATIONSHIP_TYPE = 'DELETE_RELATIONSHIP_TYPE',
  DELETE_SAMPLE_REQUEST_FORM = 'DELETE_SAMPLE_REQUEST_FORM',
  DELETE_SPECIALITY = 'DELETE_SPECIALITY',
  DELETE_SPECIALITY_GROUP = 'DELETE_SPECIALITY_GROUP',
  DELETE_SYSTEM_NOTIFICATION = 'DELETE_SYSTEM_NOTIFICATION',
  DELETE_TEMPLATE = 'DELETE_TEMPLATE',
  DELETE_TEMPLATE_TOKEN = 'DELETE_TEMPLATE_TOKEN',
  DELETE_TIME_ON_TERRITORY = 'DELETE_TIME_ON_TERRITORY',
  DELETE_TIME_ON_TERRITORY_TYPE = 'DELETE_TIME_ON_TERRITORY_TYPE',
  DELETE_TOUCH_POINT_PLAN = 'DELETE_TOUCH_POINT_PLAN',
  DELETE_TO_DO = 'DELETE_TO_DO',
  DELETE_TO_DO_CATEGORY = 'DELETE_TO_DO_CATEGORY',
  DELETE_USER = 'DELETE_USER',
  DELETE_USER_GROUP = 'DELETE_USER_GROUP',
  DISTRIBUTE_STOCK = 'DISTRIBUTE_STOCK',
  EDIT_ACADEMIC_TITLE = 'EDIT_ACADEMIC_TITLE',
  EDIT_ACTIVITY = 'EDIT_ACTIVITY',
  EDIT_ACTIVITY_CONFLICT_GROUP = 'EDIT_ACTIVITY_CONFLICT_GROUP',
  EDIT_ACTIVITY_PRESET = 'EDIT_ACTIVITY_PRESET',
  EDIT_ACTIVITY_TYPE = 'EDIT_ACTIVITY_TYPE',
  EDIT_ADDRESS = 'EDIT_ADDRESS',
  EDIT_AGENDA_ITEM_TYPE = 'EDIT_AGENDA_ITEM_TYPE',
  EDIT_AGENDA_TYPE = 'EDIT_AGENDA_TYPE',
  EDIT_APPROVAL = 'EDIT_APPROVAL',
  EDIT_ATTACHMENT = 'EDIT_ATTACHMENT',
  EDIT_ATTRIBUTE = 'EDIT_ATTRIBUTE',
  EDIT_ATTRIBUTE_CATEGORY = 'EDIT_ATTRIBUTE_CATEGORY',
  EDIT_BUSINESS_EVENT = 'EDIT_BUSINESS_EVENT',
  EDIT_BUSINESS_EVENT_INVITATION_STATUS = 'EDIT_BUSINESS_EVENT_INVITATION_STATUS',
  EDIT_BUSINESS_EVENT_PARTICIPATION_STATUS = 'EDIT_BUSINESS_EVENT_PARTICIPATION_STATUS',
  EDIT_CALENDAR_CONFIGURATION = 'EDIT_CALENDAR_CONFIGURATION',
  EDIT_CALL_OUTCOME = 'EDIT_CALL_OUTCOME',
  EDIT_CAMPAIGN = 'EDIT_CAMPAIGN',
  EDIT_CAMPAIGN_TARGET_PERSON = 'EDIT_CAMPAIGN_TARGET_PERSON',
  EDIT_COACHING_INSTANCE = 'EDIT_COACHING_INSTANCE',
  EDIT_COMMENT = 'EDIT_COMMENT',
  EDIT_COMMENT_TYPE = 'EDIT_COMMENT_TYPE',
  EDIT_CONSENT_MAIL = 'EDIT_CONSENT_MAIL',
  EDIT_CONSENT_TYPE = 'EDIT_CONSENT_TYPE',
  EDIT_COST_TYPE = 'EDIT_COST_TYPE',
  EDIT_COUNTRY_CONFIGURATION = 'EDIT_COUNTRY_CONFIGURATION',
  EDIT_DAILY_ALLOWANCE_CONFIGURATION = 'EDIT_DAILY_ALLOWANCE_CONFIGURATION',
  EDIT_DAILY_ALLOWANCE_REDUCTION_TYPE = 'EDIT_DAILY_ALLOWANCE_REDUCTION_TYPE',
  EDIT_DATA_CONSISTENCY_RULE = 'EDIT_DATA_CONSISTENCY_RULE',
  EDIT_DATA_SCOPE = 'EDIT_DATA_SCOPE',
  EDIT_DELIVERY_STATUS = 'EDIT_DELIVERY_STATUS',
  EDIT_DELIVERY_TIME_FRAME = 'EDIT_DELIVERY_TIME_FRAME',
  EDIT_DISCOUNT_TYPE = 'EDIT_DISCOUNT_TYPE',
  EDIT_DOCUMENT = 'EDIT_DOCUMENT',
  EDIT_EMPLOYEE = 'EDIT_EMPLOYEE',
  EDIT_EMPLOYEE_FUNCTION = 'EDIT_EMPLOYEE_FUNCTION',
  EDIT_EVENT_ORGANIZATION_ROLE = 'EDIT_EVENT_ORGANIZATION_ROLE',
  EDIT_EVENT_PERSON_ROLE = 'EDIT_EVENT_PERSON_ROLE',
  EDIT_EVENT_STATUS = 'EDIT_EVENT_STATUS',
  EDIT_EVENT_STATUS_TRANSITION_REASON = 'EDIT_EVENT_STATUS_TRANSITION_REASON',
  EDIT_EVENT_TYPE = 'EDIT_EVENT_TYPE',
  EDIT_FEEDBACK = 'EDIT_FEEDBACK',
  EDIT_GIVEN_PROMOTIONAL_MATERIAL_CUSTOM_CHANGE_REASON = 'EDIT_GIVEN_PROMOTIONAL_MATERIAL_CUSTOM_CHANGE_REASON',
  EDIT_INVENTORY = 'EDIT_INVENTORY',
  EDIT_KEY_MESSAGE = 'EDIT_KEY_MESSAGE',
  EDIT_LIST = 'EDIT_LIST',
  EDIT_MEDIA = 'EDIT_MEDIA',
  EDIT_MEDIA_TRACKING_INFO = 'EDIT_MEDIA_TRACKING_INFO',
  EDIT_MEDICAL_REQUEST = 'EDIT_MEDICAL_REQUEST',
  EDIT_MEDICAL_REQUEST_TOPIC = 'EDIT_MEDICAL_REQUEST_TOPIC',
  EDIT_NOTIFICATION = 'EDIT_NOTIFICATION',
  EDIT_ORGANIZATION = 'EDIT_ORGANIZATION',
  EDIT_ORGANIZATION_ASSIGNMENT = 'EDIT_ORGANIZATION_ASSIGNMENT',
  EDIT_ORGANIZATION_TYPE = 'EDIT_ORGANIZATION_TYPE',
  EDIT_PAYMENT_METHOD = 'EDIT_PAYMENT_METHOD',
  EDIT_PERSON = 'EDIT_PERSON',
  EDIT_PERSON_ASSIGNMENT = 'EDIT_PERSON_ASSIGNMENT',
  EDIT_PERSON_ASSIGNMENT_TYPE = 'EDIT_PERSON_ASSIGNMENT_TYPE',
  EDIT_PERSON_CONSENT = 'EDIT_PERSON_CONSENT',
  EDIT_PERSON_TYPE = 'EDIT_PERSON_TYPE',
  EDIT_POSTAL_CODE_CITY = 'EDIT_POSTAL_CODE_CITY',
  EDIT_PRICE_LIST = 'EDIT_PRICE_LIST',
  EDIT_PRICE_TYPE = 'EDIT_PRICE_TYPE',
  EDIT_PRIVILEGE = 'EDIT_PRIVILEGE',
  EDIT_PRODUCT = 'EDIT_PRODUCT',
  EDIT_PRODUCT_TYPE = 'EDIT_PRODUCT_TYPE',
  EDIT_PROMOTIONAL_MATERIAL = 'EDIT_PROMOTIONAL_MATERIAL',
  EDIT_PURCHASE_ORDER = 'EDIT_PURCHASE_ORDER',
  EDIT_PURCHASE_ORDER_REQUEST = 'EDIT_PURCHASE_ORDER_REQUEST',
  EDIT_PURCHASE_ORDER_TYPE = 'EDIT_PURCHASE_ORDER_TYPE',
  EDIT_QUICK_ATTRIBUTE_GROUP = 'EDIT_QUICK_ATTRIBUTE_GROUP',
  EDIT_RELATIONSHIP = 'EDIT_RELATIONSHIP',
  EDIT_RELATIONSHIP_TYPE = 'EDIT_RELATIONSHIP_TYPE',
  EDIT_REMOTE_INTERACTION = 'EDIT_REMOTE_INTERACTION',
  EDIT_ROLE = 'EDIT_ROLE',
  EDIT_SPECIALITY = 'EDIT_SPECIALITY',
  EDIT_SPECIALITY_GROUP = 'EDIT_SPECIALITY_GROUP',
  EDIT_SPECIALITY_GROUP_MAPPING = 'EDIT_SPECIALITY_GROUP_MAPPING',
  EDIT_STAND_IN = 'EDIT_STAND_IN',
  EDIT_STOCK = 'EDIT_STOCK',
  EDIT_STOCK_TRANSACTION_REASON = 'EDIT_STOCK_TRANSACTION_REASON',
  EDIT_SURVEY = 'EDIT_SURVEY',
  EDIT_SURVEY_INSTANCE = 'EDIT_SURVEY_INSTANCE',
  EDIT_SURVEY_QUESTION = 'EDIT_SURVEY_QUESTION',
  EDIT_SYSTEM_CONFIGURATION = 'EDIT_SYSTEM_CONFIGURATION',
  EDIT_TEMPLATE = 'EDIT_TEMPLATE',
  EDIT_TEMPLATE_TOKEN = 'EDIT_TEMPLATE_TOKEN',
  EDIT_TIME_ON_TERRITORY = 'EDIT_TIME_ON_TERRITORY',
  EDIT_TIME_ON_TERRITORY_TYPE = 'EDIT_TIME_ON_TERRITORY_TYPE',
  EDIT_TOUCH_POINT_PLAN = 'EDIT_TOUCH_POINT_PLAN',
  EDIT_TO_DO = 'EDIT_TO_DO',
  EDIT_TO_DO_CATEGORY = 'EDIT_TO_DO_CATEGORY',
  EDIT_USER = 'EDIT_USER',
  EDIT_USER_GROUP = 'EDIT_USER_GROUP',
  EDIT_WIZARD_CONFIGURATION = 'EDIT_WIZARD_CONFIGURATION',
  HANDLE_TEMPLATE_PLACEHOLDER_COLLECTION = 'HANDLE_TEMPLATE_PLACEHOLDER_COLLECTION',
  IMPERSONATE_USER = 'IMPERSONATE_USER',
  IMPORT_PURCHASE_ORDER = 'IMPORT_PURCHASE_ORDER',
  MERGE_ORGANIZATION = 'MERGE_ORGANIZATION',
  MERGE_PERSON = 'MERGE_PERSON',
  PROCESS_PURCHASE_ORDER = 'PROCESS_PURCHASE_ORDER',
  READ_ACADEMIC_TITLE = 'READ_ACADEMIC_TITLE',
  READ_ACTIVITY_PRESET = 'READ_ACTIVITY_PRESET',
  READ_ACTIVITY_TYPE = 'READ_ACTIVITY_TYPE',
  READ_ADDRESS = 'READ_ADDRESS',
  READ_APPROVAL = 'READ_APPROVAL',
  READ_ATTRIBUTE = 'READ_ATTRIBUTE',
  READ_ATTRIBUTE_CATEGORY = 'READ_ATTRIBUTE_CATEGORY',
  READ_BUSINESS_EVENT = 'READ_BUSINESS_EVENT',
  READ_BUSINESS_EVENT_INVITATION_STATUS = 'READ_BUSINESS_EVENT_INVITATION_STATUS',
  READ_BUSINESS_EVENT_PARTICIPATION_STATUS = 'READ_BUSINESS_EVENT_PARTICIPATION_STATUS',
  READ_CALENDAR_CONFIGURATION = 'READ_CALENDAR_CONFIGURATION',
  READ_CALL_OUTCOME = 'READ_CALL_OUTCOME',
  READ_CAMPAIGN = 'READ_CAMPAIGN',
  READ_CAMPAIGN_STATISTICS = 'READ_CAMPAIGN_STATISTICS',
  READ_CAMPAIGN_TARGET_PERSON = 'READ_CAMPAIGN_TARGET_PERSON',
  READ_COACHING_INSTANCE = 'READ_COACHING_INSTANCE',
  READ_COMMENT = 'READ_COMMENT',
  READ_COMMENT_TYPE = 'READ_COMMENT_TYPE',
  READ_COMMUNICATION_DATA_TYPE = 'READ_COMMUNICATION_DATA_TYPE',
  READ_CONSENT_TYPE = 'READ_CONSENT_TYPE',
  READ_COST_TYPE = 'READ_COST_TYPE',
  READ_COUNTRY_CONFIGURATION = 'READ_COUNTRY_CONFIGURATION',
  READ_DAILY_ALLOWANCE_CONFIGURATION = 'READ_DAILY_ALLOWANCE_CONFIGURATION',
  READ_DAILY_ALLOWANCE_REDUCTION_TYPE = 'READ_DAILY_ALLOWANCE_REDUCTION_TYPE',
  READ_DATA_SCOPE = 'READ_DATA_SCOPE',
  READ_DELIVERY_STATUS = 'READ_DELIVERY_STATUS',
  READ_DELIVERY_TIME_FRAME = 'READ_DELIVERY_TIME_FRAME',
  READ_DISCOUNT_TYPE = 'READ_DISCOUNT_TYPE',
  READ_DOCUMENT = 'READ_DOCUMENT',
  READ_EMPLOYEE = 'READ_EMPLOYEE',
  READ_EMPLOYEE_DEPARTMENT = 'READ_EMPLOYEE_DEPARTMENT',
  READ_EMPLOYEE_FUNCTION = 'READ_EMPLOYEE_FUNCTION',
  READ_EVENT_ORGANIZATION_ROLE = 'READ_EVENT_ORGANIZATION_ROLE',
  READ_EVENT_PERSON_ROLE = 'READ_EVENT_PERSON_ROLE',
  READ_EVENT_STATUS = 'READ_EVENT_STATUS',
  READ_EVENT_STATUS_TRANSITION_REASON = 'READ_EVENT_STATUS_TRANSITION_REASON',
  READ_EVENT_TYPE = 'READ_EVENT_TYPE',
  READ_FEEDBACK = 'READ_FEEDBACK',
  READ_GIVEN_PROMOTIONAL_MATERIAL_CUSTOM_CHANGE_REASON = 'READ_GIVEN_PROMOTIONAL_MATERIAL_CUSTOM_CHANGE_REASON',
  READ_INVENTORY = 'READ_INVENTORY',
  READ_KEY_MESSAGE = 'READ_KEY_MESSAGE',
  READ_LIST = 'READ_LIST',
  READ_MEDIA = 'READ_MEDIA',
  READ_MEDIA_TRACKING_INFO = 'READ_MEDIA_TRACKING_INFO',
  READ_MEDICAL_REQUEST = 'READ_MEDICAL_REQUEST',
  READ_MEDICAL_REQUEST_TOPIC = 'READ_MEDICAL_REQUEST_TOPIC',
  READ_ORGANIZATION = 'READ_ORGANIZATION',
  READ_ORGANIZATION_ASSIGNMENT = 'READ_ORGANIZATION_ASSIGNMENT',
  READ_ORGANIZATION_TYPE = 'READ_ORGANIZATION_TYPE',
  READ_PAYMENT_METHOD = 'READ_PAYMENT_METHOD',
  READ_PERSON = 'READ_PERSON',
  READ_PERSON_ASSIGNMENT = 'READ_PERSON_ASSIGNMENT',
  READ_PERSON_ASSIGNMENT_TYPE = 'READ_PERSON_ASSIGNMENT_TYPE',
  READ_PERSON_CONSENT = 'READ_PERSON_CONSENT',
  READ_PERSON_TYPE = 'READ_PERSON_TYPE',
  READ_POSTAL_CODE_CITY = 'READ_POSTAL_CODE_CITY',
  READ_PRICE_LIST = 'READ_PRICE_LIST',
  READ_PRICE_TYPE = 'READ_PRICE_TYPE',
  READ_PRIVILEGE = 'READ_PRIVILEGE',
  READ_PRODUCT = 'READ_PRODUCT',
  READ_PRODUCT_FAMILY = 'READ_PRODUCT_FAMILY',
  READ_PRODUCT_TYPE = 'READ_PRODUCT_TYPE',
  READ_PROMOTIONAL_MATERIAL = 'READ_PROMOTIONAL_MATERIAL',
  READ_PURCHASE_ORDER = 'READ_PURCHASE_ORDER',
  READ_PURCHASE_ORDER_REQUEST = 'READ_PURCHASE_ORDER_REQUEST',
  READ_PURCHASE_ORDER_TYPE = 'READ_PURCHASE_ORDER_TYPE',
  READ_QUICK_ATTRIBUTE_GROUP = 'READ_QUICK_ATTRIBUTE_GROUP',
  READ_RELATIONSHIP = 'READ_RELATIONSHIP',
  READ_RELATIONSHIP_TYPE = 'READ_RELATIONSHIP_TYPE',
  READ_REMOTE_INTERACTION = 'READ_REMOTE_INTERACTION',
  READ_ROLE = 'READ_ROLE',
  READ_SAMPLE_REQUEST_FORM = 'READ_SAMPLE_REQUEST_FORM',
  READ_SPECIALITY = 'READ_SPECIALITY',
  READ_SPECIALITY_GROUP = 'READ_SPECIALITY_GROUP',
  READ_SURVEY = 'READ_SURVEY',
  READ_SURVEY_INSTANCE = 'READ_SURVEY_INSTANCE',
  READ_SURVEY_QUESTION = 'READ_SURVEY_QUESTION',
  READ_TEMPLATE = 'READ_TEMPLATE',
  READ_TIME_ON_TERRITORY = 'READ_TIME_ON_TERRITORY',
  READ_TIME_ON_TERRITORY_TYPE = 'READ_TIME_ON_TERRITORY_TYPE',
  READ_TOPIC = 'READ_TOPIC',
  READ_TOUCH_POINT_PLAN = 'READ_TOUCH_POINT_PLAN',
  READ_TO_DO = 'READ_TO_DO',
  READ_TO_DO_CATEGORY = 'READ_TO_DO_CATEGORY',
  READ_USER = 'READ_USER',
  READ_USER_GROUP = 'READ_USER_GROUP',
  READ_WIZARD_CONFIGURATION = 'READ_WIZARD_CONFIGURATION',
  REQUEST_ACTIVITY_APPROVAL = 'REQUEST_ACTIVITY_APPROVAL',
  SEND_REPORT = 'SEND_REPORT',
  SET_GDPR_STATUS_PERSON = 'SET_GDPR_STATUS_PERSON',
  SET_TOUCH_POINT_PLAN_INSTANCE = 'SET_TOUCH_POINT_PLAN_INSTANCE',
  SKIP_ACTIVITY_ATTENDEES_RULES_VALIDATOR = 'SKIP_ACTIVITY_ATTENDEES_RULES_VALIDATOR',
  SKIP_ACTIVITY_CONFLICT_GROUP_VALIDATOR = 'SKIP_ACTIVITY_CONFLICT_GROUP_VALIDATOR',
  SKIP_ACTIVITY_EDITOR_VALIDATOR = 'SKIP_ACTIVITY_EDITOR_VALIDATOR',
  SKIP_ACTIVITY_MEDIA_RULES_VALIDATOR = 'SKIP_ACTIVITY_MEDIA_RULES_VALIDATOR',
  SKIP_ACTIVITY_PROMOTIONAL_MATERIAL_HANDOUT_PERIOD_VALIDATOR = 'SKIP_ACTIVITY_PROMOTIONAL_MATERIAL_HANDOUT_PERIOD_VALIDATOR',
  SKIP_ACTIVITY_PROMOTIONAL_MATERIAL_INVENTORY_VALIDATOR = 'SKIP_ACTIVITY_PROMOTIONAL_MATERIAL_INVENTORY_VALIDATOR',
  SKIP_ACTIVITY_PROMOTIONAL_MATERIAL_RULES_VALIDATOR = 'SKIP_ACTIVITY_PROMOTIONAL_MATERIAL_RULES_VALIDATOR',
  SKIP_ACTIVITY_SAMPLE_REQUEST_FORM_SIGNATURE_VALIDATOR = 'SKIP_ACTIVITY_SAMPLE_REQUEST_FORM_SIGNATURE_VALIDATOR',
  SKIP_ACTIVITY_SAMPLE_REQUEST_FORM_VALIDATOR = 'SKIP_ACTIVITY_SAMPLE_REQUEST_FORM_VALIDATOR',
  SKIP_ACTIVITY_SEALED_VALIDATOR = 'SKIP_ACTIVITY_SEALED_VALIDATOR',
  SKIP_ACTIVITY_SIMILAR_EXISTS_VALIDATOR = 'SKIP_ACTIVITY_SIMILAR_EXISTS_VALIDATOR',
  SKIP_ACTIVITY_STATUS_VALIDATOR = 'SKIP_ACTIVITY_STATUS_VALIDATOR',
  SKIP_ACTIVITY_TIME_IN_PAST_VALIDATOR = 'SKIP_ACTIVITY_TIME_IN_PAST_VALIDATOR',
  SKIP_ACTIVITY_WITH_SAMPLES_CANNOT_BE_DELETED_VALIDATOR = 'SKIP_ACTIVITY_WITH_SAMPLES_CANNOT_BE_DELETED_VALIDATOR',
  SKIP_ATTRIBUTE_CONSTRAINTS_VALIDATION = 'SKIP_ATTRIBUTE_CONSTRAINTS_VALIDATION',
  SKIP_DATA_CHANGE_REQUEST = 'SKIP_DATA_CHANGE_REQUEST',
  SKIP_PERSON_ASSIGNMENT_VALIDATOR = 'SKIP_PERSON_ASSIGNMENT_VALIDATOR',
  SKIP_PERSON_CONSENT_EDITOR_VALIDATOR = 'SKIP_PERSON_CONSENT_EDITOR_VALIDATOR',
  SKIP_PERSON_CONSENT_FORM_LIMITS_CHECK = 'SKIP_PERSON_CONSENT_FORM_LIMITS_CHECK',
  SKIP_PURCHASE_ORDER_PAYMENT_METHOD_VALIDATION = 'SKIP_PURCHASE_ORDER_PAYMENT_METHOD_VALIDATION',
  SYSTEM_RELEVANT_LIST = 'SYSTEM_RELEVANT_LIST',
}

export type DataPermissionTypeFilterInput = {
  _eq?: InputMaybe<DataPermissionType>;
  _in?: InputMaybe<Array<DataPermissionType>>;
  _ne?: InputMaybe<DataPermissionType>;
  _nin?: InputMaybe<Array<DataPermissionType>>;
};

export type DateFilterInput = {
  /** Equals the value which is passed */
  _eq?: InputMaybe<Scalars['Date']['input']>;
  /** Greater than or equals the value which is passed */
  _ge?: InputMaybe<Scalars['Date']['input']>;
  /** Greater than the value which is passed */
  _gt?: InputMaybe<Scalars['Date']['input']>;
  /** Less than or equals the value which is passed */
  _le?: InputMaybe<Scalars['Date']['input']>;
  /** Less than the value which is passed */
  _lt?: InputMaybe<Scalars['Date']['input']>;
  /** Not equals the value which is passed */
  _ne?: InputMaybe<Scalars['Date']['input']>;
};

export type DateTimeFilterInput = {
  /** Equals the value which is passed */
  _eq?: InputMaybe<Scalars['DateTime']['input']>;
  /** Greater than or equals the value which is passed */
  _ge?: InputMaybe<Scalars['DateTime']['input']>;
  /** Greater than the value which is passed */
  _gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Less than or equals the value which is passed */
  _le?: InputMaybe<Scalars['DateTime']['input']>;
  /** Less than the value which is passed */
  _lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Not equals the value which is passed */
  _ne?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DeliveryTimeFrame = Node & {
  __typename?: 'DeliveryTimeFrame';
  /** Customer reference. reference, used by the customers to refer to the entity. */
  customerReference?: Maybe<Scalars['String']['output']>;
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Name. String representation of the entity. */
  name: Scalars['String']['output'];
  /** Uuid. It uniquely identifies the entity across the delivery time frame. */
  oid: Scalars['DeliveryTimeFrameId']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated. */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED */
  state?: Maybe<State>;
};

export type DeliveryTimeFrameConnection = {
  __typename?: 'DeliveryTimeFrameConnection';
  /** List of edges. Default sorting is by name ASC. */
  edges?: Maybe<Array<DeliveryTimeFrameEdge>>;
  /** Count of the entities with the current filter applied. */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page. */
  pageInfo: PageInfo;
  /** Count of all the entities. */
  totalCount: Scalars['Int']['output'];
};

export type DeliveryTimeFrameEdge = {
  __typename?: 'DeliveryTimeFrameEdge';
  /** A cursor to this edge. This is the entity id. */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one. */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one. */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The topic entity. */
  node?: Maybe<DeliveryTimeFrame>;
};

export type DeliveryTimeFrameFilterInput = {
  _and?: InputMaybe<Array<DeliveryTimeFrameFilterInput>>;
  _or?: InputMaybe<Array<DeliveryTimeFrameFilterInput>>;
  customerReference?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  oid?: InputMaybe<DeliveryTimeFrameIdFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  state?: InputMaybe<StateFilterInput>;
};

export type DeliveryTimeFrameIdFilterInput = {
  _and?: InputMaybe<Array<DeliveryTimeFrameIdFilterInput>>;
  _eq?: InputMaybe<Scalars['DeliveryTimeFrameId']['input']>;
  _in?: InputMaybe<Array<Scalars['DeliveryTimeFrameId']['input']>>;
  _ne?: InputMaybe<Scalars['DeliveryTimeFrameId']['input']>;
  _nin?: InputMaybe<Array<Scalars['DeliveryTimeFrameId']['input']>>;
  _or?: InputMaybe<Array<DeliveryTimeFrameIdFilterInput>>;
};

export type DeliveryTimeFrameOrderByInput = {
  customerReference?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
};

export type Document = Node & {
  __typename?: 'Document';
  /** Link for downloading the document content */
  contentLink: Scalars['String']['output'];
  /** Date when the document was created */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Type of the media, MIME type, e.g. application/pdf, image/jpeg. etc */
  mediaType: Scalars['String']['output'];
  /** Name. String representation of the entity. */
  name?: Maybe<Scalars['String']['output']>;
  /** Uuid. It uniquely identifies the entity across the documents. */
  oid: Scalars['DocumentId']['output'];
  /** user who owns the document */
  owner?: Maybe<User>;
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Site in bytes for this version of the document */
  size: Scalars['Int']['output'];
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED */
  state: State;
};

export type DocumentConnection = {
  __typename?: 'DocumentConnection';
  /** List of edges. Default sorting is by name ASC */
  edges?: Maybe<Array<DocumentEdge>>;
  /** Count of the entities with the current filter applied */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page */
  pageInfo: PageInfo;
  /** Count of all the entities */
  totalCount: Scalars['Int']['output'];
};

export type DocumentEdge = {
  __typename?: 'DocumentEdge';
  /** A cursor to this edge. This is the entity id */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The academic title entity */
  node?: Maybe<Document>;
};

export type DocumentFilterInput = {
  _and?: InputMaybe<Array<DocumentFilterInput>>;
  _or?: InputMaybe<Array<DocumentFilterInput>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  oid?: InputMaybe<DocumentIdFilterInput>;
  owner?: InputMaybe<UserFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  state?: InputMaybe<StateFilterInput>;
};

export type DocumentIdFilterInput = {
  _and?: InputMaybe<Array<DocumentIdFilterInput>>;
  _eq?: InputMaybe<Scalars['DocumentId']['input']>;
  _in?: InputMaybe<Array<Scalars['DocumentId']['input']>>;
  _ne?: InputMaybe<Scalars['DocumentId']['input']>;
  _nin?: InputMaybe<Array<Scalars['DocumentId']['input']>>;
  _or?: InputMaybe<Array<DocumentIdFilterInput>>;
};

export type DocumentOrderByInput = {
  createdAt?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
};

export type EmployeeDepartment = Node & {
  __typename?: 'EmployeeDepartment';
  /** Customer reference. reference, used by the customers to refer to the entity. */
  customerReference?: Maybe<Scalars['String']['output']>;
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Name. String representation of the entity. */
  name?: Maybe<Scalars['String']['output']>;
  /** Uuid. It uniquely identifies the entity across the employee department. */
  oid: Scalars['EmployeeDepartmentId']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated. */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED */
  state: State;
};

export type EmployeeDepartmentConnection = {
  __typename?: 'EmployeeDepartmentConnection';
  /** List of edges. Default sorting is by name ASC. */
  edges?: Maybe<Array<EmployeeDepartmentEdge>>;
  /** Count of the entities with the current filter applied. */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page. */
  pageInfo: PageInfo;
  /** Count of all the entities. */
  totalCount: Scalars['Int']['output'];
};

export type EmployeeDepartmentEdge = {
  __typename?: 'EmployeeDepartmentEdge';
  /** A cursor to this edge. This is the entity id. */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The employee department entity. */
  node?: Maybe<EmployeeDepartment>;
};

export type EmployeeDepartmentFilterInput = {
  _and?: InputMaybe<Array<EmployeeDepartmentFilterInput>>;
  _or?: InputMaybe<Array<EmployeeDepartmentFilterInput>>;
  customerReference?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  oid?: InputMaybe<EmployeeDepartmentIdFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  state?: InputMaybe<StateFilterInput>;
};

export type EmployeeDepartmentIdFilterInput = {
  _and?: InputMaybe<Array<EmployeeDepartmentIdFilterInput>>;
  _eq?: InputMaybe<Scalars['EmployeeDepartmentId']['input']>;
  _in?: InputMaybe<Array<Scalars['EmployeeDepartmentId']['input']>>;
  _ne?: InputMaybe<Scalars['EmployeeDepartmentId']['input']>;
  _nin?: InputMaybe<Array<Scalars['EmployeeDepartmentId']['input']>>;
  _or?: InputMaybe<Array<EmployeeDepartmentIdFilterInput>>;
};

export type EmployeeDepartmentOrderByInput = {
  customerReference?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
};

export type EmployeeFunction = Node & {
  __typename?: 'EmployeeFunction';
  /** Customer reference. reference, used by the customers to refer to the entity. */
  customerReference?: Maybe<Scalars['String']['output']>;
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Name. String representation of the entity. */
  name?: Maybe<Scalars['String']['output']>;
  /** Uuid. It uniquely identifies the entity across the employee functions. */
  oid: Scalars['EmployeeFunctionId']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated. */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED */
  state: State;
};

export type EmployeeFunctionConnection = {
  __typename?: 'EmployeeFunctionConnection';
  /** List of edges. Default sorting is by name ASC. */
  edges?: Maybe<Array<EmployeeFunctionEdge>>;
  /** Count of the entities with the current filter applied. */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page. */
  pageInfo: PageInfo;
  /** Count of all the entities. */
  totalCount: Scalars['Int']['output'];
};

export type EmployeeFunctionEdge = {
  __typename?: 'EmployeeFunctionEdge';
  /** A cursor to this edge. This is the entity id. */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The employee function entity. */
  node?: Maybe<EmployeeFunction>;
};

export type EmployeeFunctionFilterInput = {
  _and?: InputMaybe<Array<EmployeeFunctionFilterInput>>;
  _or?: InputMaybe<Array<EmployeeFunctionFilterInput>>;
  customerReference?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  oid?: InputMaybe<EmployeeFunctionIdFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  state?: InputMaybe<StateFilterInput>;
};

export type EmployeeFunctionIdFilterInput = {
  _and?: InputMaybe<Array<EmployeeFunctionIdFilterInput>>;
  _eq?: InputMaybe<Scalars['EmployeeFunctionId']['input']>;
  _in?: InputMaybe<Array<Scalars['EmployeeFunctionId']['input']>>;
  _ne?: InputMaybe<Scalars['EmployeeFunctionId']['input']>;
  _nin?: InputMaybe<Array<Scalars['EmployeeFunctionId']['input']>>;
  _or?: InputMaybe<Array<EmployeeFunctionIdFilterInput>>;
};

export type EmployeeFunctionOrderByInput = {
  customerReference?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
};

export type Employment = Node & {
  __typename?: 'Employment';
  /** Addresses of the employment. Entities are ordered by address type */
  addresses: EntityAddressConnection;
  /** List of employment communication data. Entities are ordered by communication data type */
  communicationData: CommunicationDataConnection;
  /** Customer reference. Reference, used by the customers to refer to the entity. */
  customerReference?: Maybe<Scalars['String']['output']>;
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Department where is the employment */
  department?: Maybe<EmployeeDepartment>;
  /** Function of the employment */
  function?: Maybe<EmployeeFunction>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Uuid. It uniquely identifies the entity across the employments. */
  oid: Scalars['EmployeeId']['output'];
  /** Organization where is the employment */
  organization: Organization;
  /** Person to whom the employment belongs */
  person: Person;
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Sort code. Used to prioritise employments within same person. */
  sortCode?: Maybe<Scalars['Int']['output']>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED */
  state: State;
};

export type EmploymentAddressesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EntityAddressFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntityAddressOrderByInput>>;
};

export type EmploymentCommunicationDataArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommunicationDataFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommunicationDataOrderByInput>>;
};

export type EmploymentConnection = {
  __typename?: 'EmploymentConnection';
  /** List of edges. Default sorting is by sortCode ASC */
  edges?: Maybe<Array<EmploymentEdge>>;
  /** Count of the entities with the current filter applied */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page */
  pageInfo: PageInfo;
  /** Count of all the entities */
  totalCount: Scalars['Int']['output'];
};

export type EmploymentEdge = {
  __typename?: 'EmploymentEdge';
  /** A cursor to this edge. This is the entity id */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The employment entity */
  node?: Maybe<Employment>;
};

export type EmploymentFilterInput = {
  _and?: InputMaybe<Array<EmploymentFilterInput>>;
  _or?: InputMaybe<Array<EmploymentFilterInput>>;
  addresses?: InputMaybe<EntityAddressFilterInput>;
  communicationData?: InputMaybe<CommunicationDataFilterInput>;
  customerReference?: InputMaybe<StringFilterInput>;
  department?: InputMaybe<EmployeeDepartmentFilterInput>;
  function?: InputMaybe<EmployeeFunctionFilterInput>;
  oid?: InputMaybe<EmploymentIdFilterInput>;
  organization?: InputMaybe<OrganizationFilterInput>;
  person?: InputMaybe<PersonFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  sortCode?: InputMaybe<IntegerFilterInput>;
  state?: InputMaybe<StateFilterInput>;
};

export type EmploymentIdFilterInput = {
  _and?: InputMaybe<Array<EmploymentIdFilterInput>>;
  _eq?: InputMaybe<Scalars['EmployeeId']['input']>;
  _in?: InputMaybe<Array<Scalars['EmployeeId']['input']>>;
  _ne?: InputMaybe<Scalars['EmployeeId']['input']>;
  _nin?: InputMaybe<Array<Scalars['EmployeeId']['input']>>;
  _or?: InputMaybe<Array<EmploymentIdFilterInput>>;
};

export type EmploymentOrderByInput = {
  customerReference?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  sortCode?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
};

export type EntityAddress = {
  __typename?: 'EntityAddress';
  /** Address entity */
  address: Address;
  /** Type of the address for the entity, e.g. STANDARD, DELIVERY, LEGAL, VISIT */
  addressType: AddressType;
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type EntityAddressConnection = {
  __typename?: 'EntityAddressConnection';
  /** List of edges. Default sorting is by addressType ASC */
  edges?: Maybe<Array<EntityAddressEdge>>;
  /** Count of the entities with the current filter applied */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page */
  pageInfo: PageInfo;
  /** Count of all the addresses of the entity */
  totalCount: Scalars['Int']['output'];
};

export type EntityAddressEdge = {
  __typename?: 'EntityAddressEdge';
  /** A cursor to this edge. This is the entity id */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The person address entity */
  node?: Maybe<EntityAddress>;
};

export type EntityAddressFilterInput = {
  _and?: InputMaybe<Array<EntityAddressFilterInput>>;
  _or?: InputMaybe<Array<EntityAddressFilterInput>>;
  address?: InputMaybe<AddressFilterInput>;
  addressType?: InputMaybe<AddressTypeFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type EntityAddressOrderByInput = {
  address?: InputMaybe<AddressOrderByInput>;
  addressType?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
};

export type FacetsPreference = {
  __typename?: 'FacetsPreference';
  /** Preference for organization facets. */
  organizationFacets: OrganizationFacetsPreference;
  /** Preference for person facets. */
  personFacets: PersonFacetsPreference;
};

export type FacetsPreferenceInput = {
  /** Preference for organization facets. */
  organizationFacets?: InputMaybe<OrganizationFacetsPreferenceInput>;
  /** Preference for person facets. */
  personFacets?: InputMaybe<PersonFacetsPreferenceInput>;
};

export type Feedback = Node & {
  __typename?: 'Feedback';
  /** This contain the activity entity if the feedback is attached to an activity. */
  activity: Activity;
  /** Content of the feedback. */
  content?: Maybe<Scalars['String']['output']>;
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** UUID. It uniquely identifies the entity across the feedback entities. */
  oid: Scalars['FeedbackId']['output'];
  /** Rating if the feedback. Number between 1-5. */
  rating: Scalars['Int']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED */
  state: State;
  /** This contains the type of the entity to which the feedback is attached. */
  targetType: FeedbackTargetType;
};

export enum FeedbackTargetType {
  ACTIVITY = 'ACTIVITY',
  ACTIVITY_ATTENDEE = 'ACTIVITY_ATTENDEE',
}

export type FloatFilterInput = {
  /** Equals the value which is passed */
  _eq?: InputMaybe<Scalars['Float']['input']>;
  /** Greater than or equals the value which is passed */
  _ge?: InputMaybe<Scalars['Float']['input']>;
  /** Greater than the value which is passed */
  _gt?: InputMaybe<Scalars['Float']['input']>;
  /** Less than or equals the value which is passed */
  _le?: InputMaybe<Scalars['Float']['input']>;
  /** Less than the value which is passed */
  _lt?: InputMaybe<Scalars['Float']['input']>;
  /** Not equals the value which is passed */
  _ne?: InputMaybe<Scalars['Float']['input']>;
};

export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  NOT_KNOWN = 'NOT_KNOWN',
}

export type GenderFilterInput = {
  _eq?: InputMaybe<Gender>;
  _in?: InputMaybe<Array<Gender>>;
  _ne?: InputMaybe<Gender>;
  _nin?: InputMaybe<Array<Gender>>;
};

export type GivenPromotionalMaterial = {
  __typename?: 'GivenPromotionalMaterial';
  /** Reason why requested and actual quantity are different */
  changeReason?: Maybe<GivenPromotionalMaterialChangeReason>;
  /** Comments related to the given promotional material */
  comments: CommentConnection;
  /** Uuid. It uniquely identifies the entity across the activity promotional materials. */
  oid: Scalars['GivenPromotionalMaterialId']['output'];
  /** The Promotional material which was given in the activity */
  promotionalMaterial: PromotionalMaterial;
  /** The actual quantity that was given */
  quantity?: Maybe<Scalars['Int']['output']>;
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The quantity which was requested to be given */
  requestedQuantity: Scalars['Int']['output'];
  /** The stock from which the promotional material was given. It's optional */
  stock?: Maybe<Stock>;
};

export type GivenPromotionalMaterialCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommentFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentOrderByInput>>;
};

export enum GivenPromotionalMaterialChangeReason {
  CANCELLATION = 'CANCELLATION',
  OUT_OF_STOCKS = 'OUT_OF_STOCKS',
  RETURNED_BY_RECEIVER = 'RETURNED_BY_RECEIVER',
  RULE_VALIDATION = 'RULE_VALIDATION',
  UNDELIVERABLE = 'UNDELIVERABLE',
  UNKNOWN = 'UNKNOWN',
}

export type GivenPromotionalMaterialChangeReasonFilterInput = {
  _eq?: InputMaybe<GivenPromotionalMaterialChangeReason>;
  _in?: InputMaybe<Array<GivenPromotionalMaterialChangeReason>>;
  _ne?: InputMaybe<GivenPromotionalMaterialChangeReason>;
  _nin?: InputMaybe<Array<GivenPromotionalMaterialChangeReason>>;
};

export type GivenPromotionalMaterialConnection = {
  __typename?: 'GivenPromotionalMaterialConnection';
  /** List of edges. Default sorting is by name ASC */
  edges?: Maybe<Array<GivenPromotionalMaterialEdge>>;
  /** Count of the entities with the current filter applied */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page */
  pageInfo: PageInfo;
  /** Count of all the entities */
  totalCount: Scalars['Int']['output'];
};

export type GivenPromotionalMaterialEdge = {
  __typename?: 'GivenPromotionalMaterialEdge';
  /** A cursor to this edge. This is the entity id */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The GivenPromotionalMaterial entity */
  node?: Maybe<GivenPromotionalMaterial>;
};

export type GivenPromotionalMaterialFilterInput = {
  _and?: InputMaybe<Array<GivenPromotionalMaterialFilterInput>>;
  _or?: InputMaybe<Array<GivenPromotionalMaterialFilterInput>>;
  changeReason?: InputMaybe<GivenPromotionalMaterialChangeReasonFilterInput>;
  comments?: InputMaybe<CommentFilterInput>;
  oid?: InputMaybe<GivenPromotionalMaterialIdFilterInput>;
  promotionalMaterial?: InputMaybe<PromotionalMaterialFilterInput>;
  quantity?: InputMaybe<IntegerFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  requestedQuantity?: InputMaybe<IntegerFilterInput>;
  stock?: InputMaybe<StockFilterInput>;
};

export type GivenPromotionalMaterialIdFilterInput = {
  _and?: InputMaybe<Array<GivenPromotionalMaterialIdFilterInput>>;
  _eq?: InputMaybe<Scalars['GivenPromotionalMaterialId']['input']>;
  _in?: InputMaybe<Array<Scalars['GivenPromotionalMaterialId']['input']>>;
  _ne?: InputMaybe<Scalars['GivenPromotionalMaterialId']['input']>;
  _nin?: InputMaybe<Array<Scalars['GivenPromotionalMaterialId']['input']>>;
  _or?: InputMaybe<Array<GivenPromotionalMaterialIdFilterInput>>;
};

export type GivenPromotionalMaterialOrderByInput = {
  changeReason?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  requestedQuantity?: InputMaybe<OrderBy>;
};

export type IntegerFilterInput = {
  /** Equals the value which is passed */
  _eq?: InputMaybe<Scalars['Int']['input']>;
  /** Greater than or equals the value which is passed */
  _ge?: InputMaybe<Scalars['Int']['input']>;
  /** Greater than the value which is passed */
  _gt?: InputMaybe<Scalars['Int']['input']>;
  /** Less than or equals the value which is passed */
  _le?: InputMaybe<Scalars['Int']['input']>;
  /** Less than the value which is passed */
  _lt?: InputMaybe<Scalars['Int']['input']>;
  /** Not equals the value which is passed */
  _ne?: InputMaybe<Scalars['Int']['input']>;
};

export type Inventory = Node & {
  __typename?: 'Inventory';
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Uuid. It uniquely identifies the entity across the inventories. */
  oid: Scalars['InventoryId']['output'];
  /** Person to whom the inventory belongs */
  person: Person;
  /** Start date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated. */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Stocks: List of available stocks for the inventory */
  stocks: StockConnection;
};

export type InventoryStocksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<StockFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StockOrderByInput>>;
};

export type InventoryConnection = {
  __typename?: 'InventoryConnection';
  /** List of edges. */
  edges?: Maybe<Array<InventoryEdge>>;
  /** Count of the entities with the current filter applied. */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page. */
  pageInfo: PageInfo;
  /** Count of all the entities. */
  totalCount: Scalars['Int']['output'];
};

export type InventoryEdge = {
  __typename?: 'InventoryEdge';
  /** A cursor to this edge. This is the entity id. */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The inventory entity. */
  node?: Maybe<Inventory>;
};

export type InventoryFilterInput = {
  _and?: InputMaybe<Array<InventoryFilterInput>>;
  _or?: InputMaybe<Array<InventoryFilterInput>>;
  oid?: InputMaybe<InventoryIdFilterInput>;
  person?: InputMaybe<PersonFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  stocks?: InputMaybe<StockFilterInput>;
};

export type InventoryIdFilterInput = {
  _and?: InputMaybe<Array<InventoryIdFilterInput>>;
  _eq?: InputMaybe<Scalars['InventoryId']['input']>;
  _in?: InputMaybe<Array<Scalars['InventoryId']['input']>>;
  _ne?: InputMaybe<Scalars['InventoryId']['input']>;
  _nin?: InputMaybe<Array<Scalars['InventoryId']['input']>>;
  _or?: InputMaybe<Array<InventoryIdFilterInput>>;
};

export type InventoryOrderByInput = {
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
};

export type InvitationActivityInput = {
  /** The country of the invitation for validation of fax */
  country?: InputMaybe<Scalars['CountryId']['input']>;
  /** The object identifier of the activity. */
  oid: Scalars['ActivityId']['input'];
  /** The receivers of the invitation */
  receivers: Array<InvitationReceiverInput>;
  /** The type of the invitation, could be email/fax */
  type: CommunicationDataBaseType;
};

export type InvitationReceiverInput = {
  /** The object identifier of the activity attendee id. */
  attendeeId: Scalars['ActivityAttendeeId']['input'];
  /** The value to which invitation will be sent. */
  value: Scalars['String']['input'];
};

export type KeyMessage = Node & {
  __typename?: 'KeyMessage';
  /** Customer reference. Reference, used by the customers to refer to the entity. */
  customerReference?: Maybe<Scalars['String']['output']>;
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Description. String representation of detailed description of the entity. */
  description?: Maybe<Scalars['String']['output']>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Name. String representation of the entity. */
  name?: Maybe<Scalars['String']['output']>;
  /** Uuid. It uniquely identifies the entity across the key messages. */
  oid: Scalars['KeyMessageId']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED */
  state: State;
  /** List of topics for which the key messages are valid */
  topics: TopicConnection;
};

export type KeyMessageTopicsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TopicFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TopicOrderByInput>>;
};

export type KeyMessageConnection = {
  __typename?: 'KeyMessageConnection';
  /** List of edges. Default sorting is by name ASC */
  edges?: Maybe<Array<KeyMessageEdge>>;
  /** Count of the entities with the current filter applied */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page */
  pageInfo: PageInfo;
  /** Count of all the entities */
  totalCount: Scalars['Int']['output'];
};

export type KeyMessageEdge = {
  __typename?: 'KeyMessageEdge';
  /** A cursor to this edge. This is the entity id */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The academic title entity */
  node?: Maybe<KeyMessage>;
};

export type KeyMessageFilterInput = {
  _and?: InputMaybe<Array<KeyMessageFilterInput>>;
  _or?: InputMaybe<Array<KeyMessageFilterInput>>;
  customerReference?: InputMaybe<StringFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  oid?: InputMaybe<KeyMessageIdFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  state?: InputMaybe<StateFilterInput>;
  topics?: InputMaybe<TopicFilterInput>;
};

export type KeyMessageIdFilterInput = {
  _and?: InputMaybe<Array<KeyMessageIdFilterInput>>;
  _eq?: InputMaybe<Scalars['KeyMessageId']['input']>;
  _in?: InputMaybe<Array<Scalars['KeyMessageId']['input']>>;
  _ne?: InputMaybe<Scalars['KeyMessageId']['input']>;
  _nin?: InputMaybe<Array<Scalars['KeyMessageId']['input']>>;
  _or?: InputMaybe<Array<KeyMessageIdFilterInput>>;
};

export type KeyMessageOrderByInput = {
  customerReference?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
};

export enum LifecycleStatus {
  ACTIVE = 'ACTIVE',
  FINISHED = 'FINISHED',
  INACTIVE = 'INACTIVE',
  IN_PREPARATION = 'IN_PREPARATION',
  PREPARED = 'PREPARED',
}

export type LifecycleStatusFilterInput = {
  _eq?: InputMaybe<LifecycleStatus>;
  _in?: InputMaybe<Array<LifecycleStatus>>;
  _ne?: InputMaybe<LifecycleStatus>;
  _nin?: InputMaybe<Array<LifecycleStatus>>;
};

export type Media = Node & {
  __typename?: 'Media';
  /** Customer reference. Reference, used by the customers to refer to the entity. */
  customerReference?: Maybe<Scalars['String']['output']>;
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Description. String description of the entity. */
  description?: Maybe<Scalars['String']['output']>;
  /** Flag represent this media can be downloadable. */
  downloadable?: Maybe<Scalars['Boolean']['output']>;
  /** End date for the Media.. */
  endDate?: Maybe<Scalars['Date']['output']>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** List of key messages which can be used in the media */
  keyMessages: KeyMessageConnection;
  /** Latest version of the media */
  latestVersion: MediaVersion;
  /** Current lifecycle status, e.g. IN_PREPARATION, PREPARED, ACTIVE, FINISHED, INACTIVE. */
  lifecycleStatus?: Maybe<LifecycleStatus>;
  /** Media type. e.g. IMAGE, HTML, PDF. */
  mediaType?: Maybe<SupportedMediaType>;
  /** Name. String representation of the entity. */
  name: Scalars['String']['output'];
  /** Uuid. It uniquely identifies the entity across the medias. */
  oid: Scalars['MediaId']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated. */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Start date for the Media. */
  startDate?: Maybe<Scalars['Date']['output']>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED. */
  state: State;
  /** Storage type. e.g. INTERNAL, EXTERNAL. */
  storageType?: Maybe<StorageType>;
  /** This contain the document entity if the document is attached to the media. */
  thumbnailDocument?: Maybe<Document>;
  /** List of topics which can be used in the media */
  topics: TopicConnection;
  /** All versions of the media, ordered in desc order */
  versions: MediaVersionConnection;
};

export type MediaKeyMessagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<KeyMessageFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<KeyMessageOrderByInput>>;
};

export type MediaTopicsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TopicFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TopicOrderByInput>>;
};

export type MediaVersionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MediaVersionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MediaVersionOrderByInput>>;
};

export type MediaConnection = {
  __typename?: 'MediaConnection';
  /** List of edges. */
  edges?: Maybe<Array<MediaEdge>>;
  /** Count of the entities with the current filter applied. */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page. */
  pageInfo: PageInfo;
  /** Count of all the entities. */
  totalCount: Scalars['Int']['output'];
};

export type MediaEdge = {
  __typename?: 'MediaEdge';
  /** A cursor to this edge. This is the entity id. */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The media entity. */
  node?: Maybe<Media>;
};

export type MediaFilterInput = {
  _and?: InputMaybe<Array<MediaFilterInput>>;
  _or?: InputMaybe<Array<MediaFilterInput>>;
  customerReference?: InputMaybe<StringFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  downloadable?: InputMaybe<BooleanFilterInput>;
  endDate?: InputMaybe<DateFilterInput>;
  keyMessages?: InputMaybe<KeyMessageFilterInput>;
  lifecycleStatus?: InputMaybe<LifecycleStatusFilterInput>;
  mediaType?: InputMaybe<SupportedMediaTypeFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  oid?: InputMaybe<MediaIdFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  startDate?: InputMaybe<DateFilterInput>;
  state?: InputMaybe<StateFilterInput>;
  storageType?: InputMaybe<StorageTypeFilterInput>;
  thumbnailDocument?: InputMaybe<DocumentFilterInput>;
  topics?: InputMaybe<TopicFilterInput>;
  versions?: InputMaybe<MediaVersionFilterInput>;
};

export type MediaIdFilterInput = {
  _and?: InputMaybe<Array<MediaIdFilterInput>>;
  _eq?: InputMaybe<Scalars['MediaId']['input']>;
  _in?: InputMaybe<Array<Scalars['MediaId']['input']>>;
  _ne?: InputMaybe<Scalars['MediaId']['input']>;
  _nin?: InputMaybe<Array<Scalars['MediaId']['input']>>;
  _or?: InputMaybe<Array<MediaIdFilterInput>>;
};

export type MediaOrderByInput = {
  customerReference?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  downloadable?: InputMaybe<OrderBy>;
  endDate?: InputMaybe<OrderBy>;
  lifecycleStatus?: InputMaybe<OrderBy>;
  mediaType?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  storageType?: InputMaybe<OrderBy>;
};

export type MediaTrackingInfo = Node & {
  __typename?: 'MediaTrackingInfo';
  /** The person to whom the media was presented. */
  audiencePerson?: Maybe<Person>;
  /** List of the tracking data for the presentation. */
  data: Array<MediaTrackingInfoData>;
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** The presented media. */
  media: Media;
  /** The exact presented media version. */
  mediaVersion: MediaVersion;
  /** Uuid. It uniquely identifies the entity across the medias tracking infos. */
  oid: Scalars['MediaTrackingInfoId']['output'];
  /** The person who presented the media. */
  presenterPerson: Person;
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated. */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date and time when the media was presented. */
  shownTime: Scalars['DateTime']['output'];
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED. */
  state: State;
};

export type MediaTrackingInfoConnection = {
  __typename?: 'MediaTrackingInfoConnection';
  /** List of edges. */
  edges?: Maybe<Array<MediaTrackingInfoEdge>>;
  /** Count of the entities with the current filter applied. */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page. */
  pageInfo: PageInfo;
  /** Count of all the entities. */
  totalCount: Scalars['Int']['output'];
};

export type MediaTrackingInfoData = {
  __typename?: 'MediaTrackingInfoData';
  /** The duration of the presentation of the given page in ms */
  duration: Scalars['Int']['output'];
  /** The page number which was presented */
  page: Scalars['Int']['output'];
  /** The sequence of the tracking info. It shows the sequence in which the given page was presented */
  sequence: Scalars['Int']['output'];
};

export type MediaTrackingInfoDataInput = {
  /** The duration of the presentation of the given page in ms */
  duration: Scalars['Int']['input'];
  /** The page number which was presented */
  page: Scalars['Int']['input'];
  /** The sequence of the tracking info. It shows the sequence in which the given page was presented */
  sequence: Scalars['Int']['input'];
};

export type MediaTrackingInfoEdge = {
  __typename?: 'MediaTrackingInfoEdge';
  /** A cursor to this edge. This is the entity id. */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The media tracking info entity. */
  node?: Maybe<MediaTrackingInfo>;
};

export type MediaTrackingInfoFilterInput = {
  _and?: InputMaybe<Array<MediaTrackingInfoFilterInput>>;
  _or?: InputMaybe<Array<MediaTrackingInfoFilterInput>>;
  audiencePerson?: InputMaybe<PersonFilterInput>;
  media?: InputMaybe<MediaFilterInput>;
  mediaVersion?: InputMaybe<MediaVersionFilterInput>;
  oid?: InputMaybe<MediaTrackingInfoIdFilterInput>;
  presenterPerson?: InputMaybe<PersonFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  shownTime?: InputMaybe<DateTimeFilterInput>;
  state?: InputMaybe<StateFilterInput>;
};

export type MediaTrackingInfoIdFilterInput = {
  _and?: InputMaybe<Array<MediaTrackingInfoIdFilterInput>>;
  _eq?: InputMaybe<Scalars['MediaTrackingInfoId']['input']>;
  _in?: InputMaybe<Array<Scalars['MediaTrackingInfoId']['input']>>;
  _ne?: InputMaybe<Scalars['MediaTrackingInfoId']['input']>;
  _nin?: InputMaybe<Array<Scalars['MediaTrackingInfoId']['input']>>;
  _or?: InputMaybe<Array<MediaTrackingInfoIdFilterInput>>;
};

export type MediaTrackingInfoOrderByInput = {
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  shownTime?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
};

export type MediaVersion = {
  __typename?: 'MediaVersion';
  /** Creator of the media */
  creator?: Maybe<User>;
  /** The media document associated with this version. This is the actual media document */
  document?: Maybe<Document>;
  /** Uuid. It uniquely identifies the entity across the media versions. */
  oid: Scalars['MediaVersionId']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated. */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Version number */
  version?: Maybe<Scalars['Int']['output']>;
};

export type MediaVersionConnection = {
  __typename?: 'MediaVersionConnection';
  /** List of edges. */
  edges?: Maybe<Array<MediaVersionEdge>>;
  /** Count of the entities with the current filter applied. */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page. */
  pageInfo: PageInfo;
  /** Count of all the entities. */
  totalCount: Scalars['Int']['output'];
};

export type MediaVersionEdge = {
  __typename?: 'MediaVersionEdge';
  /** A cursor to this edge. This is the entity id. */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The media version entity. */
  node?: Maybe<MediaVersion>;
};

export type MediaVersionFilterInput = {
  _and?: InputMaybe<Array<MediaVersionFilterInput>>;
  _or?: InputMaybe<Array<MediaVersionFilterInput>>;
  creator?: InputMaybe<UserFilterInput>;
  document?: InputMaybe<DocumentFilterInput>;
  oid?: InputMaybe<MediaVersionIdFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  version?: InputMaybe<IntegerFilterInput>;
};

export type MediaVersionIdFilterInput = {
  _and?: InputMaybe<Array<MediaVersionIdFilterInput>>;
  _eq?: InputMaybe<Scalars['MediaVersionId']['input']>;
  _in?: InputMaybe<Array<Scalars['MediaVersionId']['input']>>;
  _ne?: InputMaybe<Scalars['MediaVersionId']['input']>;
  _nin?: InputMaybe<Array<Scalars['MediaVersionId']['input']>>;
  _or?: InputMaybe<Array<MediaVersionIdFilterInput>>;
};

export type MediaVersionOrderByInput = {
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

export type MoneyInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Adds an academic title */
  addAcademicTitle?: Maybe<AcademicTitle>;
  /** Create an activity */
  addActivity?: Maybe<Activity>;
  /** Adds a feedback */
  addFeedback?: Maybe<Feedback>;
  /** Create a media tracking info */
  addMediaTrackingInfo?: Maybe<MediaTrackingInfo>;
  /** Adds a new recent account history entry */
  addRecentAccount?: Maybe<Scalars['Boolean']['output']>;
  /** Adds an sample request form */
  addSampleRequestForm?: Maybe<SampleRequestForm>;
  /** Collects a digital person consent */
  collectDigitalPersonConsent?: Maybe<PersonConsent>;
  /** Collects a manual person consent */
  collectManualPersonConsent?: Maybe<PersonConsent>;
  /** Collects a paper person consent */
  collectPaperPersonConsent?: Maybe<PersonConsent>;
  /** Generates new code for the remote interaction of the given activity */
  generateRemoteInteractionCode?: Maybe<Scalars['String']['output']>;
  /** Rejects a person consent */
  rejectPersonConsent?: Maybe<PersonConsent>;
  /** Resends the double opt in */
  resendDoubleOptIn?: Maybe<PersonConsent>;
  /** Resets a person consent */
  resetPersonConsent?: Maybe<PersonConsent>;
  /** Revokes a person consent */
  revokePersonConsent?: Maybe<PersonConsent>;
  /** Sends email invitation for the activity */
  sendInvitation?: Maybe<Activity>;
  /** Updates an existing academic title */
  updateAcademicTitle?: Maybe<AcademicTitle>;
  /** Update an existing activity */
  updateActivity?: Maybe<Activity>;
  /** Updates person consent items */
  updatePersonConsentItems?: Maybe<PersonConsent>;
  /** Update an existing preference */
  updatePreference?: Maybe<Preference>;
};

export type MutationAddAcademicTitleArgs = {
  input: AddAcademicTitleInput;
};

export type MutationAddActivityArgs = {
  input: AddActivityInput;
};

export type MutationAddFeedbackArgs = {
  input: AddFeedbackInput;
};

export type MutationAddMediaTrackingInfoArgs = {
  input: AddMediaTrackingInfoInput;
};

export type MutationAddRecentAccountArgs = {
  input: RecentAccountInput;
};

export type MutationAddSampleRequestFormArgs = {
  input: AddSampleRequestFormInput;
};

export type MutationCollectDigitalPersonConsentArgs = {
  input?: InputMaybe<CollectDigitalPersonConsentInput>;
};

export type MutationCollectManualPersonConsentArgs = {
  input?: InputMaybe<CollectManualPersonConsentInput>;
};

export type MutationCollectPaperPersonConsentArgs = {
  input?: InputMaybe<CollectPaperPersonConsentInput>;
};

export type MutationGenerateRemoteInteractionCodeArgs = {
  input: RemoteInteractionCodeInput;
};

export type MutationRejectPersonConsentArgs = {
  input?: InputMaybe<RejectPersonConsentInput>;
};

export type MutationResendDoubleOptInArgs = {
  input?: InputMaybe<ResendDoubleOptInInput>;
};

export type MutationResetPersonConsentArgs = {
  input?: InputMaybe<ResetPersonConsentInput>;
};

export type MutationRevokePersonConsentArgs = {
  input?: InputMaybe<RevokePersonConsentInput>;
};

export type MutationSendInvitationArgs = {
  input: InvitationActivityInput;
};

export type MutationUpdateAcademicTitleArgs = {
  input: UpdateAcademicTitleInput;
};

export type MutationUpdateActivityArgs = {
  input: UpdateActivityInput;
};

export type MutationUpdatePersonConsentItemsArgs = {
  input?: InputMaybe<UpdatePersonConsentItemsInput>;
};

export type MutationUpdatePreferenceArgs = {
  input: UpdatePreferenceInput;
};

/** An object with a Globally Unique ID */
export type Node = {
  dataPermissions: Array<DataPermission>;
  id: Scalars['ID']['output'];
};

export enum OrderBy {
  ASC = 'ASC',
  ASC_NULL_FIRST = 'ASC_NULL_FIRST',
  ASC_NULL_LAST = 'ASC_NULL_LAST',
  DESC = 'DESC',
  DESC_NULL_FIRST = 'DESC_NULL_FIRST',
  DESC_NULL_LAST = 'DESC_NULL_LAST',
}

export type Organization = Node & {
  __typename?: 'Organization';
  /** Activities where the organization is involved. They are provided in a desc order by date */
  activities: ActivityConnection;
  /** Addresses of the organization. Entities are ordered by address type */
  addresses: EntityAddressConnection;
  /** Free comment field, Any text can be placed here */
  comment?: Maybe<Scalars['String']['output']>;
  /** List of organization communication data. Entities are ordered by communication data type */
  communicationData: CommunicationDataConnection;
  /** Customer reference. Reference, used by the customers to refer to the entity. */
  customerReference?: Maybe<Scalars['String']['output']>;
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Employments of the organization, containing all the relevant employees */
  employments: EmploymentConnection;
  /** all organization entities which are part of the  hierarchical structure of the current organization */
  hierarchyOrganizations: OrganizationConnection;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Shows if the organization is currently under a clearance process */
  inClearance: Scalars['Boolean']['output'];
  /** Name. String representation of the name of the entity. */
  name?: Maybe<Scalars['String']['output']>;
  /** Uuid. It uniquely identifies the entity across the organizations. */
  oid: Scalars['OrganizationId']['output'];
  /** Organization State. The current state, e.g. ACTIVE, UNKNOWN, CLOSED, DELETED */
  organizationState?: Maybe<OrganizationState>;
  /** Types of the organization. Entities are ordered by organization type name */
  organizationTypes: OrganizationTypeConnection;
  /** parent organization entity when hierarchical structure is available */
  parentOrganization?: Maybe<Organization>;
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** responsible body represents type of the organization. Possible values are PUB, PRI, UNI, UNKNOWN */
  responsibleBody?: Maybe<ResponsibleBody>;
  /** root organization entity */
  rootOrganization?: Maybe<Organization>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED */
  state?: Maybe<State>;
};

export type OrganizationActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ActivityFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActivityOrderByInput>>;
};

export type OrganizationAddressesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EntityAddressFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntityAddressOrderByInput>>;
};

export type OrganizationCommunicationDataArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommunicationDataFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommunicationDataOrderByInput>>;
};

export type OrganizationEmploymentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EmploymentFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmploymentOrderByInput>>;
};

export type OrganizationHierarchyOrganizationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<OrganizationFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<OrganizationOrderByInput>>;
};

export type OrganizationOrganizationTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<OrganizationTypeFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<OrganizationTypeOrderByInput>>;
};

export enum OrganizationBaseType {
  AGENCY = 'AGENCY',
  CUSTOMER = 'CUSTOMER',
  EVENT = 'EVENT',
  INTERNAL = 'INTERNAL',
}

export type OrganizationBaseTypeFilterInput = {
  _eq?: InputMaybe<OrganizationBaseType>;
  _in?: InputMaybe<Array<OrganizationBaseType>>;
  _ne?: InputMaybe<OrganizationBaseType>;
  _nin?: InputMaybe<Array<OrganizationBaseType>>;
};

export type OrganizationConnection = {
  __typename?: 'OrganizationConnection';
  /** List of edges. Default sorting is by name ASC */
  edges?: Maybe<Array<OrganizationEdge>>;
  /** Count of the entities with the current filter applied */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page */
  pageInfo: PageInfo;
  /** Count of all the entities */
  totalCount: Scalars['Int']['output'];
};

export type OrganizationEdge = {
  __typename?: 'OrganizationEdge';
  /** A cursor to this edge. This is the entity id */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The organization type entity */
  node?: Maybe<Organization>;
};

export type OrganizationFacetsPreference = {
  __typename?: 'OrganizationFacetsPreference';
  /** Preference for attributes facet. */
  attributes: Array<Attribute>;
  /** Preference for cities facet. */
  cities: Array<Scalars['String']['output']>;
  /** Preference for organization types facet. */
  organizationTypes: Array<OrganizationType>;
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type OrganizationFacetsPreferenceInput = {
  /** Preference for attributes facet. */
  attributes: Array<Scalars['AttributeId']['input']>;
  /** Preference for cities facet. */
  cities: Array<Scalars['String']['input']>;
  /** Preference for organization types facet. */
  organizationTypes: Array<Scalars['OrganizationTypeId']['input']>;
};

export type OrganizationFilterInput = {
  _and?: InputMaybe<Array<OrganizationFilterInput>>;
  _or?: InputMaybe<Array<OrganizationFilterInput>>;
  activities?: InputMaybe<ActivityFilterInput>;
  addresses?: InputMaybe<EntityAddressFilterInput>;
  attributes?: InputMaybe<AttributeValueFilterInput>;
  comment?: InputMaybe<StringFilterInput>;
  communicationData?: InputMaybe<CommunicationDataFilterInput>;
  customerReference?: InputMaybe<StringFilterInput>;
  employments?: InputMaybe<EmploymentFilterInput>;
  inClearance?: InputMaybe<BooleanFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  oid?: InputMaybe<OrganizationIdFilterInput>;
  organizationState?: InputMaybe<OrganizationStateFilterInput>;
  organizationTypes?: InputMaybe<OrganizationTypeFilterInput>;
  parentOrganization?: InputMaybe<OrganizationFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  responsibleBody?: InputMaybe<ResponsibleBodyFilterInput>;
  rootOrganization?: InputMaybe<OrganizationFilterInput>;
  scope?: InputMaybe<ScopeFilterInput>;
  state?: InputMaybe<StateFilterInput>;
};

export type OrganizationIdFilterInput = {
  _and?: InputMaybe<Array<OrganizationIdFilterInput>>;
  _eq?: InputMaybe<Scalars['OrganizationId']['input']>;
  _in?: InputMaybe<Array<Scalars['OrganizationId']['input']>>;
  _ne?: InputMaybe<Scalars['OrganizationId']['input']>;
  _nin?: InputMaybe<Array<Scalars['OrganizationId']['input']>>;
  _or?: InputMaybe<Array<OrganizationIdFilterInput>>;
};

export type OrganizationOrderByInput = {
  comment?: InputMaybe<OrderBy>;
  customerReference?: InputMaybe<OrderBy>;
  inClearance?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  organizationState?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  responsibleBody?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
};

export enum OrganizationState {
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
  DELETED = 'DELETED',
  LIMBO = 'LIMBO',
  UNKNOWN = 'UNKNOWN',
}

export type OrganizationStateFilterInput = {
  _eq?: InputMaybe<OrganizationState>;
  _in?: InputMaybe<Array<OrganizationState>>;
  _ne?: InputMaybe<OrganizationState>;
  _nin?: InputMaybe<Array<OrganizationState>>;
};

export type OrganizationType = Node & {
  __typename?: 'OrganizationType';
  /** Base type. It can be internal, customer, event or agency. */
  baseType?: Maybe<OrganizationBaseType>;
  /** Customer reference. reference, used by the customers to refer to the entity. */
  customerReference?: Maybe<Scalars['String']['output']>;
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Name. String representation of the entity. */
  name?: Maybe<Scalars['String']['output']>;
  /** Uuid. It uniquely identifies the entity across the organization type. */
  oid: Scalars['OrganizationTypeId']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Sort code. Used to prioritise organization types. */
  sortCode?: Maybe<Scalars['Int']['output']>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED */
  state: State;
};

export type OrganizationTypeConnection = {
  __typename?: 'OrganizationTypeConnection';
  /** List of edges. Default sorting is by sortCode ASC, name ASC */
  edges?: Maybe<Array<OrganizationTypeEdge>>;
  /** Count of the entities with the current filter applied */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page */
  pageInfo: PageInfo;
  /** Count of all the entities */
  totalCount: Scalars['Int']['output'];
};

export type OrganizationTypeEdge = {
  __typename?: 'OrganizationTypeEdge';
  /** A cursor to this edge. This is the entity id */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The organization type entity */
  node?: Maybe<OrganizationType>;
};

export type OrganizationTypeFilterInput = {
  _and?: InputMaybe<Array<OrganizationTypeFilterInput>>;
  _or?: InputMaybe<Array<OrganizationTypeFilterInput>>;
  baseType?: InputMaybe<OrganizationBaseTypeFilterInput>;
  customerReference?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  oid?: InputMaybe<OrganizationTypeIdFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  sortCode?: InputMaybe<IntegerFilterInput>;
  state?: InputMaybe<StateFilterInput>;
};

export type OrganizationTypeIdFilterInput = {
  _and?: InputMaybe<Array<OrganizationTypeIdFilterInput>>;
  _eq?: InputMaybe<Scalars['OrganizationTypeId']['input']>;
  _in?: InputMaybe<Array<Scalars['OrganizationTypeId']['input']>>;
  _ne?: InputMaybe<Scalars['OrganizationTypeId']['input']>;
  _nin?: InputMaybe<Array<Scalars['OrganizationTypeId']['input']>>;
  _or?: InputMaybe<Array<OrganizationTypeIdFilterInput>>;
};

export type OrganizationTypeOrderByInput = {
  baseType?: InputMaybe<OrderBy>;
  customerReference?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  sortCode?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
};

export type OrganizationalUnit = Node & {
  __typename?: 'OrganizationalUnit';
  /** Customer reference. reference, used by the customers to refer to the entity. */
  customerReference?: Maybe<Scalars['String']['output']>;
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Name. String representation of the entity. */
  name?: Maybe<Scalars['String']['output']>;
  /** Uuid. It uniquely identifies the entity across the organization units. */
  oid: Scalars['OrganizationalUnitId']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED */
  state: State;
  /** The type of the organizational unit */
  type: OrganizationalUnitType;
};

export type OrganizationalUnitConnection = {
  __typename?: 'OrganizationalUnitConnection';
  /** List of edges. Default sorting is by name ASC */
  edges?: Maybe<Array<OrganizationalUnitEdge>>;
  /** Count of the entities with the current filter applied */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page */
  pageInfo: PageInfo;
  /** Count of all the entities */
  totalCount: Scalars['Int']['output'];
};

export type OrganizationalUnitEdge = {
  __typename?: 'OrganizationalUnitEdge';
  /** A cursor to this edge. This is the entity id */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The organization unit type entity */
  node?: Maybe<OrganizationalUnit>;
};

export type OrganizationalUnitFilterInput = {
  _and?: InputMaybe<Array<OrganizationalUnitFilterInput>>;
  _or?: InputMaybe<Array<OrganizationalUnitFilterInput>>;
  customerReference?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  oid?: InputMaybe<OrganizationalUnitIdFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  state?: InputMaybe<StateFilterInput>;
  type?: InputMaybe<OrganizationalUnitTypeFilterInput>;
};

export type OrganizationalUnitIdFilterInput = {
  _and?: InputMaybe<Array<OrganizationalUnitIdFilterInput>>;
  _eq?: InputMaybe<Scalars['OrganizationalUnitId']['input']>;
  _in?: InputMaybe<Array<Scalars['OrganizationalUnitId']['input']>>;
  _ne?: InputMaybe<Scalars['OrganizationalUnitId']['input']>;
  _nin?: InputMaybe<Array<Scalars['OrganizationalUnitId']['input']>>;
  _or?: InputMaybe<Array<OrganizationalUnitIdFilterInput>>;
};

export type OrganizationalUnitOrderByInput = {
  customerReference?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
};

export type OrganizationalUnitType = Node & {
  __typename?: 'OrganizationalUnitType';
  /** Customer reference. reference, used by the customers to refer to the entity. */
  customerReference?: Maybe<Scalars['String']['output']>;
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Name. String representation of the entity. */
  name?: Maybe<Scalars['String']['output']>;
  /** Uuid. It uniquely identifies the entity across the organization unit type. */
  oid: Scalars['OrganizationalUnitTypeId']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED */
  state: State;
};

export type OrganizationalUnitTypeConnection = {
  __typename?: 'OrganizationalUnitTypeConnection';
  /** List of edges. Default sorting is by name ASC */
  edges?: Maybe<Array<OrganizationalUnitTypeEdge>>;
  /** Count of the entities with the current filter applied */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page */
  pageInfo: PageInfo;
  /** Count of all the entities */
  totalCount: Scalars['Int']['output'];
};

export type OrganizationalUnitTypeEdge = {
  __typename?: 'OrganizationalUnitTypeEdge';
  /** A cursor to this edge. This is the entity id */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The organization unit type entity */
  node?: Maybe<OrganizationalUnitType>;
};

export type OrganizationalUnitTypeFilterInput = {
  _and?: InputMaybe<Array<OrganizationalUnitTypeFilterInput>>;
  _or?: InputMaybe<Array<OrganizationalUnitTypeFilterInput>>;
  customerReference?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  oid?: InputMaybe<OrganizationalUnitTypeIdFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  state?: InputMaybe<StateFilterInput>;
};

export type OrganizationalUnitTypeIdFilterInput = {
  _and?: InputMaybe<Array<OrganizationalUnitTypeIdFilterInput>>;
  _eq?: InputMaybe<Scalars['OrganizationalUnitTypeId']['input']>;
  _in?: InputMaybe<Array<Scalars['OrganizationalUnitTypeId']['input']>>;
  _ne?: InputMaybe<Scalars['OrganizationalUnitTypeId']['input']>;
  _nin?: InputMaybe<Array<Scalars['OrganizationalUnitTypeId']['input']>>;
  _or?: InputMaybe<Array<OrganizationalUnitTypeIdFilterInput>>;
};

export type OrganizationalUnitTypeOrderByInput = {
  customerReference?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Person = Node & {
  __typename?: 'Person';
  /** Activities where the person is involved. They are provided in a desc order by date */
  activities: ActivityConnection;
  /** Addresses of the person. Entities are ordered by address type */
  addresses: EntityAddressConnection;
  /** Birthday of the person in ISO date format */
  birthday?: Maybe<Scalars['Date']['output']>;
  /** Free comment field, Any text can be placed here */
  comment?: Maybe<Scalars['String']['output']>;
  /** List of person communication data. Entities are ordered by communication data type */
  communicationData: CommunicationDataConnection;
  /**
   *  All the consents that belong to that person.
   *  If there is a consent type which had never had an action by this person (collected, rejected, etc.)
   *  then it returns the default state of that type
   */
  consents: PersonConsentConnection;
  /** Customer reference. Reference, used by the customers to refer to the entity. */
  customerReference?: Maybe<Scalars['String']['output']>;
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Employments of the person, containing all the relevant employment, ordered by sortCode asc */
  employments: EmploymentConnection;
  /** First Name. String representation of the first name of the entity. */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Gender of the person, Possible values are MALE, FEMALE, NOT_APPLICABLE, NOT_KNOWN */
  gender?: Maybe<Gender>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Shows if the person is currently under a clearance process */
  inClearance: Scalars['Boolean']['output'];
  /** Last Name. String representation of the last name of the entity. */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Middle Name. String representation of the middle name of the entity. */
  middleName?: Maybe<Scalars['String']['output']>;
  /** Uuid. It uniquely identifies the entity across the persons. */
  oid: Scalars['PersonId']['output'];
  /** Person State. The current state, e.g. ACTIVE, UNKNOWN, DECEASED, RETIRED, TEMPORARILY_NOT_AVAILABLE, GDPR_BLOCKED, DELETED */
  personState: PersonState;
  /** Types of the person. Entities are ordered by person type name */
  personTypes: PersonTypeConnection;
  /** Postfix academic title of the person. Contains AcademicTitle entity. it's placed after the name */
  postfixAcademicTitle?: Maybe<AcademicTitle>;
  /** Prefix academic title of the person. Contains AcademicTitle entity. it's placed before the name */
  prefixAcademicTitle?: Maybe<AcademicTitle>;
  /**
   *  Statistics about the promotional materials.
   *  It lists all promotional materials and information about given and available quantities.
   *  They are provided in a asc order by promotional material name
   */
  promotionalMaterials: PersonPromotionalMaterialConnection;
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Specialities of the person. Entities are ordered by speciality name */
  specialities: PersonSpecialityConnection;
  /** Speciality group of the person. */
  specialityGroup?: Maybe<SpecialityGroup>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED */
  state: State;
  /** Touch point statistics */
  touchPointStatistics: Array<Maybe<PersonTouchPointStatistics>>;
};

export type PersonActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ActivityFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActivityOrderByInput>>;
};

export type PersonAddressesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EntityAddressFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntityAddressOrderByInput>>;
};

export type PersonCommunicationDataArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommunicationDataFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommunicationDataOrderByInput>>;
};

export type PersonConsentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PersonConsentFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PersonConsentOrderByInput>>;
};

export type PersonEmploymentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EmploymentFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmploymentOrderByInput>>;
};

export type PersonPersonTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PersonTypeFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PersonTypeOrderByInput>>;
};

export type PersonPromotionalMaterialsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PersonPromotionalMaterialFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PersonPromotionalMaterialOrderByInput>>;
};

export type PersonSpecialitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PersonSpecialityFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PersonSpecialityOrderByInput>>;
};

export type PersonAssignment = Node & {
  __typename?: 'PersonAssignment';
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** End date of the person assignment */
  endDate?: Maybe<Scalars['Date']['output']>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Uuid. It uniquely identifies the entity across the person assignments. */
  oid: Scalars['PersonAssignmentId']['output'];
  /** Organizational unit to which the person is assigned. */
  organizationalUnit?: Maybe<OrganizationalUnit>;
  /** Person to which the assignment belongs */
  person?: Maybe<Person>;
  /** Shows if the assignment is primary. */
  primary?: Maybe<Scalars['Boolean']['output']>;
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Start date of the person assignment */
  startDate?: Maybe<Scalars['Date']['output']>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED */
  state: State;
  /** Type. The type of the person assignment */
  type?: Maybe<PersonAssignmentType>;
};

export enum PersonAssignmentBaseType {
  BACK_OFFICE = 'BACK_OFFICE',
  FIELD_SERVICE = 'FIELD_SERVICE',
}

export type PersonAssignmentBaseTypeFilterInput = {
  _eq?: InputMaybe<PersonAssignmentBaseType>;
  _in?: InputMaybe<Array<PersonAssignmentBaseType>>;
  _ne?: InputMaybe<PersonAssignmentBaseType>;
  _nin?: InputMaybe<Array<PersonAssignmentBaseType>>;
};

export type PersonAssignmentConnection = {
  __typename?: 'PersonAssignmentConnection';
  /** List of edges. Default sorting is by date ASC */
  edges?: Maybe<Array<PersonAssignmentEdge>>;
  /** Count of the entities with the current filter applied */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page */
  pageInfo: PageInfo;
  /** Count of all the entities */
  totalCount: Scalars['Int']['output'];
};

export type PersonAssignmentEdge = {
  __typename?: 'PersonAssignmentEdge';
  /** A cursor to this edge. This is the entity id */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The person assignment type entity */
  node?: Maybe<PersonAssignment>;
};

export type PersonAssignmentFilterInput = {
  _and?: InputMaybe<Array<PersonAssignmentFilterInput>>;
  _or?: InputMaybe<Array<PersonAssignmentFilterInput>>;
  endDate?: InputMaybe<DateFilterInput>;
  oid?: InputMaybe<PersonAssignmentIdFilterInput>;
  organizationalUnit?: InputMaybe<OrganizationalUnitFilterInput>;
  person?: InputMaybe<PersonFilterInput>;
  primary?: InputMaybe<BooleanFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  startDate?: InputMaybe<DateFilterInput>;
  state?: InputMaybe<StateFilterInput>;
  type?: InputMaybe<PersonAssignmentTypeFilterInput>;
};

export type PersonAssignmentIdFilterInput = {
  _and?: InputMaybe<Array<PersonAssignmentIdFilterInput>>;
  _eq?: InputMaybe<Scalars['PersonAssignmentId']['input']>;
  _in?: InputMaybe<Array<Scalars['PersonAssignmentId']['input']>>;
  _ne?: InputMaybe<Scalars['PersonAssignmentId']['input']>;
  _nin?: InputMaybe<Array<Scalars['PersonAssignmentId']['input']>>;
  _or?: InputMaybe<Array<PersonAssignmentIdFilterInput>>;
};

export type PersonAssignmentOrderByInput = {
  endDate?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  primary?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
};

export type PersonAssignmentType = Node & {
  __typename?: 'PersonAssignmentType';
  /** Base type. Base type of the assignment type, e.g. BACK_OFFICE, FIELD_SERVICE */
  baseType: PersonAssignmentBaseType;
  /** Customer reference. reference, used by the customers to refer to the entity. */
  customerReference?: Maybe<Scalars['String']['output']>;
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Name. String representation of the entity. */
  name?: Maybe<Scalars['String']['output']>;
  /** Uuid. It uniquely identifies the entity across the person assignment types. */
  oid: Scalars['PersonAssignmentTypeId']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated. */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED */
  state: State;
};

export type PersonAssignmentTypeConnection = {
  __typename?: 'PersonAssignmentTypeConnection';
  /** List of edges. Default sorting is by name ASC. */
  edges?: Maybe<Array<PersonAssignmentTypeEdge>>;
  /** Count of the entities with the current filter applied. */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page. */
  pageInfo: PageInfo;
  /** Count of all the entities. */
  totalCount: Scalars['Int']['output'];
};

export type PersonAssignmentTypeEdge = {
  __typename?: 'PersonAssignmentTypeEdge';
  /** A cursor to this edge. This is the entity id. */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The person assignment type entity. */
  node?: Maybe<PersonAssignmentType>;
};

export type PersonAssignmentTypeFilterInput = {
  _and?: InputMaybe<Array<PersonAssignmentTypeFilterInput>>;
  _or?: InputMaybe<Array<PersonAssignmentTypeFilterInput>>;
  baseType?: InputMaybe<PersonAssignmentBaseTypeFilterInput>;
  customerReference?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  oid?: InputMaybe<PersonAssignmentTypeIdFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  state?: InputMaybe<StateFilterInput>;
};

export type PersonAssignmentTypeIdFilterInput = {
  _and?: InputMaybe<Array<PersonAssignmentTypeIdFilterInput>>;
  _eq?: InputMaybe<Scalars['PersonAssignmentTypeId']['input']>;
  _in?: InputMaybe<Array<Scalars['PersonAssignmentTypeId']['input']>>;
  _ne?: InputMaybe<Scalars['PersonAssignmentTypeId']['input']>;
  _nin?: InputMaybe<Array<Scalars['PersonAssignmentTypeId']['input']>>;
  _or?: InputMaybe<Array<PersonAssignmentTypeIdFilterInput>>;
};

export type PersonAssignmentTypeOrderByInput = {
  baseType?: InputMaybe<OrderBy>;
  customerReference?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
};

export enum PersonBaseType {
  CUSTOMER = 'CUSTOMER',
  INTERNAL = 'INTERNAL',
}

export type PersonBaseTypeFilterInput = {
  _eq?: InputMaybe<PersonBaseType>;
  _in?: InputMaybe<Array<PersonBaseType>>;
  _ne?: InputMaybe<PersonBaseType>;
  _nin?: InputMaybe<Array<PersonBaseType>>;
};

export type PersonConnection = {
  __typename?: 'PersonConnection';
  /** List of edges. Default sorting is by lastName ASC, firstName ASC */
  edges?: Maybe<Array<PersonEdge>>;
  /** Count of the entities with the current filter applied */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page */
  pageInfo: PageInfo;
  /** Count of all the entities */
  totalCount: Scalars['Int']['output'];
};

export type PersonConsent = Node & {
  __typename?: 'PersonConsent';
  /** Date till when this consent is active. */
  actionAt?: Maybe<Scalars['DateTime']['output']>;
  /** Consent history. List of consent history related with the entity. */
  consentHistory: Array<PersonConsentHistory>;
  /** Consent items. List of consent items related with the entity. */
  consentItemValues: Array<PersonConsentItemValue>;
  /** Consent Type. */
  consentType: ConsentType;
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Consent document. */
  document?: Maybe<Document>;
  /** Person consent form identifier. */
  formIdentifier?: Maybe<Scalars['String']['output']>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Issuer person. Person who issued this consent. */
  issuer?: Maybe<Person>;
  /** Current lifecycle state, e.g. DEFAULT, PENDING_PAPER, PENDING_DOUBLE_OPT_IN, ACCEPTED, REJECTED, MERGED. */
  lifecycleState: PersonConsentLifecycleState;
  /** Uuid. It uniquely identifies the entity across the person consents. */
  oid?: Maybe<Scalars['PersonConsentId']['output']>;
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated. */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Signer person. Person who signs this consent. */
  signer?: Maybe<Person>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED */
  state?: Maybe<State>;
};

export type PersonConsentCommunicationDataValue = {
  __typename?: 'PersonConsentCommunicationDataValue';
  /** Consent communication data type from the ConsentType entity. */
  consentCommunicationDataType: ConsentCommunicationDataType;
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Value of the person consent communication data value */
  value?: Maybe<Scalars['String']['output']>;
};

export type PersonConsentCommunicationDataValueInput = {
  oid?: InputMaybe<Scalars['ConsentCommunicationDataTypeId']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type PersonConsentConnection = {
  __typename?: 'PersonConsentConnection';
  /** List of edges. Default sorting is by name ASC. */
  edges?: Maybe<Array<PersonConsentEdge>>;
  /** Count of the entities with the current filter applied. */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page. */
  pageInfo: PageInfo;
  /** Count of all the entities. */
  totalCount: Scalars['Int']['output'];
};

export type PersonConsentEdge = {
  __typename?: 'PersonConsentEdge';
  /** A cursor to this edge. This is the entity id. */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one. */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one. */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The person consent entity. */
  node?: Maybe<PersonConsent>;
};

export type PersonConsentFilterInput = {
  _and?: InputMaybe<Array<PersonConsentFilterInput>>;
  _or?: InputMaybe<Array<PersonConsentFilterInput>>;
  actionAt?: InputMaybe<DateTimeFilterInput>;
  consentItemValue?: InputMaybe<PersonConsentItemValueFilterInput>;
  consentType?: InputMaybe<ConsentTypeFilterInput>;
  document?: InputMaybe<DocumentFilterInput>;
  formIdentifier?: InputMaybe<StringFilterInput>;
  issuer?: InputMaybe<PersonFilterInput>;
  lifecycleState?: InputMaybe<PersonConsentLifecycleStateFilterInput>;
  oid?: InputMaybe<PersonConsentIdFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  signer?: InputMaybe<PersonFilterInput>;
  state?: InputMaybe<StateFilterInput>;
};

export type PersonConsentHistory = {
  __typename?: 'PersonConsentHistory';
  /** Consent action, e.g. COLLECT_DIGITAL_CONSENT, COLLECT_MANUAL_CONSENT, COLLECT_PAPER_CONSENT, REJECT_CONSENT, SET_CONSENT_ITEMS, TRANSFER_CONSENT. */
  action: ConsentAction;
  /** Date and Time when this consent history entry was created. */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Consent document for this history entry. */
  document?: Maybe<Document>;
  /** Date when consent was handled. */
  handledAt?: Maybe<Scalars['Date']['output']>;
  /** Issuer person. Person who issued this consent. */
  issuer: Person;
  /** Sequence number of history record. */
  sequenceNumber?: Maybe<Scalars['Int']['output']>;
};

export type PersonConsentIdFilterInput = {
  _and?: InputMaybe<Array<PersonConsentIdFilterInput>>;
  _eq?: InputMaybe<Scalars['PersonConsentId']['input']>;
  _in?: InputMaybe<Array<Scalars['PersonConsentId']['input']>>;
  _ne?: InputMaybe<Scalars['PersonConsentId']['input']>;
  _nin?: InputMaybe<Array<Scalars['PersonConsentId']['input']>>;
  _or?: InputMaybe<Array<PersonConsentIdFilterInput>>;
};

export type PersonConsentItemValue = {
  __typename?: 'PersonConsentItemValue';
  /** Values for communication data (email, tel, etc.) */
  communicationDataValues: Array<PersonConsentCommunicationDataValue>;
  /** The consent whose values should be set. */
  consentItem: ConsentItem;
  /** State change of consent item */
  consentItemState: ConsentItemStateChange;
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PersonConsentItemValueFilterInput = {
  _and?: InputMaybe<Array<PersonConsentItemValueFilterInput>>;
  _or?: InputMaybe<Array<PersonConsentItemValueFilterInput>>;
  consentItem?: InputMaybe<ConsentItemFilterInput>;
  consentItemState?: InputMaybe<ConsentItemStateChangeFilterInput>;
};

export type PersonConsentItemValueInput = {
  collectedCommunicationData: Array<PersonConsentCommunicationDataValueInput>;
  itemState?: InputMaybe<ConsentItemStateChange>;
  oid?: InputMaybe<Scalars['ConsentItemId']['input']>;
};

export enum PersonConsentLifecycleState {
  ACCEPTED = 'ACCEPTED',
  DEFAULT = 'DEFAULT',
  MERGED = 'MERGED',
  PENDING_DOUBLE_OPT_IN = 'PENDING_DOUBLE_OPT_IN',
  PENDING_PAPER = 'PENDING_PAPER',
  REJECTED = 'REJECTED',
}

export type PersonConsentLifecycleStateFilterInput = {
  _eq?: InputMaybe<PersonConsentLifecycleState>;
  _in?: InputMaybe<Array<PersonConsentLifecycleState>>;
  _ne?: InputMaybe<PersonConsentLifecycleState>;
  _nin?: InputMaybe<Array<PersonConsentLifecycleState>>;
};

export type PersonConsentOrderByInput = {
  actionAt?: InputMaybe<OrderBy>;
  consentType?: InputMaybe<ConsentTypeOrderByInput>;
  formIdentifier?: InputMaybe<OrderBy>;
  issuer?: InputMaybe<PersonOrderByInput>;
  lifecycleState?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  signer?: InputMaybe<PersonOrderByInput>;
  state?: InputMaybe<OrderBy>;
};

export type PersonEdge = {
  __typename?: 'PersonEdge';
  /** A cursor to this edge. This is the entity id */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The person entity */
  node?: Maybe<Person>;
};

export type PersonFacetsPreference = {
  __typename?: 'PersonFacetsPreference';
  /** Preference for attributes facet. */
  attributes: Array<Attribute>;
  /** Preference for consent types facet. */
  consentTypes: Array<ConsentType>;
  /** Preference for employee functions facet. */
  employeeFunctions: Array<EmployeeFunction>;
  /** Preference for person types facet. */
  personTypes: Array<PersonType>;
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Preference for specialities facet. */
  specialities: Array<Speciality>;
  /** Preference for speciality groups facet. */
  specialityGroups: Array<SpecialityGroup>;
};

export type PersonFacetsPreferenceInput = {
  /** Preference for attributes facet. */
  attributes: Array<Scalars['AttributeId']['input']>;
  /** Preference for consent types facet. */
  consentTypes: Array<Scalars['ConsentTypeId']['input']>;
  /** Preference for employee functions facet. */
  employeeFunctions: Array<Scalars['EmployeeFunctionId']['input']>;
  /** Preference for person types facet. */
  personTypes: Array<Scalars['PersonTypeId']['input']>;
  /** Preference for specialities facet. */
  specialities: Array<Scalars['SpecialityId']['input']>;
  /** Preference for speciality groups facet. */
  specialityGroups: Array<Scalars['SpecialityGroupId']['input']>;
};

export type PersonFilterInput = {
  _and?: InputMaybe<Array<PersonFilterInput>>;
  _or?: InputMaybe<Array<PersonFilterInput>>;
  activities?: InputMaybe<ActivityFilterInput>;
  addresses?: InputMaybe<EntityAddressFilterInput>;
  attributes?: InputMaybe<AttributeValueFilterInput>;
  birthday?: InputMaybe<DateFilterInput>;
  comment?: InputMaybe<StringFilterInput>;
  communicationData?: InputMaybe<CommunicationDataFilterInput>;
  consents?: InputMaybe<PersonConsentFilterInput>;
  customerReference?: InputMaybe<StringFilterInput>;
  employments?: InputMaybe<EmploymentFilterInput>;
  firstName?: InputMaybe<StringFilterInput>;
  gender?: InputMaybe<GenderFilterInput>;
  inClearance?: InputMaybe<BooleanFilterInput>;
  lastName?: InputMaybe<StringFilterInput>;
  middleName?: InputMaybe<StringFilterInput>;
  oid?: InputMaybe<PersonIdFilterInput>;
  personState?: InputMaybe<PersonStateFilterInput>;
  personTypes?: InputMaybe<PersonTypeFilterInput>;
  postfixAcademicTitle?: InputMaybe<AcademicTitleFilterInput>;
  prefixAcademicTitle?: InputMaybe<AcademicTitleFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  scope?: InputMaybe<ScopeFilterInput>;
  specialities?: InputMaybe<PersonSpecialityFilterInput>;
  specialityGroup?: InputMaybe<SpecialityGroupFilterInput>;
  state?: InputMaybe<StateFilterInput>;
};

export type PersonIdFilterInput = {
  _and?: InputMaybe<Array<PersonIdFilterInput>>;
  _eq?: InputMaybe<Scalars['PersonId']['input']>;
  _in?: InputMaybe<Array<Scalars['PersonId']['input']>>;
  _ne?: InputMaybe<Scalars['PersonId']['input']>;
  _nin?: InputMaybe<Array<Scalars['PersonId']['input']>>;
  _or?: InputMaybe<Array<PersonIdFilterInput>>;
};

export type PersonOrderByInput = {
  birthday?: InputMaybe<OrderBy>;
  comment?: InputMaybe<OrderBy>;
  customerReference?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  gender?: InputMaybe<OrderBy>;
  inClearance?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  middleName?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  personState?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
};

export type PersonPromotionalMaterial = {
  __typename?: 'PersonPromotionalMaterial';
  /**
   *  The already given quantity in the current period.
   *  Current period can be either current year or a given time span.
   *  It is defined in the promotionalMaterial rules
   */
  givenQuantityInCurrentPeriod: Scalars['Int']['output'];
  /** Maximum quantity that can be given in the current period, Null means no quantity limitation */
  maximumQuantityInCurrentPeriod?: Maybe<Scalars['Int']['output']>;
  /** PromotionalMaterial entity */
  promotionalMaterial: PromotionalMaterial;
};

export type PersonPromotionalMaterialConnection = {
  __typename?: 'PersonPromotionalMaterialConnection';
  /** List of edges. Default sorting is by PromotionalMaterial name ASC */
  edges?: Maybe<Array<PersonPromotionalMaterialEdge>>;
  /** Count of the entities with the current filter applied */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page */
  pageInfo: PageInfo;
  /** Count of all the entities */
  totalCount: Scalars['Int']['output'];
};

export type PersonPromotionalMaterialEdge = {
  __typename?: 'PersonPromotionalMaterialEdge';
  /** A cursor to this edge. This is the entity id */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The person PromotionalMaterial entity */
  node?: Maybe<PersonPromotionalMaterial>;
};

export type PersonPromotionalMaterialFilterInput = {
  _and?: InputMaybe<Array<PersonPromotionalMaterialFilterInput>>;
  _or?: InputMaybe<Array<PersonPromotionalMaterialFilterInput>>;
  givenQuantityInCurrentPeriod?: InputMaybe<IntegerFilterInput>;
  maximumQuantityInCurrentPeriod?: InputMaybe<IntegerFilterInput>;
  promotionalMaterial?: InputMaybe<PromotionalMaterialFilterInput>;
};

export type PersonPromotionalMaterialOrderByInput = {
  givenQuantityInCurrentPeriod?: InputMaybe<OrderBy>;
  maximumQuantityInCurrentPeriod?: InputMaybe<OrderBy>;
  promotionalMaterial?: InputMaybe<PromotionalMaterialOrderByInput>;
};

export type PersonSpeciality = {
  __typename?: 'PersonSpeciality';
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Speciality sortCode in integer. Order is asc */
  sortCode?: Maybe<Scalars['Int']['output']>;
  /** Speciality entity */
  speciality?: Maybe<Speciality>;
};

export type PersonSpecialityConnection = {
  __typename?: 'PersonSpecialityConnection';
  /** List of edges. Default sorting is by sortCode ASC */
  edges?: Maybe<Array<PersonSpecialityEdge>>;
  /** Count of the entities with the current filter applied */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page */
  pageInfo: PageInfo;
  /** Count of all the entities */
  totalCount: Scalars['Int']['output'];
};

export type PersonSpecialityEdge = {
  __typename?: 'PersonSpecialityEdge';
  /** A cursor to this edge. This is the entity id */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The person speciality entity */
  node?: Maybe<PersonSpeciality>;
};

export type PersonSpecialityFilterInput = {
  _and?: InputMaybe<Array<PersonSpecialityFilterInput>>;
  _or?: InputMaybe<Array<PersonSpecialityFilterInput>>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  speciality?: InputMaybe<SpecialityFilterInput>;
};

export type PersonSpecialityOrderByInput = {
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  sortCode?: InputMaybe<OrderBy>;
  speciality?: InputMaybe<SpecialityOrderByInput>;
};

export enum PersonState {
  ACTIVE = 'ACTIVE',
  DECEASED = 'DECEASED',
  DELETED = 'DELETED',
  GDPR_BLOCKED = 'GDPR_BLOCKED',
  LIMBO = 'LIMBO',
  RETIRED = 'RETIRED',
  TEMPORARILY_NOT_AVAILABLE = 'TEMPORARILY_NOT_AVAILABLE',
  UNKNOWN = 'UNKNOWN',
}

export type PersonStateFilterInput = {
  _eq?: InputMaybe<PersonState>;
  _in?: InputMaybe<Array<PersonState>>;
  _ne?: InputMaybe<PersonState>;
  _nin?: InputMaybe<Array<PersonState>>;
};

export type PersonTouchPointStatistics = {
  __typename?: 'PersonTouchPointStatistics';
  /** currentPlan. Indicates the number of activities for the current plan. */
  currentPlan?: Maybe<Scalars['Float']['output']>;
  /** CurrentPlanExecution. Indicates the real number of activities that has been executed for the current plan. */
  currentPlanExecution?: Maybe<Scalars['Int']['output']>;
  /** Touch point group. */
  touchPointGroup: TouchPointGroup;
};

export type PersonType = Node & {
  __typename?: 'PersonType';
  /** Base type. It can be internal or customer. */
  baseType?: Maybe<PersonBaseType>;
  /** Customer reference. reference, used by the customers to refer to the entity. */
  customerReference?: Maybe<Scalars['String']['output']>;
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Name. String representation of the entity. */
  name?: Maybe<Scalars['String']['output']>;
  /** Uuid. It uniquely identifies the entity across the person types. */
  oid: Scalars['PersonTypeId']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Sort code. Used to prioritise person types. */
  sortCode?: Maybe<Scalars['Int']['output']>;
  /** The type requires speciality to be set. */
  specialityRequired?: Maybe<Scalars['Boolean']['output']>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED */
  state: State;
};

export type PersonTypeConnection = {
  __typename?: 'PersonTypeConnection';
  /** List of edges. Default sorting is by sortCode ASC, name ASC */
  edges?: Maybe<Array<PersonTypeEdge>>;
  /** Count of the entities with the current filter applied */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page */
  pageInfo: PageInfo;
  /** Count of all the entities */
  totalCount: Scalars['Int']['output'];
};

export type PersonTypeEdge = {
  __typename?: 'PersonTypeEdge';
  /** A cursor to this edge. This is the entity id */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The person type entity */
  node?: Maybe<PersonType>;
};

export type PersonTypeFilterInput = {
  _and?: InputMaybe<Array<PersonTypeFilterInput>>;
  _or?: InputMaybe<Array<PersonTypeFilterInput>>;
  baseType?: InputMaybe<PersonBaseTypeFilterInput>;
  customerReference?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  oid?: InputMaybe<PersonTypeIdFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  sortCode?: InputMaybe<IntegerFilterInput>;
  specialityRequired?: InputMaybe<BooleanFilterInput>;
  state?: InputMaybe<StateFilterInput>;
};

export type PersonTypeIdFilterInput = {
  _and?: InputMaybe<Array<PersonTypeIdFilterInput>>;
  _eq?: InputMaybe<Scalars['PersonTypeId']['input']>;
  _in?: InputMaybe<Array<Scalars['PersonTypeId']['input']>>;
  _ne?: InputMaybe<Scalars['PersonTypeId']['input']>;
  _nin?: InputMaybe<Array<Scalars['PersonTypeId']['input']>>;
  _or?: InputMaybe<Array<PersonTypeIdFilterInput>>;
};

export type PersonTypeOrderByInput = {
  baseType?: InputMaybe<OrderBy>;
  customerReference?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  sortCode?: InputMaybe<OrderBy>;
  specialityRequired?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
};

export type PostalCodeCity = Node & {
  __typename?: 'PostalCodeCity';
  /** The city of the entity */
  city: Scalars['String']['output'];
  /** Country where the PostalCodeCity is located */
  country: Country;
  /** Customer reference. reference, used by the customers to refer to the entity. */
  customerReference?: Maybe<Scalars['String']['output']>;
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Uuid. It uniquely identifies the entity across the postal code city combinations. */
  oid: Scalars['PostalCodeCityId']['output'];
  /** The postal code of the entity */
  postalCode: Scalars['String']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED */
  state: State;
};

export type PostalCodeCityConnection = {
  __typename?: 'PostalCodeCityConnection';
  /** List of edges. Default sorting is by postalCode ASC, city ASC */
  edges?: Maybe<Array<PostalCodeCityEdge>>;
  /** Count of the entities with the current filter applied */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page */
  pageInfo: PageInfo;
  /** Count of all the entities */
  totalCount: Scalars['Int']['output'];
};

export type PostalCodeCityEdge = {
  __typename?: 'PostalCodeCityEdge';
  /** A cursor to this edge. This is the entity id */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The academic title entity */
  node?: Maybe<PostalCodeCity>;
};

export type PostalCodeCityFilterInput = {
  _and?: InputMaybe<Array<PostalCodeCityFilterInput>>;
  _or?: InputMaybe<Array<PostalCodeCityFilterInput>>;
  city?: InputMaybe<StringFilterInput>;
  country?: InputMaybe<CountryFilterInput>;
  customerReference?: InputMaybe<StringFilterInput>;
  oid?: InputMaybe<PostalCodeCityIdFilterInput>;
  postalCode?: InputMaybe<StringFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  state?: InputMaybe<StateFilterInput>;
};

export type PostalCodeCityIdFilterInput = {
  _and?: InputMaybe<Array<PostalCodeCityIdFilterInput>>;
  _eq?: InputMaybe<Scalars['PostalCodeCityId']['input']>;
  _in?: InputMaybe<Array<Scalars['PostalCodeCityId']['input']>>;
  _ne?: InputMaybe<Scalars['PostalCodeCityId']['input']>;
  _nin?: InputMaybe<Array<Scalars['PostalCodeCityId']['input']>>;
  _or?: InputMaybe<Array<PostalCodeCityIdFilterInput>>;
};

export type PostalCodeCityOrderByInput = {
  city?: InputMaybe<OrderBy>;
  customerReference?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  postalCode?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
};

export type Preference = {
  __typename?: 'Preference';
  /** facets preferences. */
  facets?: Maybe<FacetsPreference>;
  /** Uuid. It uniquely identifies the entity across the preferences. */
  oid: Scalars['PreferenceId']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type Product = Node & {
  __typename?: 'Product';
  /** Bundles of the product. */
  bundles: ProductBundleConnection;
  /** Customer reference. reference, used by the customers to refer to the entity. */
  customerReference?: Maybe<Scalars['String']['output']>;
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Description. Free field, any text can be placed here */
  description?: Maybe<Scalars['String']['output']>;
  /** Family of the product. */
  family?: Maybe<ProductFamily>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Identifiers of the product. */
  identifiers?: Maybe<Array<ProductIdentifier>>;
  /** License date. */
  licenseDate?: Maybe<Scalars['Date']['output']>;
  /** Name. String representation of the entity. */
  name: Scalars['String']['output'];
  /** Uuid. It uniquely identifies the entity across the products. */
  oid: Scalars['ProductId']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Short description. Free field, any text can be placed here */
  shortDescription?: Maybe<Scalars['String']['output']>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED */
  state: State;
  /** Thumbnails image of the product. */
  thumbnail?: Maybe<Document>;
  /** Type of the product. */
  type?: Maybe<ProductType>;
};

export type ProductBundlesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProductBundleFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProductBundleOrderByInput>>;
};

export type ProductBundle = {
  __typename?: 'ProductBundle';
  /** UUID. It uniquely identifies the entity across the product bundles. */
  oid: Scalars['ProductBundleId']['output'];
  /** Product */
  product: Product;
  /** Quantity of product must be apply. */
  quantity: Scalars['Int']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ProductBundleConnection = {
  __typename?: 'ProductBundleConnection';
  /** List of edges. Default sorting is by quantity ASC */
  edges?: Maybe<Array<ProductBundleEdge>>;
  /** Count of the entities with the current filter applied */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page */
  pageInfo: PageInfo;
  /** Count of all the entities */
  totalCount: Scalars['Int']['output'];
};

export type ProductBundleEdge = {
  __typename?: 'ProductBundleEdge';
  /** A cursor to this edge. This is the entity id */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The product bundle entity */
  node?: Maybe<ProductBundle>;
};

export type ProductBundleFilterInput = {
  _and?: InputMaybe<Array<ProductBundleFilterInput>>;
  _or?: InputMaybe<Array<ProductBundleFilterInput>>;
  oid?: InputMaybe<ProductIdFilterInput>;
  quantity?: InputMaybe<IntegerFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ProductBundleOrderByInput = {
  oid?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
};

export type ProductConnection = {
  __typename?: 'ProductConnection';
  /** List of edges. Default sorting is by name ASC */
  edges?: Maybe<Array<ProductEdge>>;
  /** Count of the entities with the current filter applied */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page */
  pageInfo: PageInfo;
  /** Count of all the entities */
  totalCount: Scalars['Int']['output'];
};

export type ProductEdge = {
  __typename?: 'ProductEdge';
  /** A cursor to this edge. This is the entity id */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The product entity */
  node?: Maybe<Product>;
};

export type ProductFamily = Node & {
  __typename?: 'ProductFamily';
  /** Customer reference. reference, used by the customers to refer to the entity. */
  customerReference: Scalars['String']['output'];
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Name. String representation of the entity. */
  name: Scalars['String']['output'];
  /** Uuid. It uniquely identifies the entity across the product families. */
  oid: Scalars['ProductFamilyId']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED */
  state: State;
};

export type ProductFamilyConnection = {
  __typename?: 'ProductFamilyConnection';
  /** List of edges. Default sorting is by name ASC */
  edges?: Maybe<Array<ProductFamilyEdge>>;
  /** Count of the entities with the current filter applied */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page */
  pageInfo: PageInfo;
  /** Count of all the entities */
  totalCount: Scalars['Int']['output'];
};

export type ProductFamilyEdge = {
  __typename?: 'ProductFamilyEdge';
  /** A cursor to this edge. This is the entity id */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The ProductFamily entity */
  node?: Maybe<ProductFamily>;
};

export type ProductFamilyFilterInput = {
  _and?: InputMaybe<Array<ProductFamilyFilterInput>>;
  _or?: InputMaybe<Array<ProductFamilyFilterInput>>;
  customerReference?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  oid?: InputMaybe<ProductFamilyIdFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  state?: InputMaybe<StateFilterInput>;
};

export type ProductFamilyIdFilterInput = {
  _and?: InputMaybe<Array<ProductFamilyIdFilterInput>>;
  _eq?: InputMaybe<Scalars['ProductFamilyId']['input']>;
  _in?: InputMaybe<Array<Scalars['ProductFamilyId']['input']>>;
  _ne?: InputMaybe<Scalars['ProductFamilyId']['input']>;
  _nin?: InputMaybe<Array<Scalars['ProductFamilyId']['input']>>;
  _or?: InputMaybe<Array<ProductFamilyIdFilterInput>>;
};

export type ProductFamilyOrderByInput = {
  customerReference?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
};

export type ProductFilterInput = {
  _and?: InputMaybe<Array<ProductFilterInput>>;
  _or?: InputMaybe<Array<ProductFilterInput>>;
  bundles?: InputMaybe<ProductBundleFilterInput>;
  customerReference?: InputMaybe<StringFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  family?: InputMaybe<ProductFamilyFilterInput>;
  identifiers?: InputMaybe<ProductIdentifierFilterInput>;
  licenseDate?: InputMaybe<DateFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  oid?: InputMaybe<ProductIdFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  shortDescription?: InputMaybe<StringFilterInput>;
  state?: InputMaybe<StateFilterInput>;
  thumbnail?: InputMaybe<DocumentFilterInput>;
  type?: InputMaybe<ProductTypeFilterInput>;
};

export type ProductIdFilterInput = {
  _and?: InputMaybe<Array<ProductIdFilterInput>>;
  _eq?: InputMaybe<Scalars['ProductId']['input']>;
  _in?: InputMaybe<Array<Scalars['ProductId']['input']>>;
  _ne?: InputMaybe<Scalars['ProductId']['input']>;
  _nin?: InputMaybe<Array<Scalars['ProductId']['input']>>;
  _or?: InputMaybe<Array<ProductIdFilterInput>>;
};

export type ProductIdentifier = {
  __typename?: 'ProductIdentifier';
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Type of the product identifier */
  type: ProductIdentifierType;
  /** Value of the product identifier */
  value: Scalars['String']['output'];
};

export type ProductIdentifierFilterInput = {
  _and?: InputMaybe<Array<ProductIdentifierFilterInput>>;
  _or?: InputMaybe<Array<ProductIdentifierFilterInput>>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  type?: InputMaybe<ProductIdentifierTypeFilterInput>;
  value?: InputMaybe<StringFilterInput>;
};

export enum ProductIdentifierType {
  GTIN_13 = 'GTIN_13',
  PHC_CHE = 'PHC_CHE',
  PZN_AUT = 'PZN_AUT',
  PZN_DEU = 'PZN_DEU',
}

export type ProductIdentifierTypeFilterInput = {
  _eq?: InputMaybe<ProductIdentifierType>;
  _in?: InputMaybe<Array<ProductIdentifierType>>;
  _ne?: InputMaybe<ProductIdentifierType>;
  _nin?: InputMaybe<Array<ProductIdentifierType>>;
};

export type ProductOrderByInput = {
  customerReference?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  licenseDate?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  shortDescription?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
};

export type ProductType = Node & {
  __typename?: 'ProductType';
  /** Customer reference. Reference, used by the customers to refer to the entity. */
  customerReference: Scalars['String']['output'];
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Name. String representation of the entity. */
  name: Scalars['String']['output'];
  /** Uuid. It uniquely identifies the entity across the product types. */
  oid: Scalars['ProductTypeId']['output'];
  /** Type of the promotional material: PRODUCT, MARKETING_MATERIAL, MARKETING_SERVICE. */
  promotionalMaterialTypes: Array<PromotionalMaterialType>;
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated. */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED. */
  state: State;
};

export type ProductTypeConnection = {
  __typename?: 'ProductTypeConnection';
  /** List of edges. Default sorting is by name ASC. */
  edges?: Maybe<Array<ProductTypeEdge>>;
  /** Count of the entities with the current filter applied. */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page. */
  pageInfo: PageInfo;
  /** Count of all the entities. */
  totalCount: Scalars['Int']['output'];
};

export type ProductTypeEdge = {
  __typename?: 'ProductTypeEdge';
  /** A cursor to this edge. This is the entity id. */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one. */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one. */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The ProductType entity. */
  node?: Maybe<ProductType>;
};

export type ProductTypeFilterInput = {
  _and?: InputMaybe<Array<ProductTypeFilterInput>>;
  _or?: InputMaybe<Array<ProductTypeFilterInput>>;
  customerReference?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  oid?: InputMaybe<ProductTypeIdFilterInput>;
  promotionalMaterialTypes?: InputMaybe<PromotionalMaterialTypeFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  state?: InputMaybe<StateFilterInput>;
};

export type ProductTypeIdFilterInput = {
  _and?: InputMaybe<Array<ProductTypeIdFilterInput>>;
  _eq?: InputMaybe<Scalars['ProductTypeId']['input']>;
  _in?: InputMaybe<Array<Scalars['ProductTypeId']['input']>>;
  _ne?: InputMaybe<Scalars['ProductTypeId']['input']>;
  _nin?: InputMaybe<Array<Scalars['ProductTypeId']['input']>>;
  _or?: InputMaybe<Array<ProductTypeIdFilterInput>>;
};

export type ProductTypeOrderByInput = {
  customerReference?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
};

export type PromotionalMaterial = Node & {
  __typename?: 'PromotionalMaterial';
  /** ActivityTypes: List of activity types in which the promotional material can be used */
  activityTypes: Array<PromotionalMaterialActivityType>;
  /** Approval required. Indicates if this entity should be approved before dispatched. */
  approvalRequired?: Maybe<Scalars['Boolean']['output']>;
  /** Promotional material batches. List of batches related with the entity. */
  batches: PromotionalMaterialBatchConnection;
  /** Customer reference. Reference, used by the customers to refer to the entity. */
  customerReference?: Maybe<Scalars['String']['output']>;
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Signature required. Indicates if the delivery for this entity should be confirmed. */
  deliveryConfirmationRequired?: Maybe<Scalars['Boolean']['output']>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Name. String representation of the entity. */
  name: Scalars['String']['output'];
  /** Uuid. It uniquely identifies the entity across the promotional materials. */
  oid: Scalars['PromotionalMaterialId']['output'];
  /** Product. product related */
  product?: Maybe<Product>;
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated. */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** List of rules connected to this promotional material */
  rules: Array<PromotionalMaterialRule>;
  /** Signature required. Indicates if this entity should be sign. */
  signatureRequired?: Maybe<Scalars['Boolean']['output']>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED. */
  state: State;
  /** Topics. List of topics which the promotional material can be used with */
  topics: TopicConnection;
  /** Type of the promotional material: PRODUCT, MARKETING_MATERIAL, MARKETING_SERVICE. */
  type: PromotionalMaterialType;
};

export type PromotionalMaterialActivityTypesArgs = {
  filter?: InputMaybe<PromotionalMaterialActivityTypeFilterInput>;
};

export type PromotionalMaterialBatchesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PromotionalMaterialBatchFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PromotionalMaterialBatchOrderByInput>>;
};

export type PromotionalMaterialTopicsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TopicFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TopicOrderByInput>>;
};

export type PromotionalMaterialActivityType = {
  __typename?: 'PromotionalMaterialActivityType';
  /** Activity type for which the promotional material is available. */
  activityType: ActivityType;
  /** Stock types which can be distributed in this activity type. */
  stockTypes: Array<StockType>;
};

export type PromotionalMaterialActivityTypeFilterInput = {
  _and?: InputMaybe<Array<ActivityTypeFilterInput>>;
  _or?: InputMaybe<Array<ActivityTypeFilterInput>>;
  activityType?: InputMaybe<ActivityTypeFilterInput>;
  oid?: InputMaybe<ActivityTypeIdFilterInput>;
};

export type PromotionalMaterialBatch = {
  __typename?: 'PromotionalMaterialBatch';
  /** Expiry date. Date when this entity expired. */
  expiryDate?: Maybe<Scalars['Date']['output']>;
  /** Name. String representation of the entity. */
  name?: Maybe<Scalars['String']['output']>;
  /** Uuid. It uniquely identifies the entity across the promotional material batches. */
  oid: Scalars['PromotionalMaterialBatchId']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated. */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PromotionalMaterialBatchConnection = {
  __typename?: 'PromotionalMaterialBatchConnection';
  /** List of edges. Default sorting is by name ASC. */
  edges?: Maybe<Array<PromotionalMaterialBatchEdge>>;
  /** Count of the entities with the current filter applied. */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page. */
  pageInfo: PageInfo;
  /** Count of all the entities. */
  totalCount: Scalars['Int']['output'];
};

export type PromotionalMaterialBatchEdge = {
  __typename?: 'PromotionalMaterialBatchEdge';
  /** A cursor to this edge. This is the entity id. */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one. */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one. */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The Promotional material batch entity. */
  node?: Maybe<PromotionalMaterialBatch>;
};

export type PromotionalMaterialBatchFilterInput = {
  _and?: InputMaybe<Array<PromotionalMaterialBatchFilterInput>>;
  _or?: InputMaybe<Array<PromotionalMaterialBatchFilterInput>>;
  expiryDate?: InputMaybe<DateFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type PromotionalMaterialBatchOrderByInput = {
  expiryDate?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
};

export type PromotionalMaterialConnection = {
  __typename?: 'PromotionalMaterialConnection';
  /** List of edges. Default sorting is by name ASC. */
  edges?: Maybe<Array<PromotionalMaterialEdge>>;
  /** Count of the entities with the current filter applied. */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page. */
  pageInfo: PageInfo;
  /** Count of all the entities. */
  totalCount: Scalars['Int']['output'];
};

export type PromotionalMaterialEdge = {
  __typename?: 'PromotionalMaterialEdge';
  /** A cursor to this edge. This is the entity id. */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one. */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one. */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The Promotional material entity. */
  node?: Maybe<PromotionalMaterial>;
};

export type PromotionalMaterialEndDateRule = PromotionalMaterialRule & {
  __typename?: 'PromotionalMaterialEndDateRule';
  /** End date of the promotional material. */
  endDate: Scalars['Date']['output'];
  /** Uuid. It uniquely identifies the entity across the promotional material rules. */
  oid: Scalars['PromotionalMaterialRuleId']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated. */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PromotionalMaterialFilterInput = {
  _and?: InputMaybe<Array<PromotionalMaterialFilterInput>>;
  _or?: InputMaybe<Array<PromotionalMaterialFilterInput>>;
  activityTypes?: InputMaybe<PromotionalMaterialActivityTypeFilterInput>;
  approvalRequired?: InputMaybe<BooleanFilterInput>;
  batches?: InputMaybe<PromotionalMaterialBatchFilterInput>;
  customerReference?: InputMaybe<StringFilterInput>;
  deliveryConfirmationRequired?: InputMaybe<BooleanFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  oid?: InputMaybe<PromotionalMaterialIdFilterInput>;
  product?: InputMaybe<ProductFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  signatureRequired?: InputMaybe<BooleanFilterInput>;
  state?: InputMaybe<StateFilterInput>;
  topics?: InputMaybe<TopicFilterInput>;
  type?: InputMaybe<PromotionalMaterialTypeFilterInput>;
};

export type PromotionalMaterialIdFilterInput = {
  _and?: InputMaybe<Array<PromotionalMaterialIdFilterInput>>;
  _eq?: InputMaybe<Scalars['PromotionalMaterialId']['input']>;
  _in?: InputMaybe<Array<Scalars['PromotionalMaterialId']['input']>>;
  _ne?: InputMaybe<Scalars['PromotionalMaterialId']['input']>;
  _nin?: InputMaybe<Array<Scalars['PromotionalMaterialId']['input']>>;
  _or?: InputMaybe<Array<PromotionalMaterialIdFilterInput>>;
};

export type PromotionalMaterialMaximumQuantityPerActivityRule =
  PromotionalMaterialRule & {
    __typename?: 'PromotionalMaterialMaximumQuantityPerActivityRule';
    /** Maximum quantity which can be given per activity. */
    maximumQuantity: Scalars['Int']['output'];
    /** Uuid. It uniquely identifies the entity across the promotional material rules. */
    oid: Scalars['PromotionalMaterialRuleId']['output'];
    /** Creation date. */
    recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
    /** Date when was last updated. */
    recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  };

export type PromotionalMaterialMaximumQuantityPerPeriodRule =
  PromotionalMaterialRule & {
    __typename?: 'PromotionalMaterialMaximumQuantityPerPeriodRule';
    /** Maximum quantity which can be given in a period. */
    maximumQuantity: Scalars['Int']['output'];
    /** Uuid. It uniquely identifies the entity across the promotional material rules. */
    oid: Scalars['PromotionalMaterialRuleId']['output'];
    /** Period of the promotional material rule. */
    period: PromotionalMaterialRulePeriod;
    /** Creation date. */
    recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
    /** Date when was last updated. */
    recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  };

export type PromotionalMaterialOrderByInput = {
  approvalRequired?: InputMaybe<OrderBy>;
  customerReference?: InputMaybe<OrderBy>;
  deliveryConfirmationRequired?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  signatureRequired?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

export enum PromotionalMaterialPeriodType {
  CURRENT_YEAR = 'CURRENT_YEAR',
  CUSTOM_INTERVAL = 'CUSTOM_INTERVAL',
}

export type PromotionalMaterialRequestInput = {
  oid: Scalars['GivenPromotionalMaterialId']['input'];
  promotionalMaterialId: Scalars['PromotionalMaterialId']['input'];
  quantity: Scalars['Int']['input'];
  stockId?: InputMaybe<Scalars['StockId']['input']>;
};

/** An abstract object for promotional materials */
export type PromotionalMaterialRule = {
  /** Uuid. It uniquely identifies the entity across the promotional material rules. */
  oid: Scalars['PromotionalMaterialRuleId']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated. */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PromotionalMaterialRulePeriod = {
  __typename?: 'PromotionalMaterialRulePeriod';
  /** Period representation in a ISO-8601 period formats if it's CUSTOM_INTERVAL, e.g. P2Y, P3M, P1Y2M3D, etc */
  period?: Maybe<Scalars['String']['output']>;
  /** Start date of the period if it's CUSTOM_INTERVAL. */
  startDate?: Maybe<Scalars['Date']['output']>;
  /** Type of the period. Possible values are CURRENT_YEAR and CUSTOM_INTERVAL */
  type: PromotionalMaterialPeriodType;
};

export enum PromotionalMaterialType {
  MARKETING_MATERIAL = 'MARKETING_MATERIAL',
  MARKETING_SERVICE = 'MARKETING_SERVICE',
  PRODUCT = 'PRODUCT',
}

export type PromotionalMaterialTypeFilterInput = {
  _eq?: InputMaybe<PromotionalMaterialType>;
  _in?: InputMaybe<Array<PromotionalMaterialType>>;
  _ne?: InputMaybe<PromotionalMaterialType>;
  _nin?: InputMaybe<Array<PromotionalMaterialType>>;
};

export type Query = {
  __typename?: 'Query';
  /** Returns an academic title by a given ID */
  academicTitle?: Maybe<AcademicTitle>;
  /** Returns all academic titles */
  academicTitles: AcademicTitleConnection;
  activities: ActivityConnection;
  activity?: Maybe<Activity>;
  activityType?: Maybe<ActivityType>;
  activityTypes: ActivityTypeConnection;
  address?: Maybe<Address>;
  addresses: AddressConnection;
  attribute?: Maybe<Attribute>;
  attributes: AttributeConnection;
  callOutcome?: Maybe<CallOutcome>;
  callOutcomes: CallOutcomeConnection;
  commentType?: Maybe<CommentType>;
  commentTypes: CommentTypeConnection;
  communicationDataType?: Maybe<CommunicationDataType>;
  communicationDataTypes: CommunicationDataTypeConnection;
  /** Returns a list of all paper consent form identifiers for the current user that are pending and can be used */
  consentFormIdentifiers: Array<Scalars['String']['output']>;
  consentType?: Maybe<ConsentType>;
  consentTypes: ConsentTypeConnection;
  countries: CountryConnection;
  country?: Maybe<Country>;
  currentUser?: Maybe<User>;
  deliveryTimeFrame?: Maybe<DeliveryTimeFrame>;
  deliveryTimeFrames: DeliveryTimeFrameConnection;
  document?: Maybe<Document>;
  documents: DocumentConnection;
  employeeDepartment?: Maybe<EmployeeDepartment>;
  employeeDepartments: EmployeeDepartmentConnection;
  employeeFunction?: Maybe<EmployeeFunction>;
  employeeFunctions: EmployeeFunctionConnection;
  employment?: Maybe<Employment>;
  employments: EmploymentConnection;
  inventories: InventoryConnection;
  inventory?: Maybe<Inventory>;
  keyMessage?: Maybe<KeyMessage>;
  keyMessages: KeyMessageConnection;
  media?: Maybe<Media>;
  mediaTrackingInfo?: Maybe<MediaTrackingInfo>;
  mediaTrackingInfos: MediaTrackingInfoConnection;
  medias: MediaConnection;
  node?: Maybe<Node>;
  organization?: Maybe<Organization>;
  organizationType?: Maybe<OrganizationType>;
  organizationTypes: OrganizationTypeConnection;
  organizationalUnit?: Maybe<OrganizationalUnit>;
  organizationalUnitType?: Maybe<OrganizationalUnitType>;
  organizationalUnitTypes: OrganizationalUnitTypeConnection;
  organizationalUnits: OrganizationalUnitConnection;
  organizations: OrganizationConnection;
  person?: Maybe<Person>;
  personAssignment?: Maybe<PersonAssignment>;
  personAssignmentType?: Maybe<PersonAssignmentType>;
  personAssignmentTypes: PersonAssignmentTypeConnection;
  personAssignments: PersonAssignmentConnection;
  /** Returns a person consent by a given ID */
  personConsent?: Maybe<PersonConsent>;
  /** Returns all person consents which had an action on it */
  personConsents: PersonConsentConnection;
  personType?: Maybe<PersonType>;
  personTypes: PersonTypeConnection;
  persons: PersonConnection;
  postalCodeCities: PostalCodeCityConnection;
  postalCodeCity?: Maybe<PostalCodeCity>;
  preference?: Maybe<Preference>;
  product?: Maybe<Product>;
  productFamilies: ProductFamilyConnection;
  productFamily?: Maybe<ProductFamily>;
  productType?: Maybe<ProductType>;
  productTypes: ProductTypeConnection;
  products: ProductConnection;
  promotionalMaterial?: Maybe<PromotionalMaterial>;
  promotionalMaterials: PromotionalMaterialConnection;
  recentAccountHistory?: Maybe<RecentAccountHistory>;
  /** Returns sample request form by a given ID */
  sampleRequestForm?: Maybe<SampleRequestForm>;
  /** Returns all sample request forms */
  sampleRequestForms: SampleRequestFormConnection;
  specialities: SpecialityConnection;
  speciality?: Maybe<Speciality>;
  specialityGroup?: Maybe<SpecialityGroup>;
  specialityGroups: SpecialityGroupConnection;
  systemConfiguration?: Maybe<SystemConfiguration>;
  template?: Maybe<Template>;
  templates: TemplateConnection;
  topic?: Maybe<Topic>;
  topics: TopicConnection;
  touchPointGroup?: Maybe<TouchPointGroup>;
  touchPointGroups: TouchPointGroupConnection;
  touchpoint?: Maybe<Touchpoint>;
  touchpoints: TouchpointConnection;
  user?: Maybe<User>;
  users: UserConnection;
};

export type QueryAcademicTitleArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAcademicTitlesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AcademicTitleFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AcademicTitleOrderByInput>>;
};

export type QueryActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ActivityFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActivityOrderByInput>>;
};

export type QueryActivityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryActivityTypeArgs = {
  id: Scalars['ID']['input'];
};

export type QueryActivityTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ActivityTypeFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActivityTypeOrderByInput>>;
};

export type QueryAddressArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAddressesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AddressFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AddressOrderByInput>>;
};

export type QueryAttributeArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAttributesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AttributeFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AttributeOrderByInput>>;
};

export type QueryCallOutcomeArgs = {
  id: Scalars['ID']['input'];
};

export type QueryCallOutcomesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CallOutcomeFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CallOutcomeOrderByInput>>;
};

export type QueryCommentTypeArgs = {
  id: Scalars['ID']['input'];
};

export type QueryCommentTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommentTypeFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentTypeOrderByInput>>;
};

export type QueryCommunicationDataTypeArgs = {
  id: Scalars['ID']['input'];
};

export type QueryCommunicationDataTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CommunicationDataTypeFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommunicationDataTypeOrderByInput>>;
};

export type QueryConsentFormIdentifiersArgs = {
  consentTypeId: Scalars['ID']['input'];
};

export type QueryConsentTypeArgs = {
  id: Scalars['ID']['input'];
};

export type QueryConsentTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ConsentTypeFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ConsentTypeOrderByInput>>;
};

export type QueryCountriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CountryFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CountryOrderByInput>>;
};

export type QueryCountryArgs = {
  id: Scalars['ID']['input'];
};

export type QueryDeliveryTimeFrameArgs = {
  id: Scalars['ID']['input'];
};

export type QueryDeliveryTimeFramesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<DeliveryTimeFrameFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DeliveryTimeFrameOrderByInput>>;
};

export type QueryDocumentArgs = {
  id: Scalars['ID']['input'];
};

export type QueryDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<DocumentFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DocumentOrderByInput>>;
};

export type QueryEmployeeDepartmentArgs = {
  id: Scalars['ID']['input'];
};

export type QueryEmployeeDepartmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EmployeeDepartmentFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeDepartmentOrderByInput>>;
};

export type QueryEmployeeFunctionArgs = {
  id: Scalars['ID']['input'];
};

export type QueryEmployeeFunctionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EmployeeFunctionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeFunctionOrderByInput>>;
};

export type QueryEmploymentArgs = {
  id: Scalars['ID']['input'];
};

export type QueryEmploymentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EmploymentFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmploymentOrderByInput>>;
};

export type QueryInventoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<InventoryFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InventoryOrderByInput>>;
};

export type QueryInventoryArgs = {
  id: Scalars['ID']['input'];
};

export type QueryKeyMessageArgs = {
  id: Scalars['ID']['input'];
};

export type QueryKeyMessagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<KeyMessageFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<KeyMessageOrderByInput>>;
};

export type QueryMediaArgs = {
  id: Scalars['ID']['input'];
};

export type QueryMediaTrackingInfoArgs = {
  id: Scalars['ID']['input'];
};

export type QueryMediaTrackingInfosArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MediaTrackingInfoFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MediaTrackingInfoOrderByInput>>;
};

export type QueryMediasArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MediaFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MediaOrderByInput>>;
};

export type QueryNodeArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOrganizationArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOrganizationTypeArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOrganizationTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<OrganizationTypeFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<OrganizationTypeOrderByInput>>;
};

export type QueryOrganizationalUnitArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOrganizationalUnitTypeArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOrganizationalUnitTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<OrganizationalUnitTypeFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<OrganizationalUnitTypeOrderByInput>>;
};

export type QueryOrganizationalUnitsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<OrganizationalUnitFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<OrganizationalUnitOrderByInput>>;
};

export type QueryOrganizationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<OrganizationFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<OrganizationOrderByInput>>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type QueryPersonArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPersonAssignmentArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPersonAssignmentTypeArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPersonAssignmentTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PersonAssignmentTypeFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PersonAssignmentTypeOrderByInput>>;
};

export type QueryPersonAssignmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PersonAssignmentFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PersonAssignmentOrderByInput>>;
};

export type QueryPersonConsentArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPersonConsentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PersonConsentFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PersonConsentOrderByInput>>;
};

export type QueryPersonTypeArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPersonTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PersonTypeFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PersonTypeOrderByInput>>;
};

export type QueryPersonsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PersonFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PersonOrderByInput>>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type QueryPostalCodeCitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PostalCodeCityFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PostalCodeCityOrderByInput>>;
};

export type QueryPostalCodeCityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPreferenceArgs = {
  id: Scalars['ID']['input'];
};

export type QueryProductArgs = {
  id: Scalars['ID']['input'];
};

export type QueryProductFamiliesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProductFamilyFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProductFamilyOrderByInput>>;
};

export type QueryProductFamilyArgs = {
  id: Scalars['ID']['input'];
};

export type QueryProductTypeArgs = {
  id: Scalars['ID']['input'];
};

export type QueryProductTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProductTypeFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProductTypeOrderByInput>>;
};

export type QueryProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProductFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProductOrderByInput>>;
};

export type QueryPromotionalMaterialArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPromotionalMaterialsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PromotionalMaterialFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PromotionalMaterialOrderByInput>>;
};

export type QuerySampleRequestFormArgs = {
  id: Scalars['ID']['input'];
};

export type QuerySampleRequestFormsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SampleRequestFormFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SampleRequestFormOrderByInput>>;
};

export type QuerySpecialitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SpecialityFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SpecialityOrderByInput>>;
};

export type QuerySpecialityArgs = {
  id: Scalars['ID']['input'];
};

export type QuerySpecialityGroupArgs = {
  id: Scalars['ID']['input'];
};

export type QuerySpecialityGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SpecialityGroupFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SpecialityGroupOrderByInput>>;
};

export type QueryTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type QueryTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TemplateFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TemplateOrderByInput>>;
};

export type QueryTopicArgs = {
  id: Scalars['ID']['input'];
};

export type QueryTopicsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TopicFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TopicOrderByInput>>;
};

export type QueryTouchPointGroupArgs = {
  id: Scalars['ID']['input'];
};

export type QueryTouchPointGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TouchPointGroupFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TouchPointGroupOrderByInput>>;
};

export type QueryTouchpointArgs = {
  id: Scalars['ID']['input'];
};

export type QueryTouchpointsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TouchpointFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TouchpointOrderByInput>>;
};

export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserOrderByInput>>;
};

export enum Reaction {
  NEGATIVE = 'NEGATIVE',
  NEUTRAL = 'NEUTRAL',
  NONE = 'NONE',
  POSITIVE = 'POSITIVE',
}

export type ReactionFilterInput = {
  _eq?: InputMaybe<Reaction>;
  _in?: InputMaybe<Array<Reaction>>;
  _ne?: InputMaybe<Reaction>;
  _nin?: InputMaybe<Array<Reaction>>;
};

export type RecentAccountHistory = {
  __typename?: 'RecentAccountHistory';
  /** Last seen organization. */
  organizations: OrganizationConnection;
  /** Last seen persons. */
  persons: PersonConnection;
};

export type RecentAccountHistoryOrganizationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<OrganizationFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<OrganizationOrderByInput>>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type RecentAccountHistoryPersonsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PersonFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PersonOrderByInput>>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type RecentAccountInput = {
  /** UUID. It uniquely identifies the entity across the persons or organizations. */
  oid: Scalars['UUID']['input'];
  /** Name. E.g. Dr. med. vet. */
  type: RecentAccountType;
};

export enum RecentAccountType {
  ORGANIZATION = 'ORGANIZATION',
  PERSON = 'PERSON',
}

export type RejectPersonConsentInput = {
  actionAt: Scalars['DateTime']['input'];
  consentTypeId: Scalars['ConsentTypeId']['input'];
  issuerPersonId: Scalars['PersonId']['input'];
  oid: Scalars['PersonConsentId']['input'];
  signerPersonId: Scalars['PersonId']['input'];
};

export type RemoteInteractionCodeInput = {
  /**
   *  The object identifier of the activity attendee id.
   *  This is optional. If provided, the code is associated with that attendee
   */
  attendeeId?: InputMaybe<Scalars['ActivityAttendeeId']['input']>;
  /** The object identifier of the activity. */
  oid: Scalars['ActivityId']['input'];
};

export type ResendDoubleOptInInput = {
  oid: Scalars['PersonConsentId']['input'];
};

export type ResetPersonConsentInput = {
  actionAt: Scalars['DateTime']['input'];
  issuerPersonId: Scalars['PersonId']['input'];
  oid: Scalars['PersonConsentId']['input'];
};

export enum ResponsibleBody {
  PRI = 'PRI',
  PUB = 'PUB',
  UNI = 'UNI',
  UNKNOWN = 'UNKNOWN',
}

export type ResponsibleBodyFilterInput = {
  _eq?: InputMaybe<ResponsibleBody>;
  _in?: InputMaybe<Array<ResponsibleBody>>;
  _ne?: InputMaybe<ResponsibleBody>;
  _nin?: InputMaybe<Array<ResponsibleBody>>;
};

export type RevokePersonConsentInput = {
  actionAt: Scalars['DateTime']['input'];
  issuerPersonId: Scalars['PersonId']['input'];
  oid: Scalars['PersonConsentId']['input'];
};

export type SampleRequestForm = Node & {
  __typename?: 'SampleRequestForm';
  /** Activity type. Type of the activity associated to the sample request form. */
  activityType?: Maybe<ActivityType>;
  /** Address: Address of the sample request form. */
  address: Address;
  /** Address extension. Extension of the address. */
  addressExtension?: Maybe<Scalars['String']['output']>;
  /** Created at date. Date when the sample request form is created. */
  createdAt: Scalars['Date']['output'];
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Delivery date. Date when the samples should be delivered. */
  deliveryDate?: Maybe<Scalars['Date']['output']>;
  /** Delivery time frame for the sample request */
  deliveryTimeFrame?: Maybe<DeliveryTimeFrame>;
  /** Document */
  document?: Maybe<Document>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Issuer. Person that supplies the sample request form. */
  issuer: Person;
  /** Sample requests form items */
  items: Array<SampleRequestFormItem>;
  /** Uuid. It uniquely identifies the entity across the sample request forms. */
  oid: Scalars['SampleRequestFormId']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Signer. Person who sings the sample request form. */
  signer: Person;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED */
  state: State;
};

export type SampleRequestFormConnection = {
  __typename?: 'SampleRequestFormConnection';
  /** List of edges. Default sorting is by recCreatedAt DESC */
  edges?: Maybe<Array<SampleRequestFormEdge>>;
  /** Count of the entities with the current filter applied */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page */
  pageInfo: PageInfo;
  /** Count of all the entities */
  totalCount: Scalars['Int']['output'];
};

export type SampleRequestFormEdge = {
  __typename?: 'SampleRequestFormEdge';
  /** A cursor to this edge. This is the entity id */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The SampleRequestForm entity */
  node?: Maybe<SampleRequestForm>;
};

export type SampleRequestFormFilterInput = {
  _and?: InputMaybe<Array<SampleRequestFormFilterInput>>;
  _or?: InputMaybe<Array<SampleRequestFormFilterInput>>;
  activityType?: InputMaybe<ActivityTypeFilterInput>;
  address?: InputMaybe<AddressFilterInput>;
  addressExtension?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deliveryDate?: InputMaybe<DateFilterInput>;
  deliveryTimeFrame?: InputMaybe<DeliveryTimeFrameFilterInput>;
  document?: InputMaybe<DocumentFilterInput>;
  issuer?: InputMaybe<PersonFilterInput>;
  oid?: InputMaybe<SampleRequestFormIdFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  signer?: InputMaybe<PersonFilterInput>;
  state?: InputMaybe<StateFilterInput>;
};

export type SampleRequestFormIdFilterInput = {
  _and?: InputMaybe<Array<SampleRequestFormIdFilterInput>>;
  _eq?: InputMaybe<Scalars['SampleRequestFormId']['input']>;
  _in?: InputMaybe<Array<Scalars['SampleRequestFormId']['input']>>;
  _ne?: InputMaybe<Scalars['SampleRequestFormId']['input']>;
  _nin?: InputMaybe<Array<Scalars['SampleRequestFormId']['input']>>;
  _or?: InputMaybe<Array<SampleRequestFormIdFilterInput>>;
};

export type SampleRequestFormItem = {
  __typename?: 'SampleRequestFormItem';
  /** Quantity of items. */
  quantity: Scalars['Int']['output'];
  /** Stock og the sample request form item. */
  stock: Stock;
};

export type SampleRequestFormItemInput = {
  /** Quantity of items. */
  quantity: Scalars['Int']['input'];
  /** Stock Id of the sample request form item. */
  stockId: Scalars['StockId']['input'];
};

export type SampleRequestFormOrderByInput = {
  activityType?: InputMaybe<ActivityTypeOrderByInput>;
  address?: InputMaybe<AddressOrderByInput>;
  addressExtension?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  deliveryDate?: InputMaybe<OrderBy>;
  issuer?: InputMaybe<PersonOrderByInput>;
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  signer?: InputMaybe<PersonOrderByInput>;
  state?: InputMaybe<OrderBy>;
};

export enum Scope {
  /** Show all entities */
  ALL = 'ALL',
  /** Show only entities, related to my territories */
  TERRITORY = 'TERRITORY',
}

export type ScopeFilterInput = {
  _eq?: InputMaybe<Scope>;
  _in?: InputMaybe<Array<Scope>>;
  _ne?: InputMaybe<Scope>;
  _nin?: InputMaybe<Array<Scope>>;
};

export type Speciality = Node & {
  __typename?: 'Speciality';
  /** Classification. Possible values are SPECIALITY, SUBSPECIALITY and FOCUS */
  classification?: Maybe<SpecialityClassification>;
  /** Customer reference. reference, used by the customers to refer to the entity. */
  customerReference?: Maybe<Scalars['String']['output']>;
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Name. String representation of the entity. */
  name?: Maybe<Scalars['String']['output']>;
  /** Uuid. It uniquely identifies the entity across the specialities. */
  oid: Scalars['SpecialityId']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED */
  state?: Maybe<State>;
};

export enum SpecialityClassification {
  FOCUS = 'FOCUS',
  SPECIALITY = 'SPECIALITY',
  SUBSPECIALITY = 'SUBSPECIALITY',
}

export type SpecialityClassificationFilterInput = {
  _eq?: InputMaybe<SpecialityClassification>;
  _in?: InputMaybe<Array<SpecialityClassification>>;
  _ne?: InputMaybe<SpecialityClassification>;
  _nin?: InputMaybe<Array<SpecialityClassification>>;
};

export type SpecialityConnection = {
  __typename?: 'SpecialityConnection';
  /** List of edges. Default sorting is by name ASC */
  edges?: Maybe<Array<SpecialityEdge>>;
  /** Count of the entities with the current filter applied */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page */
  pageInfo: PageInfo;
  /** Count of all the entities */
  totalCount: Scalars['Int']['output'];
};

export type SpecialityEdge = {
  __typename?: 'SpecialityEdge';
  /** A cursor to this edge. This is the entity id */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The speciality entity */
  node?: Maybe<Speciality>;
};

export type SpecialityFilterInput = {
  _and?: InputMaybe<Array<SpecialityFilterInput>>;
  _or?: InputMaybe<Array<SpecialityFilterInput>>;
  classification?: InputMaybe<SpecialityClassificationFilterInput>;
  customerReference?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  oid?: InputMaybe<SpecialityIdFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  state?: InputMaybe<StateFilterInput>;
};

export type SpecialityGroup = Node & {
  __typename?: 'SpecialityGroup';
  /** Customer reference. reference, used by the customers to refer to the entity. */
  customerReference?: Maybe<Scalars['String']['output']>;
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Name. String representation of the entity. */
  name?: Maybe<Scalars['String']['output']>;
  /** Uuid. It uniquely identifies the entity across the speciality groups. */
  oid: Scalars['SpecialityGroupId']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED */
  state?: Maybe<State>;
};

export type SpecialityGroupConnection = {
  __typename?: 'SpecialityGroupConnection';
  /** List of edges. Default sorting is by name ASC */
  edges?: Maybe<Array<SpecialityGroupEdge>>;
  /** Count of the entities with the current filter applied */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page */
  pageInfo: PageInfo;
  /** Count of all the entities */
  totalCount: Scalars['Int']['output'];
};

export type SpecialityGroupEdge = {
  __typename?: 'SpecialityGroupEdge';
  /** A cursor to this edge. This is the entity id */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The speciality group entity */
  node?: Maybe<SpecialityGroup>;
};

export type SpecialityGroupFilterInput = {
  _and?: InputMaybe<Array<SpecialityGroupFilterInput>>;
  _or?: InputMaybe<Array<SpecialityGroupFilterInput>>;
  customerReference?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  oid?: InputMaybe<SpecialityGroupIdFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  state?: InputMaybe<StateFilterInput>;
};

export type SpecialityGroupIdFilterInput = {
  _and?: InputMaybe<Array<SpecialityGroupIdFilterInput>>;
  _eq?: InputMaybe<Scalars['SpecialityGroupId']['input']>;
  _in?: InputMaybe<Array<Scalars['SpecialityGroupId']['input']>>;
  _ne?: InputMaybe<Scalars['SpecialityGroupId']['input']>;
  _nin?: InputMaybe<Array<Scalars['SpecialityGroupId']['input']>>;
  _or?: InputMaybe<Array<SpecialityGroupIdFilterInput>>;
};

export type SpecialityGroupOrderByInput = {
  customerReference?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
};

export type SpecialityIdFilterInput = {
  _and?: InputMaybe<Array<SpecialityIdFilterInput>>;
  _eq?: InputMaybe<Scalars['SpecialityId']['input']>;
  _in?: InputMaybe<Array<Scalars['SpecialityId']['input']>>;
  _ne?: InputMaybe<Scalars['SpecialityId']['input']>;
  _nin?: InputMaybe<Array<Scalars['SpecialityId']['input']>>;
  _or?: InputMaybe<Array<SpecialityIdFilterInput>>;
};

export type SpecialityOrderByInput = {
  classification?: InputMaybe<OrderBy>;
  customerReference?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
};

export enum State {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  INACTIVE = 'INACTIVE',
  LIMBO = 'LIMBO',
}

export type StateFilterInput = {
  _eq?: InputMaybe<State>;
  _in?: InputMaybe<Array<State>>;
  _ne?: InputMaybe<State>;
  _nin?: InputMaybe<Array<State>>;
};

export type Stock = {
  __typename?: 'Stock';
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Uuid. It uniquely identifies the entity across the stocks. */
  oid: Scalars['StockId']['output'];
  /** Promotional material for this stock. */
  promotionalMaterial: PromotionalMaterial;
  /** Promotional material batch for this stock. */
  promotionalMaterialBatch?: Maybe<PromotionalMaterialBatch>;
  /** Quantity of stock available */
  quantity?: Maybe<Scalars['Int']['output']>;
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated. */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Type. It can be PHYSICAL or VIRTUAL. */
  type?: Maybe<StockType>;
};

export type StockConnection = {
  __typename?: 'StockConnection';
  /** List of edges. */
  edges?: Maybe<Array<StockEdge>>;
  /** Count of the entities with the current filter applied. */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page. */
  pageInfo: PageInfo;
  /** Count of all the entities. */
  totalCount: Scalars['Int']['output'];
};

export type StockEdge = {
  __typename?: 'StockEdge';
  /** A cursor to this edge. This is the entity id. */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The stock entity. */
  node?: Maybe<Stock>;
};

export type StockFilterInput = {
  _and?: InputMaybe<Array<StockFilterInput>>;
  _or?: InputMaybe<Array<StockFilterInput>>;
  promotionalMaterial?: InputMaybe<PromotionalMaterialFilterInput>;
  promotionalMaterialBatch?: InputMaybe<PromotionalMaterialBatchFilterInput>;
  quantity?: InputMaybe<IntegerFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  type?: InputMaybe<StockTypeFilterInput>;
};

export type StockOrderByInput = {
  oid?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

export enum StockType {
  PHYSICAL = 'PHYSICAL',
  VIRTUAL = 'VIRTUAL',
}

export type StockTypeFilterInput = {
  _eq?: InputMaybe<StockType>;
  _in?: InputMaybe<Array<StockType>>;
  _ne?: InputMaybe<StockType>;
  _nin?: InputMaybe<Array<StockType>>;
};

export enum StorageType {
  EXTERNAL = 'EXTERNAL',
  INTERNAL = 'INTERNAL',
}

export type StorageTypeFilterInput = {
  _eq?: InputMaybe<StorageType>;
  _in?: InputMaybe<Array<StorageType>>;
  _ne?: InputMaybe<StorageType>;
  _nin?: InputMaybe<Array<StorageType>>;
};

export type StringFilterInput = {
  /** Matching exactly the value which is passed (case sensitive) */
  _eq?: InputMaybe<Scalars['String']['input']>;
  /**
   *  Matching items which are after or same as the value which is passed,
   *  e.g. _ge("b") gives all items which start with a letter after "b" or with "b" (case sensitive)
   */
  _ge?: InputMaybe<Scalars['String']['input']>;
  /**
   *  Matching items which are after the value which is passed,
   *  e.g. _gt("b") gives all items which start with a letter after "b" (case sensitive)
   */
  _gt?: InputMaybe<Scalars['String']['input']>;
  /** Matching exactly one of the values which are passed (case sensitive) */
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   *  Matching items which are before or same as the value which is passed,
   *  e.g. _le("b") gives all items which start with a letter before "b" or with "b" (case sensitive)
   */
  _le?: InputMaybe<Scalars['String']['input']>;
  /** Like the value which is passed. Use % to replace 0 or more characters, e.g. %a%, a%, %a, etc. (case insensitive) */
  _like?: InputMaybe<Scalars['String']['input']>;
  /**
   *  Matching items which are before the value which is passed,
   *  e.g. _lt("b") gives all items which start with a letter before "b" (case sensitive)
   */
  _lt?: InputMaybe<Scalars['String']['input']>;
  /** Not matching the value which is passed (case sensitive) */
  _ne?: InputMaybe<Scalars['String']['input']>;
  /** Not matching any of the values which are passed (case sensitive) */
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum SupportedMediaType {
  HTML = 'HTML',
  IMAGE = 'IMAGE',
  PDF = 'PDF',
}

export type SupportedMediaTypeFilterInput = {
  _eq?: InputMaybe<SupportedMediaType>;
  _in?: InputMaybe<Array<SupportedMediaType>>;
  _ne?: InputMaybe<SupportedMediaType>;
  _nin?: InputMaybe<Array<SupportedMediaType>>;
};

export type SystemConfiguration = Node & {
  __typename?: 'SystemConfiguration';
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Default country of the system. */
  defaultCountry: Country;
  /** Shows if email validation is enabled. */
  emailValidationEnabled: Scalars['Boolean']['output'];
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Shows if employment role is mandatory for persons. */
  personEmploymentRoleMandatory: Scalars['Boolean']['output'];
  /** Shows if phone numbers validation and adaption is enabled. */
  phoneNumberValidationAndAdaptionEnabled: Scalars['Boolean']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Shows if additional information is enabled for delivery of sample requests. */
  sampleRequestAdditionalDeliveryInformationEnabled: Scalars['Boolean']['output'];
  /** Shows if delivery date selection is enabled for delivery of sample requests */
  sampleRequestDeliveryDateEnabled: Scalars['Boolean']['output'];
  /** Shows minimum days in the future for delivery of sample requests. */
  sampleRequestDeliveryDateMinimumDaysInFuture: Scalars['Int']['output'];
  /** Shows if url validation is enabled. */
  urlValidationEnabled: Scalars['Boolean']['output'];
};

export type Template = Node & {
  __typename?: 'Template';
  /** Available. Boolean flag indicating if this entity is currently available for use. */
  available: Scalars['Boolean']['output'];
  /** Customer reference. Reference, used by the customers to refer to the entity. */
  customerReference?: Maybe<Scalars['String']['output']>;
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Description. String representation of the template description of the entity. */
  description?: Maybe<Scalars['String']['output']>;
  /** Document. Document associated with this entity. */
  document?: Maybe<Document>;
  /** End date. Local date detailing when this entity ends to be available. */
  endDate?: Maybe<Scalars['Date']['output']>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Name. String representation of the name of the entity. */
  name: Scalars['String']['output'];
  /** Uuid. It uniquely identifies the entity across the templates. */
  oid: Scalars['TemplateId']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Start date. Local date detailing when this entity starts to be available. */
  startDate?: Maybe<Scalars['Date']['output']>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED. */
  state: State;
  /** Type. Enum representation of the type of template detailing for which purpose it is used, e.g. DIGITAL_CONSENT or PURCHASE_ORDER_NOTIFICATION. */
  type: TemplateType;
};

export type TemplateConnection = {
  __typename?: 'TemplateConnection';
  /** List of edges. Default sorting is by name ASC. */
  edges?: Maybe<Array<TemplateEdge>>;
  /** Count of the entities with the current filter applied. */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page. */
  pageInfo: PageInfo;
  /** Count of all the entities. */
  totalCount: Scalars['Int']['output'];
};

export type TemplateEdge = {
  __typename?: 'TemplateEdge';
  /** A cursor to this edge. */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one. */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one. */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The template entity. */
  node?: Maybe<Template>;
};

export type TemplateFilterInput = {
  _and?: InputMaybe<Array<TemplateFilterInput>>;
  _or?: InputMaybe<Array<TemplateFilterInput>>;
  available?: InputMaybe<BooleanFilterInput>;
  customerReference?: InputMaybe<StringFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  document?: InputMaybe<DocumentFilterInput>;
  endDate?: InputMaybe<DateFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  oid?: InputMaybe<TemplateIdFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  startDate?: InputMaybe<DateFilterInput>;
  state?: InputMaybe<StateFilterInput>;
  type?: InputMaybe<TemplateTypeFilterInput>;
};

export type TemplateIdFilterInput = {
  _and?: InputMaybe<Array<TemplateIdFilterInput>>;
  _eq?: InputMaybe<Scalars['TemplateId']['input']>;
  _in?: InputMaybe<Array<Scalars['TemplateId']['input']>>;
  _ne?: InputMaybe<Scalars['TemplateId']['input']>;
  _nin?: InputMaybe<Array<Scalars['TemplateId']['input']>>;
  _or?: InputMaybe<Array<TemplateIdFilterInput>>;
};

export type TemplateOrderByInput = {
  available?: InputMaybe<OrderBy>;
  customerReference?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  endDate?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

export enum TemplateType {
  ACTIVITY_DELIVERY_CONFIRMATION = 'ACTIVITY_DELIVERY_CONFIRMATION',
  CONTRACT = 'CONTRACT',
  CUSTOM = 'CUSTOM',
  DIGITAL_CONSENT = 'DIGITAL_CONSENT',
  DOUBLE_OPT_IN_COLLECTION_PAGE = 'DOUBLE_OPT_IN_COLLECTION_PAGE',
  DOUBLE_OPT_IN_EMAIL = 'DOUBLE_OPT_IN_EMAIL',
  DOUBLE_OPT_IN_ERROR_PAGE = 'DOUBLE_OPT_IN_ERROR_PAGE',
  DOUBLE_OPT_IN_SMS = 'DOUBLE_OPT_IN_SMS',
  DOUBLE_OPT_IN_SUCCESS_PAGE = 'DOUBLE_OPT_IN_SUCCESS_PAGE',
  MAIL_CONSENT_RECEIVED = 'MAIL_CONSENT_RECEIVED',
  MAIL_MEDICAL_REQUEST = 'MAIL_MEDICAL_REQUEST',
  MAIL_PERSONAL = 'MAIL_PERSONAL',
  MAIL_REPORT = 'MAIL_REPORT',
  MAIL_TO_DO_REMINDER = 'MAIL_TO_DO_REMINDER',
  PAPER_CONSENT = 'PAPER_CONSENT',
  PURCHASE_ORDER_NOTIFICATION = 'PURCHASE_ORDER_NOTIFICATION',
  SAMPLE_REQUEST_FORM = 'SAMPLE_REQUEST_FORM',
}

export type TemplateTypeFilterInput = {
  _eq?: InputMaybe<TemplateType>;
  _in?: InputMaybe<Array<TemplateType>>;
  _ne?: InputMaybe<TemplateType>;
  _nin?: InputMaybe<Array<TemplateType>>;
};

export type Topic = Node & {
  __typename?: 'Topic';
  /** Customer reference. reference, used by the customers to refer to the entity. */
  customerReference?: Maybe<Scalars['String']['output']>;
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Name. String representation of the entity. */
  name: Scalars['String']['output'];
  /** Uuid. It uniquely identifies the entity across the topics. */
  oid: Scalars['TopicId']['output'];
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated. */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED */
  state?: Maybe<State>;
};

export type TopicConnection = {
  __typename?: 'TopicConnection';
  /** List of edges. Default sorting is by name ASC. */
  edges?: Maybe<Array<TopicEdge>>;
  /** Count of the entities with the current filter applied. */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page. */
  pageInfo: PageInfo;
  /** Count of all the entities. */
  totalCount: Scalars['Int']['output'];
};

export type TopicEdge = {
  __typename?: 'TopicEdge';
  /** A cursor to this edge. This is the entity id. */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one. */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one. */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The topic entity. */
  node?: Maybe<Topic>;
};

export type TopicFilterInput = {
  _and?: InputMaybe<Array<TopicFilterInput>>;
  _or?: InputMaybe<Array<TopicFilterInput>>;
  customerReference?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  oid?: InputMaybe<TopicIdFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  state?: InputMaybe<StateFilterInput>;
};

export type TopicIdFilterInput = {
  _and?: InputMaybe<Array<TopicIdFilterInput>>;
  _eq?: InputMaybe<Scalars['TopicId']['input']>;
  _in?: InputMaybe<Array<Scalars['TopicId']['input']>>;
  _ne?: InputMaybe<Scalars['TopicId']['input']>;
  _nin?: InputMaybe<Array<Scalars['TopicId']['input']>>;
  _or?: InputMaybe<Array<TopicIdFilterInput>>;
};

export type TopicOrderByInput = {
  customerReference?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
};

export type TouchPointGroup = Node & {
  __typename?: 'TouchPointGroup';
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Name. String representation of the entity. */
  name: Scalars['String']['output'];
  /** Uuid. It uniquely identifies the entity across the touch point plans. */
  oid: Scalars['TouchPointGroupId']['output'];
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED */
  state: State;
};

export type TouchPointGroupConnection = {
  __typename?: 'TouchPointGroupConnection';
  /** List of edges. Default sorting is by name ASC */
  edges?: Maybe<Array<TouchPointGroupEdge>>;
  /** Count of the entities with the current filter applied */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page */
  pageInfo: PageInfo;
  /** Count of all the entities */
  totalCount: Scalars['Int']['output'];
};

export type TouchPointGroupEdge = {
  __typename?: 'TouchPointGroupEdge';
  /** A cursor to this edge. This is the entity id */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The touch point group entity */
  node?: Maybe<TouchPointGroup>;
};

export type TouchPointGroupFilterInput = {
  _and?: InputMaybe<Array<TouchPointGroupFilterInput>>;
  _or?: InputMaybe<Array<TouchPointGroupFilterInput>>;
  name?: InputMaybe<StringFilterInput>;
  oid?: InputMaybe<TouchPointGroupIdFilterInput>;
  state?: InputMaybe<StateFilterInput>;
};

export type TouchPointGroupIdFilterInput = {
  _and?: InputMaybe<Array<TouchPointGroupIdFilterInput>>;
  _eq?: InputMaybe<Scalars['TouchPointGroupId']['input']>;
  _in?: InputMaybe<Array<Scalars['TouchPointGroupId']['input']>>;
  _ne?: InputMaybe<Scalars['TouchPointGroupId']['input']>;
  _nin?: InputMaybe<Array<Scalars['TouchPointGroupId']['input']>>;
  _or?: InputMaybe<Array<TouchPointGroupIdFilterInput>>;
};

export type TouchPointGroupOrderByInput = {
  name?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
};

export type Touchpoint = Node & {
  __typename?: 'Touchpoint';
  /** The type of the touchpoint */
  activityType: ActivityType;
  /** List of attendees of the activity. List of 0 or more attendees, can be existing persons in the system or guests */
  attendees: Array<TouchpointAttendee>;
  /** List of coOrganizers of the activity. List of 0 or more coOrganizers */
  coOrganizers: Array<TouchpointCoOrganizer>;
  /** If the (planned) activity is confirmed to take place by the attendee */
  confirmed: Scalars['Boolean']['output'];
  /** permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** End date of the touchpoint */
  endDate?: Maybe<Scalars['Date']['output']>;
  /** End date and time of the touchpoint */
  endDateTime?: Maybe<Scalars['DateTime']['output']>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** Uuid. It uniquely identifies the entity across the touchpoints. */
  oid: Scalars['TouchpointId']['output'];
  /** The organization to which the activity is connected */
  organization: Organization;
  /** The person, who is the organizer of the activity, usually the creator */
  organizer: Person;
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Start date of the touchpoint */
  startDate?: Maybe<Scalars['Date']['output']>;
  /** Start date and time of the touchpoint */
  startDateTime?: Maybe<Scalars['DateTime']['output']>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED */
  state: State;
  /** Status. The current status, e.g. PLANNED, CLOSED */
  status: ActivityStatus;
};

export type TouchpointAttendeesArgs = {
  filter?: InputMaybe<TouchpointAttendeeFilterInput>;
};

export type TouchpointCoOrganizersArgs = {
  filter?: InputMaybe<TouchpointCoOrganizerFilterInput>;
};

export type TouchpointAttendee = {
  __typename?: 'TouchpointAttendee';
  /** Name of the attendee if it's a guest */
  guestName?: Maybe<Scalars['String']['output']>;
  /** Shows if attendee is a known person to the system or it's a guest */
  isGuest: Scalars['Boolean']['output'];
  /** Person entity if it's known, not deleted person to the system */
  person?: Maybe<Person>;
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TouchpointAttendeeFilterInput = {
  _and?: InputMaybe<Array<TouchpointAttendeeFilterInput>>;
  _or?: InputMaybe<Array<TouchpointAttendeeFilterInput>>;
  guestName?: InputMaybe<StringFilterInput>;
  isGuest?: InputMaybe<BooleanFilterInput>;
  person?: InputMaybe<PersonFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type TouchpointCoOrganizer = {
  __typename?: 'TouchpointCoOrganizer';
  /** The organizational unit of the co-organizer */
  organizationalUnit?: Maybe<OrganizationalUnit>;
  /** Person entity if it's known, not deleted person to the system */
  person?: Maybe<Person>;
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TouchpointCoOrganizerFilterInput = {
  _and?: InputMaybe<Array<TouchpointCoOrganizerFilterInput>>;
  _or?: InputMaybe<Array<TouchpointCoOrganizerFilterInput>>;
  organizationalUnit?: InputMaybe<OrganizationalUnitFilterInput>;
  person?: InputMaybe<PersonFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type TouchpointConnection = {
  __typename?: 'TouchpointConnection';
  /** List of edges. Default sorting is by startDate DESC */
  edges?: Maybe<Array<TouchpointEdge>>;
  /** Count of the entities with the current filter applied */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page */
  pageInfo: PageInfo;
  /** Count of all the entities */
  totalCount: Scalars['Int']['output'];
};

export type TouchpointEdge = {
  __typename?: 'TouchpointEdge';
  /** A cursor to this edge. This is the entity id */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The touchpoint entity */
  node?: Maybe<Touchpoint>;
};

export type TouchpointFilterInput = {
  _and?: InputMaybe<Array<TouchpointFilterInput>>;
  _or?: InputMaybe<Array<TouchpointFilterInput>>;
  activityType?: InputMaybe<ActivityTypeFilterInput>;
  attendees?: InputMaybe<TouchpointAttendeeFilterInput>;
  coOrganizers?: InputMaybe<TouchpointCoOrganizerFilterInput>;
  confirmed?: InputMaybe<BooleanFilterInput>;
  endDate?: InputMaybe<DateFilterInput>;
  endDateTime?: InputMaybe<DateTimeFilterInput>;
  oid?: InputMaybe<TouchpointIdFilterInput>;
  organization?: InputMaybe<OrganizationFilterInput>;
  organizer?: InputMaybe<PersonFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  startDate?: InputMaybe<DateFilterInput>;
  startDateTime?: InputMaybe<DateTimeFilterInput>;
  state?: InputMaybe<StateFilterInput>;
  status?: InputMaybe<ActivityStatusFilterInput>;
};

export type TouchpointIdFilterInput = {
  _and?: InputMaybe<Array<TouchpointIdFilterInput>>;
  _eq?: InputMaybe<Scalars['TouchpointId']['input']>;
  _in?: InputMaybe<Array<Scalars['TouchpointId']['input']>>;
  _ne?: InputMaybe<Scalars['TouchpointId']['input']>;
  _nin?: InputMaybe<Array<Scalars['TouchpointId']['input']>>;
  _or?: InputMaybe<Array<TouchpointIdFilterInput>>;
};

export type TouchpointOrderByInput = {
  endDate?: InputMaybe<OrderBy>;
  endDateTime?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  startDateTime?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

export type UpdateAcademicTitleAction = {
  /** Update for the customer reference. */
  customerReference?: InputMaybe<UpdateAcademicTitleCustomerReferenceAction>;
  /** Update for the name. */
  name?: InputMaybe<UpdateAcademicTitleNameAction>;
  /** Update for the short name. */
  shortName?: InputMaybe<UpdateAcademicTitleShortNameAction>;
  /** Update for the sort code. */
  sortCode?: InputMaybe<UpdateAcademicTitleSortCodeAction>;
  /** Update for the state. */
  state?: InputMaybe<UpdateAcademicTitleStateAction>;
  /** Update for the type. */
  type?: InputMaybe<UpdateAcademicTitleTypeAction>;
};

export type UpdateAcademicTitleCustomerReferenceAction = {
  /** Customer reference. Reference, used by the customers to refer to the entity. */
  value?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAcademicTitleInput = {
  actions: Array<UpdateAcademicTitleAction>;
  oid: Scalars['AcademicTitleId']['input'];
};

export type UpdateAcademicTitleNameAction = {
  /** Name. E.g. Dr. med. vet. */
  value: Scalars['String']['input'];
};

export type UpdateAcademicTitleShortNameAction = {
  /** Short version of name. E.g. Dr. */
  value?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAcademicTitleSortCodeAction = {
  /** Sort code. Used to prioritise academic titles. */
  value?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateAcademicTitleStateAction = {
  /** State. The state, e.g. ACTIVE, INACTIVE, DELETED */
  value: State;
};

export type UpdateAcademicTitleTypeAction = {
  /** Type. It can be prefix or postfix title. */
  value: AcademicTitleType;
};

export type UpdateActivityAction = {
  /** Update for the activity type. */
  activityTypeId?: InputMaybe<UpdateActivityTypeIdAction>;
  /** Update for the attendees. */
  attendees?: InputMaybe<UpdateAttendeesAction>;
  /** Update for the call details. */
  callDetails?: InputMaybe<UpdateCallDetailsAction>;
  /** Update for the co-organizer. */
  coOrganizers?: InputMaybe<UpdateCoOrganizersAction>;
  /** Update for the comments. */
  comments?: InputMaybe<UpdateCommentsAction>;
  /** Update confirmed status. */
  confirmed?: InputMaybe<UpdateConfirmedAction>;
  /** Update for the customer reference. */
  customerReference?: InputMaybe<UpdateActivityCustomerReferenceAction>;
  /** Update for the discussed topics. */
  discussedTopics?: InputMaybe<UpdateDiscussedTopicsAction>;
  /** Update for the end date. */
  endDate?: InputMaybe<UpdateEndDateAction>;
  /** Update for the media shown during the activity. */
  media?: InputMaybe<UpdateMediaAction>;
  /** Update for the organization id. */
  organizationId?: InputMaybe<UpdateActivityOrganizationIdAction>;
  /** Update for the organizational unit id. */
  organizationalUnitId?: InputMaybe<UpdateActivityOrganizationalUnitIdAction>;
  /** Update for the organizer id. */
  organizerId?: InputMaybe<UpdateActivityOrganizerIdAction>;
  /** Update for the requested promotional materials. */
  requestedPromotionalMaterials?: InputMaybe<UpdateRequestedPromotionalMaterialsAction>;
  /** Update for the sample request form id. */
  sampleRequestFormId?: InputMaybe<UpdateSampleRequestFormIdAction>;
  /** Update sealed status. */
  sealed?: InputMaybe<UpdateSealedAction>;
  /** Update for the start date. */
  startDate?: InputMaybe<UpdateStartDateAction>;
  /** Update for the activity state. */
  state?: InputMaybe<UpdateStateAction>;
  /** Update for the activity status. */
  status?: InputMaybe<UpdateStatusAction>;
};

export type UpdateActivityCustomerReferenceAction = {
  /** Customer reference. Reference, used by the customers to refer to the entity. */
  value: Scalars['String']['input'];
};

export type UpdateActivityInput = {
  /** The actions for each field of an activity. */
  actions: Array<UpdateActivityAction>;
  /** The object identifier of the activity. */
  oid: Scalars['ActivityId']['input'];
};

export type UpdateActivityOrganizationIdAction = {
  /** Id of the organization where the activity takes place. */
  value: Scalars['OrganizationId']['input'];
};

export type UpdateActivityOrganizationalUnitIdAction = {
  /** Id of the organizational unit where the activity takes place. */
  value: Scalars['OrganizationalUnitId']['input'];
};

export type UpdateActivityOrganizerIdAction = {
  /** Id of the organizer of the activity. */
  value: Scalars['PersonId']['input'];
};

export type UpdateActivityTypeIdAction = {
  /** The type of the activity. */
  value: Scalars['ActivityTypeId']['input'];
};

export type UpdateAttendeesAction = {
  /** The participants of the activity. */
  value: Array<ActivityAttendeeInput>;
};

export type UpdateCallDetailsAction = {
  /** Call details of a telephony activity, such as when did the call take place, which number was dialed, what were the results, etc. */
  value: Array<ActivityCallDetailInput>;
};

export type UpdateCoOrganizersAction = {
  /** The co-organizers of the activity. */
  value: Array<ActivityCoOrganizerInput>;
};

export type UpdateCommentsAction = {
  /** Comments for the activity. */
  value: Array<CommentInput>;
};

export type UpdateConfirmedAction = {
  /** Confirmed status of the activity. */
  value: Scalars['Boolean']['input'];
};

export type UpdateDiscussedTopicsAction = {
  /** The topics that will be discussed in the activity. */
  value: Array<Scalars['TopicId']['input']>;
};

export type UpdateEndDateAction = {
  /** The date and time when the activity ends. */
  value?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateFacetsPreferenceAction = {
  /** The new value of the facets preferences. */
  value: FacetsPreferenceInput;
};

export type UpdateMediaAction = {
  /** The media which was shown during the activity. */
  value: Array<ActivityShownMediaInput>;
};

export type UpdatePersonConsentItemsInput = {
  actionAt: Scalars['DateTime']['input'];
  consentItemsValues: Array<PersonConsentItemValueInput>;
  issuerPersonId: Scalars['PersonId']['input'];
  oid: Scalars['PersonConsentId']['input'];
};

export type UpdatePersonIdAction = {
  /** The new value of the person id to whom the preference belongs. */
  value: Scalars['PersonId']['input'];
};

export type UpdatePreferenceAction = {
  /** Update for the facet preference. */
  facets?: InputMaybe<UpdateFacetsPreferenceAction>;
  /** Update for the person id. */
  personId?: InputMaybe<UpdatePersonIdAction>;
};

export type UpdatePreferenceInput = {
  /** The actions for each field of an activity. */
  actions: Array<UpdatePreferenceAction>;
  /** The object identifier of the preference. */
  oid: Scalars['PreferenceId']['input'];
};

export type UpdateRequestedPromotionalMaterialsAction = {
  /** The requested promotional materials which were given during the activity. */
  value: Array<PromotionalMaterialRequestInput>;
};

export type UpdateSampleRequestFormIdAction = {
  /** The sample request form that was filled in during the activity from one of the participants. */
  value?: InputMaybe<Scalars['SampleRequestFormId']['input']>;
};

export type UpdateSealedAction = {
  /** Sealed status of the activity. */
  value: Scalars['Boolean']['input'];
};

export type UpdateStartDateAction = {
  /** The date and time when the activity starts. */
  value: Scalars['DateTime']['input'];
};

export type UpdateStateAction = {
  /** The state (LIMBO, ACTIVE, INACTIVE, DELETED) of the activity. */
  value: State;
};

export type UpdateStatusAction = {
  /** The status (PLANNED, CLOSED) of the activity. */
  value: ActivityStatus;
};

export type User = Node & {
  __typename?: 'User';
  /** all client permissions for current user */
  allClientPermissions?: Maybe<Array<ClientPermission>>;
  /** all data permissions for current user */
  allDataPermissions?: Maybe<Array<DataPermission>>;
  /** data permissions for current entity */
  dataPermissions: Array<DataPermission>;
  /** The email of the entity. */
  email?: Maybe<Scalars['String']['output']>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** the inventory of the current user */
  inventory?: Maybe<Inventory>;
  /** Uuid. It uniquely identifies the entity across the users. */
  oid: Scalars['UserId']['output'];
  /** Optional person if connected to the user */
  person?: Maybe<Person>;
  /** Assignments to the user in the organizational hierarchy. */
  personAssignments: PersonAssignmentConnection;
  /** Preference of the current user for using the system. */
  preference?: Maybe<Preference>;
  /** Creation date. */
  recCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date when was last updated. */
  recUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** State. The current state, e.g. ACTIVE, INACTIVE, DELETED */
  state: State;
  /** User Name. String representation of the entity username. it's used also to login */
  username?: Maybe<Scalars['String']['output']>;
};

export type UserPersonAssignmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PersonAssignmentFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PersonAssignmentOrderByInput>>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  /** List of edges. Default sorting is by username ASC. */
  edges?: Maybe<Array<UserEdge>>;
  /** Count of the entities with the current filter applied. */
  filteredCount: Scalars['Int']['output'];
  /** Provides more information about the current page. */
  pageInfo: PageInfo;
  /** Count of all the entities. */
  totalCount: Scalars['Int']['output'];
};

export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor to this edge. This is the entity id. */
  cursor: Scalars['String']['output'];
  /** There is an entity after this one */
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  /** There is an entity before this one */
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  /** The user entity. */
  node?: Maybe<User>;
};

export type UserFilterInput = {
  _and?: InputMaybe<Array<UserFilterInput>>;
  _or?: InputMaybe<Array<UserFilterInput>>;
  email?: InputMaybe<StringFilterInput>;
  oid?: InputMaybe<UserIdFilterInput>;
  person?: InputMaybe<PersonFilterInput>;
  recCreatedAt?: InputMaybe<DateTimeFilterInput>;
  recUpdatedAt?: InputMaybe<DateTimeFilterInput>;
  state?: InputMaybe<StateFilterInput>;
  username?: InputMaybe<StringFilterInput>;
};

export type UserIdFilterInput = {
  _and?: InputMaybe<Array<UserIdFilterInput>>;
  _eq?: InputMaybe<Scalars['UserId']['input']>;
  _in?: InputMaybe<Array<Scalars['UserId']['input']>>;
  _ne?: InputMaybe<Scalars['UserId']['input']>;
  _nin?: InputMaybe<Array<Scalars['UserId']['input']>>;
  _or?: InputMaybe<Array<UserIdFilterInput>>;
};

export type UserOrderByInput = {
  email?: InputMaybe<OrderBy>;
  oid?: InputMaybe<OrderBy>;
  recCreatedAt?: InputMaybe<OrderBy>;
  recUpdatedAt?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  username?: InputMaybe<OrderBy>;
};
